import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "./button";

// icons
import spike from "../assets/icon/spiked-circle/black/35px.svg";
import minted from "../assets/icon/favicon-24px.svg";
import unread from "../assets/icon/circleRed.svg";
import arrow from "../assets/icon/chevron-up-small.svg";
import offerMade from "../assets/icon/offer-made.svg";
import listed from "../assets/icon/list-for-sale.svg";
import { Link } from "react-router-dom";
import exLink from "../assets/icon/arrow-angle-right-grey.svg";

import Loader from "./shared/Loader";
import {
  getNotifications,
  markAllNotificationsAsRead,
  updateSingleIsReadInDatabase,
  getSettingFirebase,
  updateNotificationVisiblity,
  getUserData,
  getAdminNotifications,
  updatedSingleIsReadAdminNotification,
  markAllAdminNotificationAsRead,
  getAllUsers,
} from "../firebase/firebase";
import { useAccount } from "wagmi";
import DotLoader from "./shared/dotLoader";
import { RefreshContext } from "./notificationContext";
import NotificationLoader from "./loader/notificationLoader";
import config from "../config";
import UseWindowResize from "../customHooks/useWindowResize";
const Notifications = (props) => {
  const navigate = useNavigate();
  const { address } = useAccount();
  const [triggerNavbar, setTriggerNavbar] = useState(false);

  const [notification, setNotification] = useState([]);
  const [isRead, setIsRead] = useState(true);
  const [username, setUserName] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingNotificationId, setLoadingNotificationId] = useState(null);

  const fetchNotificationData = async () => {
    try {
      // setLoading(true);
      const data = await getUserData(address);
      setUserName(data?.userName);

      try {
        let notifications;
        if (props.type === "admin") {
          notifications = await getAdminNotifications();
        } else {
          notifications = await getNotifications(address);
        }

        if (notifications.length < 1 || !notifications) {
          setLoading(false);
        }
        setNotification(notifications);
        const allRead = notifications.every(
          (notification) => notification.isRead
        );
        setIsRead(allRead);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotificationData();
  }, [address]);

  const { unreadNotifications, setUnreadNotifications } =
    useContext(RefreshContext);

  const fetchNotifications = async () => {
    try {
      const unread = notification.filter(
        (notification) =>
          notification?.isRead === false &&
          notification?.username !== "undefined"
      );

      if (unread.length > 0) {
        setUnreadNotifications(true);
      } else {
        setUnreadNotifications(false);
        localStorage.setItem("unread", false);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    if (username) {
      fetchNotifications();
    }
  }, [username, notification]);

  const allRead = async () => {
    setIsRead(true);
    try {
      let read;
      if (props.type === "admin") {
        read = await markAllAdminNotificationAsRead(address); // This might need to be adjusted for admin
      } else {
        read = await markAllNotificationsAsRead(address);
      }
      setNotification(read);
      fetchNotificationData();
    } catch (error) {
      console.log("Error in marking all notifications read");
    }
  };

  useEffect(() => {
    if (username) {
      fetchNotificationData();
    }
  }, [username]);

  const fetchNotification = async () => {
    try {
      let notifications;
      if (props.type === "admin") {
        notifications = await getAdminNotifications();
      } else {
        notifications = await getNotifications(username);
      }
      setNotification(notifications);

      // Check if all notifications are read
      const allRead = notifications.every(
        (notification) => notification.isRead
      );
      setIsRead(allRead);

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getSetting = async () => {
    try {
      const settings = await getSettingFirebase(address);
      if (settings && settings.data) {
        for (let a of notification) {


          await updateNotificationVisiblity(
            settings.data[a.notificationType],
            a.id
          );
          getNotifications();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSetting();
  }, []);

  const markNotificationAsRead = async (notificationId) => {
    setLoading(true);

    try {
      if (props.type === "admin") {
        await updatedSingleIsReadAdminNotification(notificationId);
      } else {
        await updateSingleIsReadInDatabase(notificationId);
      }
      await fetchNotificationData(); // Await this to ensure state updates correctly
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const location = useLocation();
  const pathsToHideComp = ["/admin-dashboard"];

  const shouldHideComp = pathsToHideComp.some((path) =>
    location.pathname.includes(path)
  );

  const navigateToexplorer = (entry) => {
    if (entry.notificationType == 'addFavourite') {
      return;
    }
    if (entry.notificationType !== "newFollower") {
      const network = entry.selectedBlockchain;

      let url = "";

      if (network === config.networks.songbird) {
        url = `https://songbird-explorer.flare.network/tx/${entry.transactionHash}`;
      } else {
        url = `https://flare-explorer.flare.network/tx/${entry.transactionHash}`;
      }

      window.open(url, "_blank");
    }
  };

  const [userData, setUserData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      let res = await getAllUsers();
      setUserData(res);
    };
    getData();
  }, []);
  const getArtistNamebyAdress = (ArtistAddress) => {
    let artistName = "";
    if (address === ArtistAddress) {
      return "You";
    }

    userData?.map((user) => {
      if (user?.id === ArtistAddress) {
        artistName = user?.userName;
      }
    });

    return "@" + artistName;
  };

  const generateNotificationMessage = (notification) => {
    const {
      notificationType,
      artName,
      price,
      artLink,
      currency,
      username,
      To,
    } = notification;

    switch (notificationType) {
      case "purchasedBy":
        return (
          <>
            You purchased{" "}
            <Link to={`${artLink}`} className="notifications-bold">
              {artName}{" "}
            </Link>
          </>
        );
      case "soldBy":
        return (
          <>
            Artwork{" "}
            <Link to={`${artLink}`} className="notifications-bold">
              {artName}{" "}
            </Link>{" "}
            sold to{" "}
            <Link to={`/profile/${To}`} className="notifications-bold">
              {getArtistNamebyAdress(To)}
            </Link>
          </>
        );
      case "recievedOffer":
        return (
          <>
            <Link to={`/profile/${To}`} className="notifications-bold">
              {getArtistNamebyAdress(To)}
            </Link>{" "}
            just made an offer of {price} {currency} on your artwork{" "}
            <span className="notifications-bold">{artName}</span>.
          </>
        );
      case "madeOffer":
        return (
          <>
            You made an offer of {price} {currency} on{" "}
            <Link to={`${artLink}`} className="notifications-bold">
              {artName}{" "}
            </Link>
          </>
        );
      case "offerAccepted":
        return (
          <>
            Congratulations! Your offer on{" "}
            <Link to={`${artLink}`} className="notifications-bold">
              {artName}{" "}
            </Link>{" "}
            was accepted.
          </>
        );
      case "newFollower":
        return (
          <>
            You have a new follower!{" "}
            <Link to={`/profile/${To}`} className="notifications-bold">
              {getArtistNamebyAdress(To)}
            </Link>{" "}
            is now following you.
          </>
        );
      case "minted":
        return (
          <>
            You minted{" "}
            <Link to={`${artLink}`} className="notifications-bold">
              {artName}
            </Link>
          </>
        );
      case "listed":
        return (
          <>
            You listed{" "}
            <Link to={`${artLink}`} className="notifications-bold">
              {artName}{" "}
            </Link>{" "}
            for {price} {currency}
          </>
        );
      case "transferFrom":
        return (
          <>
            You transferred{" "}
            <Link to={`${artLink}`} className="notifications-bold">
              {artName}{" "}
            </Link>{" "}
            to{" "}
            <Link to={`/profile/${To}`} className="notifications-bold">
              {getArtistNamebyAdress(To)}
            </Link>
            .
          </>
        );
      case "transferTo":
        return (
          <>
            <Link to={`/profile/${To}`} className="notifications-bold">
              {getArtistNamebyAdress(To)}
            </Link>{" "}
            transferred{" "}
            <Link to={`${artLink}`} className="notifications-bold">
              {artName}{" "}
            </Link>{" "}
            to you.
          </>
        );
      case "addFavourite":
        return (
          <>
            You added <span className="notifications-bold">
              <Link to={`${artLink}`} className="notifications-bold">
                {artName}{" "}
              </Link>{" "}
            </span> to
            your favorites.
          </>
        );
      case "favouriteArt":
        return (
          <>
            <Link to={`/profile/${username}`} className="notifications-bold">
              {getArtistNamebyAdress(username)}
            </Link>{" "}
            added your artwork{" "}
            <span className="notifications-bold">{artName}</span> to favorites.
          </>
        );

      case "claimBy":
        return (
          <>
            <Link to={`/profile/${username}`} className="notifications-bold">
              {getArtistNamebyAdress(username)}
            </Link>{" "}
            claimed artwork{" "}
            <span className="notifications-bold">{artName}</span>. You are now
            the owner of this artwork
          </>
        );
      case "claimFor":
        return (
          <>
            Artwork <span className="notifications-bold">{artName}</span>{" "}
            claimed by user{" "}
            <Link to={`/profile/${To}`} className="notifications-bold">
              {getArtistNamebyAdress(To)}
            </Link>{" "}
            .
          </>
        );
      case "cancelListing":
        return (
          <>
            <Link to={`/profile/${username}`} className="notifications-bold">
              {getArtistNamebyAdress(username)}
            </Link>{" "}
            cancelled listing for{" "}
            <Link to={`${artLink}`} className="notifications-bold">
              {artName}
            </Link>
          </>
        );

      case "outBid":
        return (
          <>
            Your bid on{" "}
            <Link to={`${artLink}`} className="notifications-bold">
              {artName}
            </Link>{" "}
            has been outbid. The new highest bid is {price} ETH.
          </>
        );
      case "followActivity":
        return (
          <>
            <Link to={`/profile/${To}`} className="notifications-bold">
              {getArtistNamebyAdress(To)}
            </Link>{" "}
            minted{" "}
            <Link to={`${artLink}`} className="notifications-bold">
              {artName}
            </Link>
            .
          </>
        );
      case "priceChange":
        return (
          <>
            <Link to={`/profile/${username}`} className="notifications-bold">
              {getArtistNamebyAdress(username)}
            </Link>{" "}
            updated list price of{" "}
            <Link to={`${artLink}`} className="notifications-bold">
              {artName}
            </Link>{" "}
            to {price}
            {currency}
          </>
        );

      default:
        return <>Unknown notification type</>;
    }
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const shouldHideButton =
    location.pathname === "/notification" && windowWidth < 600;

  return (
    <div>
      <section className={`notifications site-container ${props.activeClass}`}>
        <div
          className={`section-header-2 v-center justify-content-between ${props.noBorder} `}
        >
          {/* desktop */}
          <h3 className={` fw-bolder v-center hide-on-mobile ${props.hide}`}>
            <img src={spike} alt="spike" className="me-1" />
            Notifications
          </h3>

          {/* mobile */}
          <h6
            className={`mt-0 v-center hide-on-desktop ${props.hide}`}
          // onClick={() => navigate("/account-settings")}
          >
            {/* <img src={arrow} alt="arrow" className="me-3 rotate-270 " /> */}
            Notifications
          </h6>

          <div className="d-flex justify-content-between v-center w-100">
            <p className={`body-large fw-bold ${props.hideHead}`}>
              Notifications
            </p>

            {!shouldHideButton && (
              <Button
                text={"Mark all as read"}
                className={`btn-prime ${isRead ? "btn-ternary" : "btn-primary"
                  }   `}
                disabled={isRead}
                width="123px"
                height="36px"
                onClick={allRead}
              />
            )}
          </div>
        </div>

        {loading ? (
          <>
            <NotificationLoader />
          </>
        ) : (
          <div
            className={`notifications-content ${notification?.length < 1 ? "no-after" : ""
              }`}
          >
            {notification?.length < 1 || !notification ? (
              <p className="mt-70 pt-5 text-center">
                You don't have any <b>notifications</b> yet.
              </p>
            ) : props.type !== "admin" ? (
              notification.map((notification, index) =>
                notification.isVisible ? (
                  <div
                    className="histrory-list justify-content-between"
                    key={index}
                  >
                    <div className="d-flex pointer">
                      <div className="left img-24 v-center justify-content-center">
                        {/* Display icons based on the updated notification types */}
                        {notification.notificationType === "listed" && (
                          <img src={listed} alt="listed" className="img-14" />
                        )}
                        {notification.notificationType === "purchasedBy" && (
                          <img
                            src={offerMade}
                            alt="purchase"
                            className="img-14"
                          />
                        )}
                        {notification.notificationType === "soldBy" && (
                          <img
                            src={offerMade}
                            alt="purchase"
                            className="img-14"
                          />
                        )}
                        {notification.notificationType === "recievedOffer" && (
                          <img
                            src={offerMade}
                            alt="newOffer"
                            className="img-14"
                          />
                        )}
                        {notification.notificationType === "madeOffer" && (
                          <img
                            src={offerMade}
                            alt="newOffer"
                            className="img-14"
                          />
                        )}
                        {notification.notificationType === "offerAccepted" && (
                          <img
                            src={offerMade}
                            alt="offerAccepted"
                            className="img-14"
                          />
                        )}
                        {notification.notificationType === "itemSold" && (
                          <img
                            src={offerMade}
                            alt="itemSold"
                            className="img-14"
                          />
                        )}
                        {notification.notificationType === "minted" && (
                          <img src={minted} alt="minted" />
                        )}
                        {notification.notificationType === "transferFrom" && (
                          <img
                            src={offerMade}
                            alt="transfer"
                            className="img-14"
                          />
                        )}
                        {notification.notificationType === "transferTo" && (
                          <img
                            src={offerMade}
                            alt="transfer"
                            className="img-14"
                          />
                        )}
                        {notification.notificationType === "newFollower" && (
                          <img
                            src={minted}
                            alt="newFollower"
                            className="img-14"
                          />
                        )}
                        {notification.notificationType === "addFavourite" && (
                          <img
                            src={minted}
                            alt="newFollower"
                            className="img-14"
                          />
                        )}
                        {notification.notificationType === "favouriteArt" && (
                          <img
                            src={minted}
                            alt="newFollower"
                            className="img-14"
                          />
                        )}
                        {notification.notificationType === "claimBy" && (
                          <img
                            src={minted}
                            alt="newFollower"
                            className="img-14"
                          />
                        )}
                        {notification.notificationType === "claimFor" && (
                          <img
                            src={minted}
                            alt="newFollower"
                            className="img-14"
                          />
                        )}
                        {notification.notificationType === "outBid" && (
                          <img src={minted} alt="outBid" className="img-14" />
                        )}
                        {notification.notificationType === "followActivity" && (
                          <img
                            src={minted}
                            alt="followActivity"
                            className="img-14"
                          />
                        )}
                        {notification.notificationType === "cancelListing" && (
                          <img
                            src={minted}
                            alt="cancelListing"
                            className="img-14"
                          />
                        )}
                      </div>

                      <div className="right">
                        <p className="body-medium">
                          {!notification.isRead && (
                            <img
                              src={unread}
                              alt="notification"
                              className="img-12 me-1"
                            />
                          )}

                          {/* Display the notification content */}
                          <span>
                            {generateNotificationMessage(notification)}
                          </span>
                        </p>
                        <label
                          onClick={() => {

                            navigateToexplorer(notification);
                          }}
                          className="small d-flex v-center gap-1 mt-1"
                        >
                          {new Date(notification.createdAt).toLocaleString()}

                          {
                            notification.notificationType !== "addFavourite" &&
                            <img src={exLink} alt="" className="img-12" />}
                        </label>
                      </div>
                    </div>
                    <div>
                      <label
                        className={`medium pointer no-text-transform hide-on-mobile ${notification.isRead ? "text-gray" : "text-black"
                          }`}
                        onClick={() => {
                          setLoadingNotificationId(notification.id); // Start loader for this notification
                          markNotificationAsRead(notification.id); // Mark notification as read
                        }}
                      >
                        {loadingNotificationId === notification.id &&
                          loading ? (
                          <DotLoader />
                        ) : (
                          "Mark as read"
                        )}
                      </label>
                    </div>
                  </div>
                ) : null
              )
            ) : (
              <div>
                {props?.type === "admin" &&
                  notification.map((notification, index) => (
                    <div
                      className="histrory-list justify-content-between"
                      key={index}
                    >
                      <div className="d-flex pointer">
                        <div className="left img-24 v-center justify-content-center">
                          {/* Display icons based on the updated notification types */}

                          <img src={listed} alt="listed" className="img-14" />
                        </div>

                        <div className="right">
                          <p className="body-medium">
                            {!notification.isRead && (
                              <img
                                src={unread}
                                alt="notification"
                                className="img-12 me-1"
                              />
                            )}

                            {/* Display the notification content */}
                            <span>{notification.content}</span>
                          </p>
                          <label
                            onClick={() => {
                              navigateToexplorer(notification);
                            }}
                            className="small d-flex v-center gap-1 mt-1"
                          >
                            {new Date(notification.createdAt).toLocaleString()}
                            <img src={exLink} alt="" className="img-12" />
                          </label>
                        </div>
                      </div>
                      <div>
                        <label
                          className={`medium pointer no-text-transform hide-on-mobile ${notification.isRead ? "text-gray" : "text-black"
                            }`}
                          onClick={() => {
                            setLoadingNotificationId(notification.id); // Start loader for this notification
                            markNotificationAsRead(notification.id); // Mark notification as read
                          }}
                        >
                          {loadingNotificationId === notification.id &&
                            loading ? (
                            <DotLoader />
                          ) : (
                            "Mark as read"
                          )}
                        </label>
                      </div>
                    </div>

                    // <div key={index}>
                    //   {notification.content}
                    // </div>
                  ))}
              </div>
            )}
          </div>
        )}
      </section>

      <div
        className={`footer-read-btn v-center justify-content-center hide-on-desktop ${props.hide}`}
      >
        <Button
          text={"Mark all as Read"}
          className={`btn-prime ${isRead ? "btn-ternary" : "btn-primary"}   `}
          width="90%"
          height="36px"
          disabled={isRead}
          onClick={allRead}
        />
      </div>
    </div>
  );
};

export default Notifications;
