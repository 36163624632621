import React, { createContext, useState, useEffect } from 'react';
import { getNotifications, getUserData } from "../firebase/firebase";
import { useAccount } from 'wagmi';
import { add } from 'lodash';

export const RefreshContext = createContext();



export const RefreshProvider = ({ children }) => {
  const { address } = useAccount();
  const [unreadNotifications, setUnreadNotifications] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [notification, setNotification] = useState([]);
  const [username, setUserName] = useState(null);



  const fetchNotificationData = async () => {

    try {

      const data = await getUserData(address);
      setUserName(data?.userName);
      try {
        const notifications = await getNotifications(address);
        setNotification(notifications);
        const allRead = notifications.every(
          (notification) => notification.isRead
        );

      } catch (error) {
        console.error(error);

      }

    } catch (error) {
      console.error(error);

    }
  };

  useEffect(() => {
    fetchNotificationData();
  }, [address]);

  const fetchNotifications = async () => {

    try {
      const unread = notification.filter(
        (notification) =>
          notification?.isRead === false &&
          notification?.username !== "undefined"
      );

      if (unread.length > 0) {
        setUnreadNotifications(true);
      } else {
        setUnreadNotifications(false);
        localStorage.setItem("unread", false);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    if (username) {
      fetchNotifications();
    }
  }, [username, notification]);







  return (
    <RefreshContext.Provider value={{ unreadNotifications, setUnreadNotifications, isAdmin, setIsAdmin }}>
      {children}
    </RefreshContext.Provider>
  );
};
