import React from "react";
import Header from "../header";
import add from "../../assets/icon/plus.svg";
import art from "../../assets/images/artwork-example-1.png";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import imgg from "../../assets/images/collectionPlaceholder.png";
import CollectionCard from "../collectionCard";

const SelectArtTab = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { collection } = location.state;


  const navigateToCreate = async () => {
    navigate("/create-collections");
  };
  const navigateToCreatArt = async (name) => {
    if (name) {
      navigate("/create-art", {
        state: { CollectionName: name },
      });
    }

  };
  return (
    <div>
      <section className="create-artwork mt-0">
        <Header head="Create" className="position-relative" />

        <div className="create-collection-popup mt-70 flex-column v-center  ">
          <h4 className="medium-head mb-4">Select a collection</h4>
          <p className="mb-50 text-center">
            Choose the collection you’d like to add a new <br /> artwork to or
            create a new one.
          </p>
          <div
            onClick={navigateToCreate}
            className="create-new-collection v-center pointer"
          >
            <div className="left">
              <img src={add} alt="add" />
            </div>
            <label className="large text-black no-text-transform fw-bold pointer">
              Create new collection
            </label>
          </div>
          {collection.map((data, index) => {
            {
              /*      <div
              onClick={() => navigateToCreatArt(e.data.name)}
             className="create-new-collection v-center mt-2 pointer"
            >
            <div className="left">
                {e.data.image ? (
               ) : (
                  <img src={imgg} alt="add" className="img-100" />
               )}
            </div>
              <div>
                <label className="large text-black no-text-transform fw-bold pointer">
                   {e.data.name}
              </label>
               <br />
             <label className="small no-text-transform fw-500 pointer">
                  {e.data.symbol}
            </label>
          </div> 
             <span className="small"></span>  
           </div>  */
            }
            return <CollectionCard key={index} {...data} />;
          })}
        </div>
      </section>
    </div>
  );
};

export default SelectArtTab;
