import React, { useEffect, useState } from "react";
import "../../style/main.scss";
import verified from "../../assets/icon/verified-artist-small.svg";
import sgb from "../../assets/icon/SGB.svg";
import flr from "../../assets/icon/FLR.svg";
import profile from "../../assets/icon/profile-picture.svg";
import loader from "../../assets/icon/loader-medium.svg";
import thumb from "../../assets/icon/profile-picture.svg";
import vidIcon from "../../assets/icon/video-fill.svg";
import userProfile from "../../assets/icon/profile-picture.svg";
import ArtistPopUp from "../shared/artistpopup";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AsideFilterExplore from "../explore/asideFilterExplore";
import { getAllUsers } from "../../firebase/firebase";
import Web3 from "web3";
import mintContractABI from "../../abis/SafeMint/v2/abi.json";
import marketplaceContractABI from "../../abis/Marketplace/abi.json";
import config from "../../config";
import CardSkelton from "../shared/cardSkelton";
import { Popover } from "antd";
import { add } from "lodash";
import { useAccount } from "wagmi";
const UserCollectionGrid = (props) => {
  // contract address of marketplace
  const { marketplace, erc20 } = config;

  const { address: connectedAddress } = useAccount();

  const Marketplace_coston_contractAddress = marketplace.coston;
  const Marketplace_coston2_contractAddress = marketplace.coston2;
  const contractABI = marketplaceContractABI;

  const coston_Token = erc20.coston;
  const coston2_Token = erc20.coston2;

  const [collectedArts, setCollectedArts] = useState([]);
  const [nftData, setNftData] = useState([]);
  const [address, setAddress] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const handleImageLoad = () => {
    setLoaded(true);
  };
  useEffect(() => {
    setAddress(props.address);
  }, [props]);

  useEffect(() => {
    if (props?.listedNft) {
      setNftData(props?.listedNft);
    }
  }, [props?.listedNft]);

  // get best offer price from blockchain

  const fetchDataAndUpdateDetails = async () => {
    let bidding;
    if (props?.listedNft) {
      let data = props?.listedNft.flat();

      try {
        const web3 = new Web3(window.ethereum);
        const updatedDetails = await Promise.all(
          data
            .filter((item) => item?.data.isOfferListed)
            .map(async (item) => {
              const MarketplaceAddress =
                item?.metadata.data.selectedBlockchain === "Coston"
                  ? Marketplace_coston_contractAddress
                  : Marketplace_coston2_contractAddress;

              const contract = new web3.eth.Contract(
                marketplaceContractABI,
                MarketplaceAddress
              );
              try {
                const biddingHistoryCoston = await contract.methods
                  .getBiddingHistory(item?.data.listCount)
                  .call();

                let maxPrice = null;
                if (biddingHistoryCoston && biddingHistoryCoston.length > 0) {
                  maxPrice = calculateMaxPrice(biddingHistoryCoston);
                }

                return { ...item, maxPrice: maxPrice ? maxPrice / 1e18 : null }; // Converting to ether or setting to null
              } catch (error) {
                console.error("Error fetching bidding history:", error);
                return { ...item, maxPrice: null }; // Handle error fetching bidding history by returning the item with null maxPrice
              }
            })
        );

        setNftData((prevDetails) => {
          const unchangedItems = prevDetails.filter(
            (prevItem) => !prevItem.data.isOfferListed
          );

          const mergedDetails = [
            ...unchangedItems,
            ...updatedDetails.filter(Boolean),
          ];

          return mergedDetails;
        });
      } catch (error) {
        console.error("Error fetching data and updating details:", error);
        // Handle error fetching data and updating details
      }
    }
  };

  // Function to calculate the maximum price from bidding history
  const calculateMaxPrice = (biddingHistoryArray) => {
    let maxPrice = 0;
    for (const biddingHistory of biddingHistoryArray) {
      for (const key in biddingHistory) {
        if (Object.prototype.hasOwnProperty.call(biddingHistory, key)) {
          const currentPrice = parseFloat(biddingHistory[key]);
          if (!isNaN(currentPrice) && currentPrice > maxPrice) {
            maxPrice = currentPrice;
          }
        }
      }
    }
    return maxPrice;
  };
  useEffect(() => {
    if (props?.listedNft) {
      setCollectedArts(props?.listedNft);
      const fetchdata = async () => {
        await fetchDataAndUpdateDetails();
      };
      fetchdata();
    }
  }, [props?.listedNft]);

  const navigate = useNavigate();
  // navigate to single artwork

  const navigateTo = async (item, listType) => {
    try {
      let collectionId = item?.data.mintContract;
      let tokenId = item?.data.tokenId;

      let network = item?.metadata?.data?.selectedBlockchain;
      navigate(`/artwork/eth/${collectionId}/${tokenId}/`);
    } catch (error) {
      console.error(error);
      // Handle error if needed
    }
  };

  const tooltip1 = (artPrice) => {
    return <Tooltip id="tooltip1">{artPrice}</Tooltip>;
  };

  const tooltip2 = (chainName) => {
    return <Tooltip id="tooltip2">Chain: {chainName}</Tooltip>;
  };

  const [userData, setUserData] = useState([]);

  // get user from localstorage

  const checkUser = async () => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUserData(user);
    }
  };

  useEffect(() => {
    checkUser();
    const fetchUserData = async () => {
      const users = await getAllUsers();

      setUserData(users);
    };
    fetchUserData();
  }, []);

  // get artist name by address

  const getArtistNamebyAdress = (accountAddress) => {
    let artistName = "";

    if (connectedAddress === accountAddress) {
      return "You";
    } else {
      userData?.forEach((user) => {
        if (user?.id === accountAddress) {
          artistName = user?.userName;
        }
      });
    }

    return "@" + artistName;
  };

  // get artist image by address

  const getArtistImage = (accountAddress) => {
    let artistImage = userProfile;
    if (!userData) {
      return;
    }
    userData?.forEach((user) => {
      if (user?.id === accountAddress) {
        if (user?.image) {
          artistImage = user?.image;
        } else {
          artistImage = userProfile;
        }
      }
    });
    return artistImage;
  };

  return (
    <div>
      <div
        className={
          props.flag ? "d-flex align-item-center justify-content-between" : ""
        }
      >
        <div className={props.flag ? "my-filter w-25" : "d-none"}>
          <AsideFilterExplore
            onSelectedFilterChange={props.onSelectedFilterChange}
          />
        </div>

        {props.loading ? (
          <div
            className={`grid-display artworkGrid  ${props.flag ? "w-75 active" : ""
              }`}
          >
            <CardSkelton />
            <CardSkelton />
            <CardSkelton />
            <CardSkelton />
          </div>
        ) : (
          <div
            className={`grid-display artworkGrid  ${props.flag ? "w-75 active" : ""
              }`}
          >
            {nftData &&
              nftData.map((item, index) => {
                if (item?.listedData?.listed === false || item === undefined) {
                  return (
                    <>
                      <CardSkelton />
                      <CardSkelton />
                      <CardSkelton />
                      <CardSkelton />
                    </>
                  );
                } else {
                  return (
                    <div className="collection-grid-card" key={index}>
                      <div className="card-head  ">
                        <div className="user-img">
                          {address ? (
                            <img
                              src={getArtistImage(address) || thumb}
                              alt="profile image"
                              className="img-100"
                            />
                          ) : (
                            <img
                              src={thumb}
                              alt="profile image"
                              className="img-100"
                            />
                          )}
                        </div>

                        <div className="user-name">
                          <p
                            onClick={() => navigateTo(item, "listed")}
                            className="body-large hover-underline pointer ellipsis-text-collection  "
                          >
                            {item?.metadata?.data?.artName || "Art Name"}
                          </p>

                          <Popover
                            placement="top"
                            trigger="hover"
                            content={
                              <>
                                <ArtistPopUp
                                  userProfile={
                                    getArtistImage(item?.data?.mintArtist) ||
                                    thumb
                                  }
                                  verified={verified}
                                  artistName={getArtistNamebyAdress(
                                    item?.data?.mintArtist
                                  )} // passing artist as prop
                                />
                              </>
                            }
                          >
                            <p
                              onClick={() => {
                                navigate(`/profile/${item?.data?.mintArtist}`);
                                window.location.reload();
                              }}
                              className="fw-bold text-medium-grey hoverBlack ellipsis-container "
                            >
                              <p className="ellipsis-text-collection">
                                {getArtistNamebyAdress(item?.data?.mintArtist)}
                              </p>

                              <img
                                src={verified}
                                alt="verified"
                                className="img-18 ms-1"
                              />
                              {/* artsit pop up here */}
                            </p>
                          </Popover>
                        </div>
                      </div>

                      <div className="card-body">
                        <div
                          className="art-img"
                          onClick={() => navigateTo(item, "listed")}
                        >
                          {item?.metadata?.data?.image && (
                            <img
                              src={item?.metadata?.data?.image}
                              alt="art"
                              className={`img-100 artwork-hover ${loaded ? "loaded" : ""
                                }`}
                              onLoad={handleImageLoad}
                            />
                          )}

                          {item?.metadata?.data?.previewImage && (
                            <img
                              src={item?.metadata?.data?.previewImage}
                              alt="Preview"
                              className={`img-100 artwork-hover ${loaded ? "loaded" : ""
                                }`}
                              onLoad={handleImageLoad}
                            />
                          )}
                        </div>
                        {item.metadata.data.selectedBlockchain ===
                          config.networks.songbird &&
                          item?.data?.listedData?.price ? (
                          <>
                            <div className="chain-logo ">
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltip2(
                                  item?.metadata.data?.selectedBlockchain
                                )}
                                id="tooltip1"
                              >
                                <img src={sgb} alt="chain logo" />
                              </OverlayTrigger>
                            </div>
                            <OverlayTrigger
                              placement="top"
                              overlay={tooltip1(
                                item.data.isSaleListed &&
                                item?.data.listedData?.price /
                                1000000000000000000
                              )}
                              id="tooltip1"
                            >
                              <div className="sgb">
                                <img src={sgb} alt="sgb" />
                                <p className="body-large text-white ms-1">
                                  {item.data.isSaleListed &&
                                    item?.data?.listedData?.price /
                                    1000000000000000000}
                                </p>
                              </div>
                            </OverlayTrigger>
                          </>
                        ) : item.metadata.data.selectedBlockchain ===
                          config.networks.flare &&
                          item?.data?.listedData?.price ? (
                          <>
                            <div className="chain-logo ">
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltip2(
                                  item?.metadata.data?.selectedBlockchain
                                )}
                                id="tooltip1"
                              >
                                <img src={flr} alt="chain logo" />
                              </OverlayTrigger>
                            </div>
                            <OverlayTrigger
                              placement="top"
                              overlay={tooltip1(
                                item.data?.isSaleListed &&
                                item?.data?.listedData?.price /
                                1000000000000000000
                              )}
                              id="tooltip1"
                            >
                              <div className="sgb">
                                <img src={flr} alt="FLR" />
                                <p className="body-large text-white ms-1">
                                  {item.data?.isSaleListed &&
                                    item?.data?.listedData?.price /
                                    1000000000000000000}
                                </p>
                              </div>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <></>
                        )}
                        {item?.metadata?.data?.videoUrl && (
                          <div className="video-icon  position-absolute   ">
                            <img src={vidIcon} alt="" />
                          </div>
                        )}
                      </div>

                      <div className="card-footer">
                        <Popover
                          placement="top"
                          trigger="hover"
                          content={
                            <>
                              <ArtistPopUp
                                userProfile={getArtistImage(
                                  item?.data?.newOwner
                                )}
                                verified={verified}
                                artistName={getArtistNamebyAdress(
                                  item?.data?.newOwner
                                )} // passing artist as prop
                              />
                            </>
                          }
                        >
                          <div className="owner">
                            <p className="body-medium text-medium-grey ">
                              Owner
                            </p>
                            <label
                              onClick={() => {
                                navigate(`/profile/${item?.data?.newOwner}`);
                                window.location.reload();
                              }}
                              className="medium text-black"
                            >
                              <img src={profile} alt="profile" />


                              {getArtistNamebyAdress(item?.data.newOwner)
                                .length > 14
                                ? getArtistNamebyAdress(
                                  item?.data.newOwner
                                ).substring(0, 14) + "..."
                                : getArtistNamebyAdress(
                                  item?.data.newOwner
                                )}
                              {/* {getArtistNamebyAdress(item?.data?.newOwner)} */}
                            </label>
                          </div>
                        </Popover>

                        <div className="divider"></div>

                        <div className="offer">
                          {item.metadata?.data.lastPrice ? (
                            <>
                              <p className="body-medium text-medium-grey">
                                Last Sale Price
                              </p>
                              <label className="medium text-black">
                                {item.metadata?.data.lastPrice /
                                  1000000000000000000}
                              </label>
                            </>
                          ) : item.maxPrice ? (
                            <>
                              <p className="body-medium text-medium-grey">
                                Best Offer
                              </p>
                              <label className="medium text-black">
                                {item?.maxPrice}
                              </label>
                            </>
                          ) : (
                            <>
                              <p className="body-medium text-medium-grey">
                                Price
                              </p>
                              <label className="medium text-black">--</label>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        )}
      </div>

      <div className="content-loader rotate-360 d-none">
        <img src={loader} alt="loader" />
      </div>
    </div>
  );
};

export default UserCollectionGrid;
