import React, { useEffect, useState } from "react";
import "../../style/main.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import star20 from "../../assets/icon/spiked-circle/black/24px.svg";
import profileicon from "../../assets/icon/profile.svg";
import collection from "../../assets/icon/collection.svg";
import noOffer from "../../assets/icon/offers.svg";
import likes from "../../assets/icon/likes.svg";
import ProfileTab from "./profileTab";
import UserCollections from "./userCollections";
import CreatedArtwork from "./createdArtwork";
import Offers from "./offers";
import Likes from "./likes";
import Loader from "../shared/Loader";
import { ClearAllProvider } from "../explore/ClearAllContext";
import AllCollections from "../allcollections";
import {
  getProfileSettingFirebase,
  getUserDataByUserName,
  getUserData,
} from "../../firebase/firebase";
import { useAccount } from "wagmi";

const ProfileTabs = ({
  id,
  setUserDataByUserName,
  address,
  userDataByUserName,
  setReload,
  reload,
  likeArtworks,
  selectedTab,
  load,
}) => {
  const { address: accountAddress } = useAccount();
  const [loading, setLoading] = useState(true);
  const [key, setKey] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);
  const [userAddress, setUserAddress] = useState(" ");
  const [isWhiteListed, setIsWhiteListed] = useState(null);
  useEffect(() => {
    if (selectedTab) {
      setKey(selectedTab);
    }
  }, [selectedTab]);

  // get user data by username from firebase

  useEffect(() => {

    let adr;
    if (!id) {
      adr = address;
    } else {
      adr = id;
    }
    const fetchData = async () => {
      if (address) {
        let res = await getUserData(adr);

        if (res) {
          setIsWhiteListed(res?.isWhiteListed);
        }
      }
    };
    fetchData();
    setReload(false);
  }, [userDataByUserName, reload, id, address]);

  // get profile setting from firebase

  useEffect(() => {
    const fetchProfileSetting = async () => {
      let adr;
      if (!id) {
        adr = address;
      } else {
        adr = id;
      }
      const profileSetting = await getProfileSettingFirebase(adr);

      if (
        profileSetting &&
        profileSetting.hasOwnProperty("private") &&
        adr !== accountAddress
      ) {
        setIsPrivate(profileSetting.private);
        setLoading(false);
      } else {
        setIsPrivate(false);
        setLoading(false);
      }
    };
    fetchProfileSetting();

    if (!id) {
      // If there's no id, wait for 3 seconds and then set loading to false
      const timeout = setTimeout(() => {
        setLoading(false);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [address, userDataByUserName, id]);



  useEffect(() => {

    if (userDataByUserName?.isWhiteListed) {
      setKey("created");
    } else {
      setKey("collected");
    }
  }, [userDataByUserName]);

  return (
    <>
      {loading ? (
        <div>
          <Loader />
        </div>
      ) : isPrivate ? (
        <></>
      ) : (
        <div className="profile-tabs tabs-parent pb-5 mb-5">
          <Tabs
            defaultActiveKey="profile"
            id="uncontrolled-tab-example"
            className="mb-3 profile-tabs"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            {userDataByUserName?.isWhiteListed && (
              <Tab
                eventKey="created"
                title={
                  <span>
                    <img
                      src={key === "created" ? star20 : profileicon}
                      alt="star"
                      className={
                        key === "created"
                          ? "hide-on-mobile spikeimg"
                          : "hide-on-mobile"
                      }
                    />
                    Artwork
                  </span>
                }
              >
                <ClearAllProvider>
                  <CreatedArtwork
                    address={address}
                    id={id}
                    userDataByUserName={userDataByUserName}
                    reload={reload}
                    setReload={setReload}
                    load={load}
                  />
                </ClearAllProvider>
              </Tab>
            )}

            {userDataByUserName?.isWhiteListed && (
              <Tab
                eventKey="collections"
                title={
                  <span className="pointer">
                    <img
                      // src={key == "collections" ? star20 : grid}
                      alt="star"
                      className={
                        key == "collections"
                          ? "hide-on-mobile spikeimg"
                          : "hide-on-mobile"
                      }
                    />
                    collections
                  </span>
                }
              >
                <ClearAllProvider>
                  <AllCollections loc={"profile"} data={userDataByUserName} />
                </ClearAllProvider>
              </Tab>
            )}
            <Tab
              eventKey="collected"
              title={
                <span>
                  <img
                    src={key === "collected" ? star20 : collection}
                    alt="collection"
                    className={
                      key === "collected"
                        ? "hide-on-mobile spikeimg"
                        : "hide-on-mobile"
                    }
                  />
                  Owned
                </span>
              }
            >
              <ClearAllProvider>
                <UserCollections
                  id={id}
                  userDataByUserName={userDataByUserName}
                  propFromCollections="d-nones"
                  address={address}
                  load={load}
                />
              </ClearAllProvider>
            </Tab>
            {!id && (
              <Tab
                eventKey="offers"
                title={
                  <span>
                    <img
                      src={key === "offers" ? star20 : noOffer}
                      alt="offer"
                      className={
                        key === "offers"
                          ? "hide-on-mobile spikeimg"
                          : "hide-on-mobile"
                      }
                    />
                    Offers
                  </span>
                }
              >
                <Offers
                  address={address}
                  id={id}
                  userDataByUserName={userDataByUserName}
                />
              </Tab>
            )}
            <Tab
              eventKey="like"
              title={
                <span>
                  <img
                    src={key === "like" ? star20 : likes}
                    alt="like"
                    className={
                      key === "like"
                        ? "hide-on-mobile spikeimg"
                        : "hide-on-mobile"
                    }
                  />
                  Likes
                </span>
              }
            >
              <Likes
                address={address}
                id={id}
                userDataByUserName={userDataByUserName}
                propFromCollections="d-nones"
                likeArtworks={likeArtworks}
              />
            </Tab>
            <Tab
              eventKey="profile"
              title={
                <span>
                  <img
                    src={key === "profile" ? star20 : profileicon}
                    alt="star"
                    className={
                      key === "profile"
                        ? "hide-on-mobile spikeimg"
                        : "hide-on-mobile"
                    }
                  />
                  About
                </span>
              }
            >
              <ProfileTab
                id={id}
                accountAddress={address}
                userDataByUserName={userDataByUserName}
                setUserDataByUserName={setUserDataByUserName}
                reload={reload}
                setReload={setReload}
              />
            </Tab>
          </Tabs>
        </div>
      )}
    </>
  );
};

export default ProfileTabs;
