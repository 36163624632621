import React, { useEffect, useState } from "react";
import "../../style/main.scss";
import verified from "../../assets/icon/verified-artist-small.svg";
import sgb from "../../assets/icon/SGB.svg";
import FLR from "../../assets/icon//FLR.svg";
import profile from "../../assets/icon/profile-picture.svg";
import loader from "../../assets/icon/loader-medium.svg";
import chainImage from "../../assets/icon/SGB.svg";
import userProfile from "../../assets/images/face-3.png";
import vidIcon from "../../assets/icon/video-fill.svg";
import thumb from "../../assets/icon/profile-picture.svg";
import ArtistPopUp from "../shared/artistpopup";
import AsideFilterExplore from "../explore/asideFilterExplore";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import marketplaceContractABI from "../../abis/Marketplace/v3/abi.json";
import mintContractABI from "../../abis/SafeMint/v2/abi.json";
import Web3 from "web3";
import CardSkelton from "../shared/cardSkelton";
import config from "../../config";
import {
  getAllUsers,

  getArtLastPriceFromFirebase,
  getContractAddressByCollectionId
} from "../../firebase/firebase";
import { useAccount } from "wagmi";
import { Popover } from "antd";

const LayoutArtwork = (props) => {
  // market place contract address
  const { marketplace, erc20 } = config;




  const Marketplace_coston_contractAddress =
    marketplace.coston;
  const Marketplace_coston2_contractAddress =
    marketplace.coston2;
  const contractABI = marketplaceContractABI;

  const coston_Token = erc20.coston;
  const coston2_Token = erc20.coston2;

  const [collectionDetails, setCollectionDetails] = useState(props?.ApvNFT);
  const [nftDetails, setNftDetails] = useState([]);
  const [listedNfts, setListedNfts] = useState([]);
  const [web3, setWeb3] = useState(null);
  const { address } = useAccount();
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const [collectionData, setCollectionData] = useState([]);
  const [nftFilteredDetails, setNftFilteredDetails] = useState(collectionData);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  // setting rpc url

  useEffect(() => {
    try {
      if (collectionDetails?.selectedNetwork === config.networks.songbird) {
        setWeb3(new Web3(config.rpc.songbird));
      }
      if (collectionDetails?.selectedNetwork === config.networks.flare) {
        setWeb3(new Web3(config.rpc.flr));
      }
    }
    catch (error) {
      console.log(error);
    }

  }, [collectionDetails]);

  // get all listed data from blockchain

  const getAllListNFTData = async () => {

    try {
      if (web3 !== null) {
        let MarketplaceAddress;
        if (collectionDetails?.selectedNetwork === config.networks.songbird) {
          MarketplaceAddress = Marketplace_coston_contractAddress;
        } else if (collectionDetails?.selectedNetwork === config.networks.flare) {
          MarketplaceAddress = Marketplace_coston2_contractAddress;
        }

        const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);
        const data = await contract.methods.getAllListedNfts().call();

        setListedNfts(data);
      }
    }
    catch (error) {
      console.log(error, "error");
    }

  };

  useEffect(() => {
    setNftDetails(props?.nftDetails);
    getAllListNFTData();
  }, [props?.nftDetails, web3]);

  // get connected users nfts from blockchain





  // mapping listed data to nfts

  useEffect(() => {
    if (props?.nfts !== undefined) {
      let nftListDetails = [];

      props?.nfts.forEach((item) => {
        let isSaleListed = false;
        let isOfferListed = false;

        // Check for sale listings
        listedNfts[0]?.forEach((nft) => {
          if (nft?.uriData === item?.uri) {
            isSaleListed = true;
            nftListDetails.push({
              ...nft,
              ...item,
              isListed: true,
              isSaleListed: true,
            });
          }
        });

        // Check for offer listings
        listedNfts[1]?.forEach((nft) => {
          if (nft?.uriData === item?.uri) {
            isOfferListed = true;
            const existingIndex = nftListDetails.findIndex(
              (nftDetail) => nftDetail.uri === item.uri
            );

            if (existingIndex !== -1) {
              nftListDetails[existingIndex] = {
                ...nftListDetails[existingIndex],
                isOfferListed: true,
              };
            } else {
              nftListDetails.push({
                ...nft,
                ...item,
                isListed: true,
                isOfferListed: true,
              });
            }
          }
        });

        // Ensure both flags are set correctly for NFTs listed in both categories
        if (isSaleListed || isOfferListed) {
          const existingIndex = nftListDetails.findIndex(
            (nftDetail) => nftDetail.uri === item.uri
          );
          if (existingIndex !== -1) {
            nftListDetails[existingIndex] = {
              ...nftListDetails[existingIndex],
              isListed: true,
              isSaleListed: isSaleListed,
              isOfferListed: isOfferListed,
            };
          }
        }
      });


      // Fetch and set last price for each NFT
      nftListDetails.forEach(async (e) => {
        let Price = await getArtLastPriceFromFirebase(e?.data?.artId);
        if (Price) {
          e.data.lastPrice = Price;
        }
      });

      bid(nftListDetails);

      setCollectionData(nftListDetails);
    } else {
      setCollectionData([]);
      setNftFilteredDetails([]);
    }
  }, [nftDetails, listedNfts, props?.nfts]);

  // calculating maximum offer price

  const bid = async (nfts) => {


    try {
      let data = nfts;
      let offerListed = nfts.filter((item) => item.isOfferListed);
      let MarketplaceAddress;

      if (collectionDetails?.selectedNetwork === config.networks.songbird) {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (collectionDetails?.selectedNetwork === config.networks.flare) {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }

      // getting bidding history from blockchain-

      const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);
      const updatedDetails = await Promise.all(
        offerListed.map(async (item) => {
          const biddingHistoryCoston = await contract.methods
            .getBiddingHistory(item?.listCount)
            .call();

          // Calculate max price from bidding history
          const maxPrice = calculateMaxPrice(biddingHistoryCoston);

          return { ...item, maxPrice: maxPrice / 1000000000000000000 }; // Assuming maxPrice is in wei, converting to ether
        })
      );

      setNftFilteredDetails((prevDetails) => {
        const unchangedItems = data.filter((prevItem) => !prevItem.isOfferListed);
        const mergedDetails = [
          ...unchangedItems,
          ...updatedDetails.filter(Boolean),
        ];
        return mergedDetails;
      });
    }
    catch (error) {
      console.log(error, "error");
    }

  };

  // Function to calculate the maximum price from bidding history
  const calculateMaxPrice = (biddingHistoryArray) => {
    let maxPrice = 0;
    for (const biddingHistory of biddingHistoryArray) {
      for (const key in biddingHistory) {
        if (Object.prototype.hasOwnProperty.call(biddingHistory, key)) {
          const currentPrice = parseFloat(biddingHistory[key]);
          if (currentPrice && !isNaN(currentPrice) && currentPrice > maxPrice) {
            maxPrice = currentPrice;
          }
        }
      }
    }
    return maxPrice;
  };

  // Filters (Recently Minted, Recently Listed, Lowest Price, Highest Price)
  useEffect(() => {
    if (props?.sortFilter !== undefined) {
      let sortedData = [];
      if (props?.sortFilter === "Recently Minted") {
        sortedData = collectionData.sort((a, b) => {
          const dateA = new Date(a?.data?.mintedAt);
          const dateB = new Date(b?.data?.mintedAt);
          return dateB - dateA;
        });
      } else if (props?.sortFilter === "Recently Listed") {
        sortedData = collectionData
          .filter((item) => {
            return item.isListed;
          })
          .sort((a, b) => {
            const dateA = new Date(parseInt(a?.listedData?.listTime) * 1000);
            const dateB = new Date(parseInt(b?.listedData?.listTime) * 1000);
            return dateB - dateA;
          });
      } else if (props?.sortFilter === "Lowest price") {
        if (collectionData?.length > 0) {
          sortedData = collectionData
            .filter((item) => {
              return item.isListed;
            })
            .sort((a, b) => {
              if (a.isSaleListed && b.isSaleListed) {
                return (
                  parseFloat(a?.listedData?.price) -
                  parseFloat(b?.listedData?.price)
                );
              }
              if (a.isOfferListed && b.isOfferListed) {
                return (
                  parseFloat(a?.listedData?.minimumBid) -
                  parseFloat(b?.listedData?.minimumBid)
                );
              }
            });
        }
      } else if (props?.sortFilter === "Highest price") {
        if (collectionData.length > 0) {
          sortedData = collectionData
            .filter((item) => {
              return item.isListed; // Only keep listed items
            })
            .sort((a, b) => {
              // Prioritize sale listings over offer listings
              if (a.isSaleListed && b.isSaleListed) {
                return (
                  parseFloat(b?.listedData?.price) -
                  parseFloat(a?.listedData?.price)
                );
              }
              if (a.isOfferListed && b.isOfferListed) {
                return (
                  parseFloat(b?.listedData?.minimumBid) -
                  parseFloat(a?.listedData?.minimumBid)
                );
              }
            });
        }
      }
      setNftFilteredDetails(sortedData);
    }
  }, [props?.sortFilter]);

  // side filter (buy now, offer now , price, category, network)
  const handleSelectedFilterChange = (newFilter) => {

    let filteredData = [];

    if (collectionData?.length > 0) {

      // Define conversion rates for USD based on selected blockchain
      const conversionRates = {
        coston: props.sgb,
        coston2: props.flr,
      };

      // Function to apply price filter
      const applyPriceFilter = (item) => {
        let priceFilter = true;

        if (newFilter.selectPrice.min !== "" || newFilter.selectPrice.max !== "") {
          let priceInEth = parseFloat(item?.listedData?.price) / 1e18;


          // Adjust price based on selected priceCurrency
          if (newFilter.priceCurrency === "USD") {
            const blockchain = item?.metadata?.selectedBlockchain?.toLowerCase();
            const conversionRate = conversionRates[blockchain] || 1; // Default rate if not found
            priceInEth *= conversionRate;
          } else if (
            newFilter.priceCurrency === "CFLR" &&
            item?.data?.selectedBlockchain?.toLowerCase() !== "coston"
          ) {
            priceFilter = false;
          } else if (
            newFilter.priceCurrency === "C2FLR" &&
            item?.data?.selectedBlockchain?.toLowerCase() !== "coston2"
          ) {
            priceFilter = false;
          } else if (
            newFilter.priceCurrency === "SGB" &&
            item.data.selectedBlockchain?.toLowerCase() !== "sgb"
          ) {
            priceFilter = false;
          } else if (
            newFilter.priceCurrency === "FLR" &&
            item.data.selectedBlockchain?.toLowerCase() !== "flr"
          ) {
            priceFilter = false;
          }

          // Apply price range filtering
          if (priceFilter) {
            priceFilter =
              (newFilter.selectPrice.min === "" || priceInEth >= parseFloat(newFilter.selectPrice.min)) &&
              (newFilter.selectPrice.max === "" || priceInEth <= parseFloat(newFilter.selectPrice.max));
          }
        }

        return priceFilter;
      };

      // Get current time for early access calculation
      const now = Date.now();

      collectionData.forEach((item) => {
        const isCategorySelected = Object.values(newFilter.selectCategories).some((category) => category);
        const selectedCurrency = Object.values(newFilter.selectCurrency).some((currency) => currency);

        let categoriesFilter = true;
        let currencyFilter = true;
        let statusFilter = true;
        let earlyAccessFilter = true;

        // Calculate early access based on listTime (Unix timestamp in seconds)
        const listTime = item?.listedData.listTime;

        const mintedAt = listTime ? new Date(listTime * 1000) : null;

        if (mintedAt) {
          const timeElapsed = now - mintedAt; // Calculate the time difference in milliseconds
          const hoursElapsed = Math.floor(timeElapsed / (1000 * 60 * 60)); // Convert milliseconds to hours
          earlyAccessFilter = hoursElapsed < 24 && item.isSaleListed; // Check if minted within the last 24 hours
        }


        // Apply categories filter
        if (isCategorySelected) {
          categoriesFilter = Object.keys(newFilter.selectCategories).every(
            (category) =>
              newFilter.selectCategories[category]
                ? item?.data?.selectedTags?.includes(category)
                : true
          );
        }

        // Apply currency filter
        if (selectedCurrency) {
          currencyFilter =
            newFilter.selectCurrency.allChains ||
            (newFilter.selectCurrency.flr && item?.data?.selectedBlockchain?.toLowerCase() === "flare network") ||
            (newFilter.selectCurrency.sgb && item?.data?.selectedBlockchain?.toLowerCase() === "songbird network") ||
            (newFilter.selectCurrency.coston && item?.data?.selectedBlockchain?.toLowerCase() === "coston") ||
            (newFilter.selectCurrency.coston2 && item?.data?.selectedBlockchain?.toLowerCase() === "coston2");
        }

        // Apply status filter
        if (
          newFilter.selectStatus.buyNow ||
          newFilter.selectStatus.onOffer ||
          newFilter.selectStatus.new ||
          newFilter.selectStatus.earlyAccess // Check for early access filter
        ) {
          statusFilter =
            (newFilter.selectStatus.buyNow && item?.isSaleListed) ||
            (newFilter.selectStatus.onOffer && item?.isOfferListed) ||
            (newFilter.selectStatus.new && !item?.isListed) ||
            (newFilter.selectStatus.earlyAccess && earlyAccessFilter);
        }

        // Apply all filters
        if (categoriesFilter && currencyFilter && applyPriceFilter(item) && statusFilter) {
          filteredData.push(item);
        }
      });


      // Set the filtered data
      setNftFilteredDetails(filteredData);
    }
  };

  const getContractAddressById = async (item) => {
    let res = await getContractAddressByCollectionId(item?.data?.selectedCollectionId);
    if (res) {
      return res;
    }

  }

  const navigateToSingleArtwork = async (item) => {

    let collectionId = await getContractAddressById(item);
    let network = item?.data.selectedBlockchain;
    let tokenId;
    if (item?.listedData) {
      tokenId = item?.listedData.tokenId;
    } else {
      tokenId = await item?.tokenId;
    }
    navigate(`/artwork/eth/${collectionId}/${tokenId}/`);
  };

  const [userData, setUserData] = useState([]);

  const checkUser = async () => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUserData(user);
    }
  };

  useEffect(() => {
    checkUser();
    const fetchUserData = async () => {
      const users = await getAllUsers();
      setUserData(users);
    };
    fetchUserData();
  }, []);

  // get artist name by address

  const getArtistNamebyAdress = (accountAddress) => {
    let artistName = "";

    if (address === accountAddress) {
      return "You";
    } else {
      userData?.forEach((user) => {
        if (user?.id === accountAddress) {
          artistName = user?.userName;
        }
      });
    }

    return "@" + artistName;
  };



  const [collectionDataDisplayed, setCollectionDataDisplayed] = useState([]);



  // Filters (Recently Minted, Recently Listed, Lowest Price, Highest Price)
  useEffect(() => {

    if (props?.sortFilter !== undefined) {
      let sortedData = [];
      if (props?.sortFilter === "Recently Minted") {
        sortedData = nftFilteredDetails.sort((a, b) => {
          const dateA = new Date(a?.data?.mintedAt);
          const dateB = new Date(b?.data?.mintedAt);
          return dateB - dateA;
        });
      } else if (props?.sortFilter === "Recently Listed") {
        sortedData = nftFilteredDetails
          .filter((item) => {
            return item.isListed;
          })
          .sort((a, b) => {
            const dateA = new Date(parseInt(a?.listedData?.listTime) * 1000);
            const dateB = new Date(parseInt(b?.listedData?.listTime) * 1000);
            return dateB - dateA;
          });
      } else if (props?.sortFilter === "Lowest price") {

        if (nftFilteredDetails?.length > 0) {

          const nftsWithPrice = [...nftFilteredDetails].filter(
            (a) => a?.listedData?.price !== undefined
          );
          const nftsWithoutPrice = [...nftFilteredDetails].filter(
            (a) => a?.listedData?.price === undefined
          );

          const sortByLowestPrice = nftsWithPrice.sort((a, b) => {
            const aPrice = a?.listedData?.price;
            const bPrice = b?.listedData?.price;

            return aPrice - bPrice;
          });

          // Concatenate sorted NFTs with price and NFTs without price
          sortedData = [...sortByLowestPrice, ...nftsWithoutPrice];
        }
      } else if (props?.sortFilter === "Highest price") {

        const nftWithPrice = [...nftFilteredDetails].filter((a) =>
          a.listedData.price !== undefined && a.listedData.price !== null);


        const nftsWithoutPrice = [...nftFilteredDetails].filter((a) =>
          a.listedData.price === undefined || a.listedData.price === null);

        const sortByHighestPrice = [...nftWithPrice].sort((a, b) => {

          const aPrice = a?.listedData?.price;
          const bPrice = b?.listedData?.price;

          return bPrice - aPrice;

        })
        sortedData = [...sortByHighestPrice, ...nftsWithoutPrice];
      }
      setCollectionDataDisplayed(sortedData);

    }
  }, [props?.sortFilter, nftFilteredDetails]);
  // set number of total nfts

  useEffect(() => {
    props.setItemNumber(nftFilteredDetails.length);
  }, [nftFilteredDetails]);

  return (
    <div>
      <div
        className={
          props.flag ? "d-flex align-item-center justify-content-between" : ""
        }
      >
        <div className={props.flag ? "my-filter w-25" : "d-none"}>
          {/* Filter */}
          <AsideFilterExplore
            onSelectedFilterChange={handleSelectedFilterChange}
            clearAll={props?.clearAll}
          />
        </div>
        <div
          className={`grid-display layout-artwork  ${props.flag ? "w-75 active" : ""
            }`}
        >
          {/* nfts */}
          {collectionDataDisplayed.map((item, index) => {
            return (
              <div onClick={() => {
                navigateToSingleArtwork(item);
              }} className="collection-grid-card" key={index}>
                <div className="card-body">
                  <div className="art-img">
                    {item?.data?.image ? (
                      <img
                        src={item?.data?.image}
                        className="img-100 artwork-hover"
                      />
                    ) : (
                      <img
                        src={item?.data?.previewImg}
                        className="img-100 artwork-hover"
                      />
                    )}
                    <img src={item.chainimg} className="chainImage" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LayoutArtwork;
