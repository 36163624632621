import React, { useState, useEffect } from "react";
import "../style/main.scss";
import logo from "../assets/icon/logo.svg";
import star from "../assets/images/star35.png";
import { Link } from "react-router-dom";
import { getSpotlightUser } from "../firebase/firebase";
const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();
  const [spotlightActive, setSpotlightActive] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {

        const users = await getSpotlightUser();
        setSpotlightActive(users?.[0]?.spotActive || null);
      } catch (error) {
        console.error(error);
        setSpotlightActive(null);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {/* It contains widgets links */}
      <section className="footer">
        <div className="footer-content">
          <div className="left">
            <div className="site-logo">
              <div>
                <img src={star} alt="logo" className="hide-on-desktop" />
              </div>
              <div>
                <img src={logo} alt="logo" />
              </div>
            </div>
            <h4>© {year} The Catalyst</h4>
          </div>
          <div className="center f-links">
            <a href="/explore">Explore Art</a>
            {/* <a href="/allcollections">Explore Drops</a> */}
            {spotlightActive && <Link to="/spotlight">Spotlight</Link>}
            <Link to="/report-issue">Report Content</Link>
          </div>
          <div className="right f-links">
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/terms">Terms and Conditions</Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
