import React, { useEffect, useState } from "react";
import unCheck from "../../assets/icon/checkbox.svg";
import checked from "../../assets/icon/checkbox-selected.svg";
import successWhite from "../../assets/icon/tick-white.svg";
import arrow from "../../assets/icon/chevron-up-small.svg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  getSettingFirebase,
  saveSettingToFirebase,
} from "../../firebase/firebase";
import { useAccount } from "wagmi";
import { notification } from "antd";


// store noification setting to firebase

const NotificationSettings = (props) => {
  // status checkbox states
  const { address } = useAccount();
  const [selectNotif, setSelectNotif] = useState({
    soldBy: true,
    recievedOffer: true,
    outBid: true,
    purchasedBy: true,
    newFollower: true,
    followActivity: true,
  });
  const handleNotifications = (tag) => {
    toast.success(`Settings updated successfully`);

    setSelectNotif((prevSelectedTags) => {

      const updatedTags = {
        ...prevSelectedTags,
        [tag]: !prevSelectedTags[tag],
      };
      // Call saveSetting with the updated state
      saveSettingToFirebase(updatedTags, address);

      return updatedTags;
    });
  };



  // getting firebase setting from firebase

  const navigate = useNavigate();

  const getSetting = async () => {
    const settings = await getSettingFirebase(address);
    if (settings?.data) {
      setSelectNotif(settings?.data);
    }



  };
  useEffect(() => {
    getSetting();
  }, []);

  return (
    <div>
      <section className={`settings site-container ${props.activeClass}`}>
        {/* desktop */}
        {/* <h3 className="fw-bolder hide-on-mobile">Notification settings</h3> */}
        {/* mobile */}
        <h6
          className="  mt-0 v-center hide-on-desktop bb-lightest-grey pb-4"
          onClick={() => navigate("/account-settings")}
        >
          <img src={arrow} alt="arrow" className="me-3 rotate-270  " />
          Notification settings
        </h6>

        <div className="notification ">
          {/* item sold */}
          <div className="notification-box mh-80 v-center ">
            <p
              className={`body-large   fw-normal v-center pointer  ${selectNotif?.soldBy ? "selected" : ""
                }`}
              onClick={() => handleNotifications("soldBy")}
            >
              <img
                src={selectNotif?.soldBy ? checked : unCheck}
                alt="checkbox"
              />
              Item sold
            </p>
            <p className="text-medium-grey ">
              When someone purchases one of your items
            </p>
          </div>

          {/* Bid Activity */}

          <div className="notification-box mh-80 v-center">
            <p
              className={`body-large   fw-normal v-center pointer ${selectNotif?.recievedOffer ? "selected" : ""
                }`}
              onClick={() => handleNotifications("recievedOffer")}
            >
              <img
                src={selectNotif?.recievedOffer ? checked : unCheck}
                alt="checkbox"
              />
              Bid Activity
            </p>
            <p className="text-medium-grey ">
              When someone bids on one of your items
            </p>
          </div>

          {/* Outbid */}
          <div className="notification-box mh-80 v-center">
            <p
              className={`body-large   fw-normal v-center pointer ${selectNotif?.outBid ? "selected" : ""
                }`}
              onClick={() => handleNotifications("outBid")}
            >
              <img
                src={selectNotif?.outBid ? checked : unCheck}
                alt="checkbox"
              />
              Outbid
            </p>
            <p className="text-medium-grey ">
              When an offer you placed is exceeded by another user
            </p>
          </div>

          {/* Auction Expiration */}


          {/*Successful Purchase  */}
          <div className="notification-box mh-80 v-center">
            <p
              className={`body-large   fw-normal v-center pointer ${selectNotif?.purchasedBy ? "selected" : ""
                }`}
              onClick={() => handleNotifications("purchasedBy")}
            >
              <img
                src={selectNotif?.purchasedBy ? checked : unCheck}
                alt="checkbox"
              />
              Successful Purchase
            </p>
            <p className="text-medium-grey ">
              When you successfully purchase an item
            </p>
          </div>

          {/* New Follower */}
          <div className="notification-box mh-80 v-center">
            <p
              className={`body-large   fw-normal v-center pointer ${selectNotif?.newFollower ? "selected" : ""
                }`}
              onClick={() => handleNotifications("newFollower")}
            >
              <img
                src={selectNotif?.newFollower ? checked : unCheck}
                alt="checkbox"
              />
              New Follower
            </p>
            <p className="text-medium-grey ">When you gain a new follower</p>
          </div>

          {/* Follow Activity*/}
          <div className="notification-box mh-80 v-center">
            <p
              className={`body-large   fw-normal v-center pointer ${selectNotif?.followActivity ? "selected" : ""
                }`}
              onClick={() => handleNotifications("followActivity")}
            >
              <img
                src={selectNotif?.followActivity ? checked : unCheck}
                alt="checkbox"
              />
              Follow Activity
            </p>
            <p className="text-medium-grey ">
              When someone you follow lists an item for sale
            </p>
          </div>

          {/* Price Change */}

        </div>

        {/* <div className={success ? "success-alert " : "success-alert d-none"}>
          <img src={successWhite} alt="success" />
          <p className="body-medium ">Settings updated successfully</p>
        </div> */}
      </section>
    </div>
  );
};

export default NotificationSettings;
