import React, { useState, useEffect, useContext } from "react";
import Button from "../button";
import "../../style/main.scss";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../../firebase/firebase";
import { handleFollow } from "../../firebase/firebase";
import { checkIfFollowed } from "../../firebase/firebase";
import thumb from "../../assets/icon/profile-picture.svg";
import marketplaceContractABI from "../../abis/Marketplace/abi.json";
import mintContractABI from "../../abis/SafeMint/abi.json";
import { useNetwork } from "wagmi";
import { useAccount } from "wagmi";
import Web3 from "web3";
import { NFTContext } from "../context/appContext";
import { Skeleton } from "antd";
import {
  unfollowArtist,
  getUserDataByUserName,
  getAllUsers,
  getCollectionByAddress,
  getFollowersByAddress,
  handleNotifications
} from "../../firebase/firebase";
import config from "../../config";

const ArtistPopUp = ({ userProfile, verified, artistName }) => {
  const { address } = useAccount();
  const [totalFollowers, setTotalFollowers] = useState(0);
  const [userAddress, setUserAddress] = useState(null);
  const [userData, setUserData] = useState([]);
  const [isWhiteListed, setIsWhiteListed] = useState(null);
  const [collected, setCollected] = useState(false);
  const { createdArtworks } = useContext(NFTContext)
  const [user, setUser] = useState([]);
  const [collections, setCollection] = useState([]);
  const [created, setCreated] = useState(0);
  const [name, setName] = useState(null);
  const [getAllUsersData, setGetAllUsersData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [inCompleteProfile, setInCompleteProfile] = useState(true);
  const [disable, setDisable] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false);

  const navigate = useNavigate();


  // network name
  const { chain } = useNetwork();

  const { marketplace } = config;
  const Marketplace_coston_contractAddress =
    marketplace.coston;
  const Marketplace_coston2_contractAddress =
    marketplace.coston2;
  const contractABI = marketplaceContractABI;

  useEffect(() => {
    const getData = async () => {
      let res = await getUserData(address);
      setUser(res?.userName);
      let artist;
      if (artistName?.startsWith('@')) {
        artist = artistName.slice(1);
      }
      else {
        artist = artistName;
      }
      if (artist === "You" || artist === res?.userName) {
        setName(res?.userName);
      }
      else {
        setName(artist);
      }
    };
    getData();
  }, [address]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getUserDataByUserName(name);
        if (res) {
          setUserAddress(res?.documentId);
          setIsWhiteListed(res?.s?.isWhiteListed);
        }
      } catch (error) {
        setIsWhiteListed(false);
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [name, artistName]);

  useEffect(() => {
    if (name == user) {
      const getCollection = async () => {
        let res = await getCollectionByAddress(address);
        setCollection(res);
      }
      getCollection();
    }
    else {
      const getCollection = async () => {
        let res = await getCollectionByAddress(userAddress);
        setCollection(res);
      }
      getCollection();
    }
  }, [address, userAddress, name, address])

  const getCreatedData = async () => {
    setLoading(true);
    let addr;
    if (name == user) {
      addr = address;
    } else {
      addr = userAddress;
    }
    if (collections?.length > 0) {
      let total = 0;
      let web3_1;

      for (let i = 0; i < collections.length; i++) {
        if (collections[i]?.selectedNetwork === config.networks.songbird) {
          web3_1 = new Web3(config.rpc.songbird);
        }
        else {
          web3_1 = new Web3(config.rpc.flr);
        }
        if (web3_1 !== null) {
          const contract = new web3_1.eth.Contract(
            mintContractABI,
            collections[i]?.contractAddress
          );
          try {
            const data = await contract.methods._tokenIdCounter().call();
            const parsedData = parseFloat(data.trim());
            if (!isNaN(parsedData)) {
              total += parsedData;
            } else {
              console.error(`Unable to parse data: ${data}`);
            }
          } catch (error) {
            console.error(`Error fetching data for contract ${collections[i]?.contractAddress}:`, error);
          }
        }
      }
      setLoading(false);
      setCreated(total);
    }
  };

  useEffect(() => {
    getCreatedData();
  }, [address, userAddress, name, userAddress, collections])


  const getCollected = async (addr) => {
    setLoading(true);
    if (chain && addr) {
      const web3_1 = new Web3(window.ethereum);
      try {
        let MarketplaceAddress;
        if (chain.name === config?.networks?.songbird) {
          MarketplaceAddress = Marketplace_coston_contractAddress;
        } else if (chain.name === config?.networks?.flare) {
          MarketplaceAddress = Marketplace_coston2_contractAddress;
        } else {
          throw new Error("Unsupported chain name");
        }
        const contract = new web3_1.eth.Contract(
          contractABI,
          MarketplaceAddress
        );
        const result = await contract.methods.userBuyRecord(addr).call();
        setCollected(result);
        setLoading(false);

      } catch (error) {
        console.error("Error fetching user records:", error);
        setLoading(false);

      }
    }
  };

  // fetch all users data
  useEffect(() => {
    const fetchUserData = async () => {
      const users = await getAllUsers();
      setGetAllUsersData(users);
      for (let a of users) {
        if (a?.id === address) {
          if (a.userName) {
            setInCompleteProfile(false);
          }
        }
      }
      setUserData(users);
    };
    fetchUserData();
  }, []);

  // get user data by address
  useEffect(() => {
    const fetchData = async () => {
      let rest = await getUserData(address);
      setUserData(rest);
    };
    fetchData();
  }, [address]);

  // get user data by username from firebase
  useEffect(() => {
    if (name === user) {
      getCollected(address);
      getCreatedData();
    } else {
      getCollected(userAddress)
      getCreatedData();
    }
  }, [address, userAddress, name, collections]);

  useEffect(() => {
    const isLogged = localStorage.getItem("catalystSigner");
    if ((isLogged && address)) {
      setDisable(false);
    }
    else {
      setDisable(true);
    }
  }, [address, localStorage.getItem("catalystSigner")]);


  // fetch follow status from firebase based on username and artistname
  useEffect(() => {
    const check = async () => {
      await checkIfFollowed(address, userAddress);
    };
    check();
  }, [address, userAddress]);

  const fetchFollowStatus = async () => {
    const res = await getUserDataByUserName(name);
    if (res) {
      setUserAddress(res?.documentId);
    }
    const result = await checkIfFollowed(address, res?.documentId);
    setIsFollowed(result);
  };

  // get Follow data from firebase
  const getFollowData = async () => {
    setTotalFollowers(0);
    let result;
    if (name === user) {
      result = await getFollowersByAddress(address);
    } else {
      result = await getFollowersByAddress(userAddress);
    }
    setTotalFollowers(result?.count > 0 ? result.count : 0);
  };


  useEffect(() => {
    fetchFollowStatus();
    getFollowData();
  }, [artistName, name, address, userAddress]);

  const followBtn = async () => {
    if (userAddress) {
      try {
        if (isFollowed) {
          await unfollowArtist(address, userAddress);
          toast.warn("Unfollowed Successfully");
          fetchFollowStatus();
        } else {
          await handleFollow(address, userAddress);
          toast.success("Followed Successfully");

          handleNotifications(
            userAddress,
            "newFollower",
            null,
            null,
            null,
            null,
            null,
            address

          )
          fetchFollowStatus();
        }
        // Update the isFollowed state after follow/unfollow action
        setIsFollowed(!isFollowed);

        getFollowData();
      } catch (error) {
        console.error("Error toggling follow status:", error);
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  useEffect(() => {
    fetchFollowStatus();
  }, []);

  const navigateToProfile = () => {
    if ((address && address.trim() !== "") || (userAddress && userAddress.trim() !== "")) {
      if (artistName === "You") {
        navigate(`/profile/${address}`);
      } else {
        navigate(`/profile/${userAddress}`);
      }
      // Reload the page for smooth reflection of changes
      window.location.reload();
    } else {
      console.error("Address or UserAddress is invalid");
    }
  }

  return (

    <>
      <div className="artist-popup" >
        {
          isWhiteListed == null ? (
            <Skeleton
              avatar
              paragraph={{ rows: 2 }}
              active
            />
          ) : (
            <>
              <div className="pop-head ">
                <div className="user-img" onClick={navigateToProfile}>
                  {!userProfile ? (
                    <img src={thumb} className="img-100 m-0 rounded-circle" />
                  ) : (
                    <img src={userProfile} className="img-100 m-0 rounded-circle" />
                  )}
                </div>
                <p
                  className="body-large pointer v-center ellipsis-text"
                  onClick={navigateToProfile}
                >
                  <span className="ellipsis-text">
                    {artistName === user ? "You" : artistName}
                  </span>

                  {isWhiteListed && <img src={verified} alt="verified" />}
                </p>
              </div>

              <div className="popup-body">
                <div className="created">
                  {isWhiteListed ? (

                    <>
                      <label className="medium">Created</label>
                      <label className="text-black">{created}</label>
                    </>
                  ) : (

                    <>
                      <label className="medium">Collected</label>
                      <label className="text-black">{collected || 0}</label>
                    </>
                  )}
                </div>
                <div className="created">
                  <label className="medium">Followers</label>
                  <label className="text-black">{totalFollowers}</label>
                </div>
              </div>
              {isFollowed ? (
                <Button
                  text={isFollowed ? "Following" : "Follow"}
                  width="100%"
                  height="36px"
                  disabled={
                    artistName == "You" ||
                    artistName === user ||
                    disable
                  }
                  className={
                    !isFollowed
                      ? "btn-prime btn-primary"
                      : "btn-prime btn-secondary bg-transparent"
                  }
                  onClick={followBtn}
                />
              ) : (
                <Button
                  text={isFollowed ? "Following" : "Follow"}
                  width="100%"
                  height="36px"
                  disabled={
                    artistName == "You" ||
                    artistName === user ||
                    disable
                  }
                  className={
                    !isFollowed
                      ? "btn-prime btn-primary"
                      : "btn-prime btn-secondary bg-transparent"
                  }
                  onClick={followBtn}
                />
              )}
            </>
          )
        }
      </div>
    </>

  );
};
export default ArtistPopUp;
