import React, { useEffect, useState } from "react";
import userProfile from "../../assets/images/face-3.png";
import verified from "../../assets/icon/verified-artist-small.svg";
import ArtistPopUp from "./artistpopup";
import { useAccount } from "wagmi";
import thumb from "../../assets/icon/profile-picture.svg";
import { getUserData } from "../../firebase/firebase";
import { Popover } from "antd";
import { useNavigate } from "react-router-dom";

const UserProfile = (props) => {
  const { address } = useAccount();
  const [userData, setUserData] = useState(null);
  const [img, setImg] = useState(thumb);
  const [artistName, setArtistName] = useState(null);
  const [userName, setUserName] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    setUserName(props?.username);
  }, [props?.username]);
  useEffect(() => {
    if (props?.image) {
      setImg(props?.image);
    } else {
      console.log("no image sent");
    }
  }, [props]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let fetchedUserData = await getUserData(props?.address);
        setUserData(fetchedUserData);
        setArtistName(fetchedUserData.userName);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [props.address]);

  const displayUserName = () => {
    if (!artistName) {
      return " ";
    }

    console.log(artistName, "artist name");
    console.log(userName, "username");
    if (address) {
      if (artistName === userName) {
        return "You";
      } else {
        return artistName;
      }
    } else {
      return artistName;
    }
  };

  const navigateToProfile = () => {
    if (artistName === userName) {
      navigate(`/profile/${address}`);
    } else {
      navigate(`/profile/${props?.address}`);
    }
  };
  return (
    <div className="v-center userProfile">
      <div className="user-img">
        <img src={img} className="img-100 rounded-circle" alt="User Profile " />
      </div>

      <div className="user-name d-flex flex-column">
        <label className="medium fw-normal text-capitalize">
          {props.status}
        </label>

        <Popover
          placement="bottom"
          trigger="hover"
          content={
            address && (
              <ArtistPopUp
                userProfile={userData?.image || thumb}
                verified={verified}
                artistName={artistName}
              />
            )
          }
        >
          <p
            onClick={navigateToProfile}
            className="body-small fw-bold text-black no-text-transform pointer hover-underline"
          >
            {displayUserName().length > 14
              ? displayUserName().substring(0, 14) + "..."
              : displayUserName()}
          </p>
        </Popover>
      </div>
    </div>
  );
};

export default UserProfile;
