import sad from "../../assets/icon/sad-face.svg";
import Button from "../button";

import React, { useEffect, useState, useContext } from "react";
import "../../style/main.scss";
import { useNavigate } from "react-router-dom";
import {
  getCollectionDetailsFirebase,
  getCollections,
  getArtLastPriceFromFirebase,
  getAllUsers,
} from "../../firebase/firebase";
import marketplaceContractABI from "../../abis/Marketplace/v3/abi.json";
import mintABI from "../../abis/SafeMint/v2/abi.json";
import Filter from "../../assets/icon/filter.svg";
import grid from "../../assets/icon/display-grid-active.svg";
import row from "../../assets/icon/display-row-active.svg";
import artwork from "../../assets/icon/display-artwork-active.svg";
import sortby from "../../assets/icon/sort-by.svg";
import close from "../../assets/icon/close.svg";
import tick from "../../assets/icon/tick-large-black.svg";
import { NFTContext } from "../context/appContext";

import UserCollectedArtwork from "./userCollectedArtwork";
import UserCollectedRow from "./userCollectedRow";
import AsideFilterExplore from "../explore/asideFilterExplore";
import { Offcanvas } from "react-bootstrap";
import Web3 from "web3";
import { useAccount, useConnect } from "wagmi";
import { ClearAllContext } from "../explore/ClearAllContext";

import UserCollectionGrid from "./userCollectionGrid";
import config from "../../config";
import Backend_url from "../../herokuUrlConfig";
import { fetchNFTMetadata } from "../../Services/nft";
const CreatedArtwork = (props) => {
  const { clearAll, setClearAll } = useContext(ClearAllContext);
  const [hide, setHide] = useState(false);
  const { isChange, artModal, udpateArtModal } = useContext(NFTContext);
  const [filter, setFilter] = useState(true);
  const { address: user } = useAccount();
  const { createdArtworks, updateCreated, sgbUsd, flrUsd } = useContext(NFTContext);

  const clearFilter = () => {
    setClearAll(true);
  };
  const [activeLayout, setactiveLayout] = useState("grid");
  const [mobileLayout, setMobileLayout] = useState(false);

  // multi filters

  const [showSideFilter, setShowSideFilter] = useState(false);
  const [Flag, setFlag] = useState(false);
  const [nftFilteredDetails, setNftFilteredDetails] = useState([]);
  const [itemsNumber, setItemsNumber] = useState(0);
  const [loading, setLoading] = useState(true);

  //  handle mobile filters
  const handleClose = () => setMobileLayout(false);
  const handleShow = () => setMobileLayout(true);

  // layout tabs handles

  const handleActiveLayout = (image) => {
    setactiveLayout(image);
  };

  // let layout handle the icon of currently selected layout
  let layout;
  if (activeLayout == "grid") {
    layout = grid;
  } else if (activeLayout == "row") {
    layout = row;
  } else if (activeLayout == "artwork") {
    layout = artwork;
  }

  const [activeCollection, setActiveCollection] = useState("Newest");

  const handleIPriceTick = (tick) => {
    setActiveCollection(tick);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth > 992) {
        setShowSideFilter(false);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  // to show and hide collection multi filter
  const showMobileSideFilter = () => {
    setHide(!hide);
    if (windowWidth < 1000) {
      setShowSideFilter(true);
    } else {
      setFlag(!Flag);
    }
  };

  const closeMobileSideFilter = () => setShowSideFilter(false);

  // mobile sort filter
  const [collectionMobileFilter, setCollectionMobileFilter] = useState(false);
  const [activeCollectionFilter, setActiveCollectionFilter] =
    useState("Newest");
  const hideMobileSortFilter = () => setCollectionMobileFilter(false);

  const showMobileSortFilter = () => {
    setCollectionMobileFilter(true);
  };
  const handleCollectionTick = (tick) => {
    setActiveCollectionFilter(tick);
    setCollectionMobileFilter(false);
  };

  // const { address: accountAddress } = useAccount();
  const [accountAddress, setAccountAddress] = useState();
  useEffect(() => {

    setAccountAddress(props?.address);
  }, [props]);
  const [address, setAddress] = useState(props?.address);
  const [nfts, setNfts] = useState([]);
  const [collectionDetails, setCollectionDetails] = useState([]);
  const [collections, setCollections] = useState([]);
  const [web3, setWeb3] = useState();
  const [isOwner, setIsOwner] = useState(false);
  const [nftMetadata, setNftMetadata] = useState([]);
  const [owner, setOwner] = useState(false);

  const updateAddressFromProps = () => {
    if (props?.id) {
      // alert("id addded");

      setAddress(props?.id);
      setOwner(false);
    } else {
      setAddress(accountAddress);
    }
  };

  useEffect(() => {
    updateAddressFromProps();
  }, [props]);

  //  marketplace contract address
  const { marketplace, erc20 } = config;


  const Marketplace_coston_contractAddress = marketplace.coston;
  const Marketplace_coston2_contractAddress = marketplace.coston2;


  const [listedNfts, setListedNfts] = useState([]);

  // check nft owner by address

  const checkOwner = async () => {
    const owner = collectionDetails?.address;
    if (address === owner) {
      setIsOwner(true);
    } else {
    }
  };

  useEffect(() => {
    if (collectionDetails) {
      checkOwner();
    }
  }, [collectionDetails]);

  const navigate = useNavigate();
  // getting collections from firebase

  let wallet = localStorage?.getItem("wagmi.store");
  wallet = JSON.parse(wallet);

  const getUserCollections = async () => {
    try {
      const usercollections = await getCollections();
      // Filter user collections where userAddress doesn't match with the provided address
      const filteredCollections = usercollections.filter(
        (collection) => collection.address !== address
      );
      if (!filteredCollections) {
        setLoading(false);
      }

      setCollections(filteredCollections);
    } catch (error) {
      console.error("Error fetching user collections:", error);
    }
  };

  useEffect(() => {
    getUserCollections();
  }, [address]);

  const getAllListNFTData = async (selectedNetwork, web3) => {
    // const accounts = await web3.eth.getAccounts();
    if (collectionDetails && web3 != null) {
      let MarketplaceAddress;
      if (selectedNetwork === config.networks.songbird) {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (selectedNetwork === config.networks.flare) {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }

      // alert("in listed");
      const contract = new web3.eth.Contract(
        marketplaceContractABI,
        MarketplaceAddress
      );
      const data = await contract.methods.getAllListedNfts().call();
      if (data.length > 0) {
        setFilter(true);
      }
      if (data?.length === 0) {
        setFilter(false);
        setLoading(false);
      }
      setListedNfts(data);
      return data;
    } else {
      // setFilter(true);
      setListedNfts([]);
    }
  };



  //get nfts of user based on address and map and set listed data to nfts

  const fetchDataForContract = async (
    collectionId,
    contractAddress,
    selectedNetwork
  ) => {
    let web;
    if (selectedNetwork == config.networks.flare) {
      web = new Web3(config.rpc.flr);
    } else {
      web = new Web3(config.rpc.songbird);
    }
    let nftListDetails = [];

    if (web) {
      const contract = new web.eth.Contract(mintABI, contractAddress);
      const nftData = await contract.methods
        .getTokenIdsByCollection(collectionId)
        .call();

      let listedNfts = await getAllListNFTData(selectedNetwork, web);
      nftData?.map((item) => {
        let isSaleListed = false;
        let isOfferListed = false;

        // Check for sale listings
        listedNfts[0]?.forEach((nft) => {
          if (nft?.uriData === item?.uri) {
            isSaleListed = true;
            nftListDetails.push({
              ...nft,
              ...item,
              isListed: true,
              isSaleListed: true,
            });
          }
        });

        // Check for offer listings
        listedNfts[1]?.forEach((nft) => {
          if (nft?.uriData === item?.uri) {
            isOfferListed = true;
            const existingIndex = nftListDetails.findIndex(
              (nftDetail) => nftDetail.uri === item.uri
            );

            if (existingIndex !== -1) {
              nftListDetails[existingIndex] = {
                ...nftListDetails[existingIndex],
                isOfferListed: true,
              };
            } else {
              nftListDetails.push({
                ...nft,
                ...item,
                isListed: true,
                isOfferListed: true,
              });
            }
          }
        });

        // Ensure both flags are set correctly for NFTs listed in both categories
        if (isSaleListed || isOfferListed) {
          const existingIndex = nftListDetails.findIndex(
            (nftDetail) => nftDetail.uri === item.uri
          );
          if (existingIndex !== -1) {
            nftListDetails[existingIndex] = {
              ...nftListDetails[existingIndex],
              isListed: true,
              isSaleListed: isSaleListed,
              isOfferListed: isOfferListed,
            };
          }
        }
      });
    }
    return nftListDetails;
  };

  // get nft meta data
  const getNftMetadata = async (tokens_uri, network) => {
    // Reset or initialize state variables
    setNfts([]);
    const nftMetadata = [];

    await Promise.all(
      tokens_uri.map(async (token) => {
        const uri = token.uri; // Extract URI from the current token

        const json = await fetchNFTMetadata(uri);


        // Check if the data object is already in nftMetadata array
        const existingIndex = nftMetadata.findIndex((item) => item.uri === uri);

        if (existingIndex === -1 && json.data) {
          // Push the data object into the nftMetadata array along with the original token
          nftMetadata.push({
            data: token,
            metadata: {
              uri: uri,
              data: json.data,
            },
          });
        }
      })
    );


    const filteredMetadata = nftMetadata.filter((item) => {
      const artistAddress = item.metadata.data.artistAddress.toLowerCase();

      return artistAddress === address.toLowerCase();
    });

    filteredMetadata.map(async (e) => {

      let lastPrice = await getArtLastPriceFromFirebase(e?.data.uriData);

      if (lastPrice !== null) {
        e.metadata.data.lastPrice = lastPrice;
      } else {
        e.metadata.data.lastPrice = 0;
      }
    });

    if (filteredMetadata.length === 0) {
      setFilter(false);
    }

    // Update the state with the metadata and original tokens
    setItemsNumber(filteredMetadata?.length);
    updateCreated(filteredMetadata?.length);
    setNftFilteredDetails(filteredMetadata);
    setNfts(filteredMetadata);
    // setLoading(false);
  };

  // fet data for nfts collected by user (connected address)

  const fetchDataForAllContracts = async () => {
    try {
      let contractDataArray = [];

      for (const item of collections) {
        let data = await fetchDataForContract(
          item.documentId,
          item?.data.contractAddress,
          item?.data.selectedNetwork
        );
        if (data?.length > 0) {
          contractDataArray.push(data);
        }
      }

      // Flatten the contractDataArray into a single array
      const flattenedTokens = contractDataArray.flat();
      setLoading(false);

      // Pass the flattened array to getNftMetadata
      getNftMetadata(flattenedTokens);
      if (collections.length === 0) {
        setLoading(false);
      }
    }
    catch (error) {

      console.log(error);
      setLoading(false);
    }

  };



  useEffect(() => {
    if (collections?.length > 0) {
      fetchDataForAllContracts();
    } else {
      setLoading(false);
    }
  }, [collections]);

  const getCollectionDetails = async () => {
    try {
      const result = await getCollectionDetailsFirebase();
      setCollectionDetails(result);
    } catch (error) {
      console.log("Error fetching collection details:", error);
    }
  };

  useEffect(() => {
    // check wallet is connect or not
    if (window.ethereum) {
      window.ethereum.enable();
    } else {
      alert("Please install MetaMask to use this dApp!");
    }

    getCollectionDetails();
  }, []);

  // Filters
  // Filters
  const collectionSortFilter = [
    { value: "Lowest price", label: "Lowest price" },
    { value: "Highest price", label: "Highest price" },
    { value: "Newest", label: "Newest" },
    { value: "Oldest", label: "Oldest" },
  ];

  // on filter change
  const handleSelectedFilterChange = (newFilter) => {
    setFilter(true);
    setLoading(true);
    let filteredData = [];

    // Conversion rates for blockchain currencies
    const conversionRates = {
      coston: sgbUsd, // Conversion rate for Coston
      coston2: flrUsd, // Conversion rate for Coston2
      "flare mainnet": flrUsd, // Conversion rate for Flare Mainnet
      "songbird mainnet": sgbUsd, // Conversion rate for Songbird Mainnet
    };

    const applyPriceFilter = (item) => {
      let priceFilter = true;

      if (newFilter.selectPrice.min !== "" || newFilter.selectPrice.max !== "") {
        let priceInEth = parseFloat(item.data.listedData.price) / 1e18;

        // Adjust price based on selected `priceCurrency`
        if (newFilter.priceCurrency === "USD") {
          const blockchain = item.metadata.data.selectedBlockchain?.toLowerCase();
          const conversionRate = conversionRates[blockchain];
          if (conversionRate) {
            priceInEth *= conversionRate;
          }
        }

        // Existing currency checks
        if (
          (newFilter.priceCurrency === "CFLR" &&
            item.metadata.data.selectedBlockchain?.toLowerCase() !== "coston") ||
          (newFilter.priceCurrency === "C2FLR" &&
            item.metadata.data.selectedBlockchain?.toLowerCase() !== "coston2") ||
          (newFilter.priceCurrency === "SGB" &&
            item.metadata.data.selectedBlockchain?.toLowerCase() !== "songbird mainnet") ||
          (newFilter.priceCurrency === "FLR" &&
            item.metadata.data.selectedBlockchain?.toLowerCase() !== "flare mainnet")
        ) {
          priceFilter = false;
        }

        // Price range filter
        priceFilter =
          priceFilter &&
          (newFilter.selectPrice.min === "" || priceInEth >= parseFloat(newFilter.selectPrice.min)) &&
          (newFilter.selectPrice.max === "" || priceInEth <= parseFloat(newFilter.selectPrice.max));
      }

      return priceFilter;
    };

    const applyCategoryAndCurrencyFilter = (item, newFilter) => {
      // Category Filter
      const isCategorySelected = Object.values(newFilter.selectCategories).some((category) => category);
      let categoriesFilter = true;

      if (isCategorySelected) {
        categoriesFilter = Object.keys(newFilter.selectCategories).every((category) =>
          newFilter.selectCategories[category]
            ? item.metadata.data.selectedTags.includes(category)
            : true
        );
      }

      // Currency Filter
      const selectedCurrency = Object.values(newFilter.selectCurrency).some((currency) => currency);
      let currencyFilter = true;

      if (selectedCurrency) {
        const blockchain = item.metadata.data.selectedBlockchain?.toLowerCase();
        currencyFilter =
          newFilter.selectCurrency.allChains ||
          (newFilter.selectCurrency.flr && blockchain === "flare mainnet") ||
          (newFilter.selectCurrency.sgb && blockchain === "songbird mainnet")
      }

      return categoriesFilter && currencyFilter;
    };

    const applyEarlyAccessFilter = (item) => {
      // Convert Unix timestamp to milliseconds
      const mintedAt = new Date(item.data.listedData?.listTime * 1000); // Multiply by 1000 for milliseconds
      const now = new Date();
      const timeElapsed = now - mintedAt; // in milliseconds
      const hoursElapsed = Math.floor(timeElapsed / (1000 * 60 * 60));

      return hoursElapsed < 24 && item.data.isSaleListed && item.data.listedData.artist === item.data.newOwner; // Change the condition as needed
    };

    // Combined filtering logic
    nfts.forEach((item) => {
      const priceFilter = applyPriceFilter(item);
      const categoryCurrencyFilter = applyCategoryAndCurrencyFilter(item, newFilter);

      let statusFilter = true;
      if (newFilter.selectStatus.buyNow && !item.data.isSaleListed) {
        statusFilter = false;
      }
      if (newFilter.selectStatus.onOffer && !item.data.isOfferListed) {
        statusFilter = false;
      }
      if (newFilter.selectStatus.earlyAccess) {
        statusFilter = applyEarlyAccessFilter(item);
      }

      if (categoryCurrencyFilter && priceFilter && statusFilter) {
        filteredData.push(item);
      }
    });

    // Update state
    setLoading(false);
    setItemsNumber(filteredData.length);
    setNftFilteredDetails(filteredData);
  };



  // Filters  (higest price, lowest price, recently listed)
  useEffect(() => {

    if (nfts) {

      setLoading(true);
      if (activeCollection === "Highest price") {
        const nftsWithPrice = [...nfts].filter(
          (a) => a?.data?.listedData?.price !== undefined
        );
        const nftsWithoutPrice = [...nfts].filter(
          (a) => a?.data?.listedData?.price === undefined
        );

        const sortByHighestPrice = nftsWithPrice.sort((a, b) => {
          const aPrice = a?.data?.listedData?.price;
          const bPrice = b?.data?.listedData?.price;

          return bPrice - aPrice;
        });

        // Concatenate sorted NFTs with price and NFTs without price
        const sortedNftList = [...sortByHighestPrice, ...nftsWithoutPrice];

        setNftFilteredDetails(sortedNftList);
      }


      // Sorting by lowest price
      else if (activeCollection === "Lowest price") {
        const nftsWithPrice = [...nfts].filter(
          (a) => a?.data?.listedData?.price !== undefined
        );
        const nftsWithoutPrice = [...nfts].filter(
          (a) => a?.data?.listedData?.price === undefined
        );

        const sortByLowestPrice = nftsWithPrice.sort((a, b) => {
          const aPrice = a?.data?.listedData?.price;
          const bPrice = b?.data?.listedData?.price;

          return aPrice - bPrice;
        });

        // Concatenate sorted NFTs with price and NFTs without price
        const sortedNftList = [...sortByLowestPrice, ...nftsWithoutPrice];

        setNftFilteredDetails(sortedNftList);
      }
      // Getting the first 5 recently listed items
      else if (activeCollection === "Newest") {
        const recentlyAdded = [...nfts].sort((a, b) => {
          return b?.data
            ? b?.data?.mintTime -
            (a?.data && a?.data.mintTime ? a?.data.mintTime : 0)
            : 0;
        });
        // .slice(0, 5);
        setNftFilteredDetails(recentlyAdded);
      } else if (activeCollection === "Oldest") {
        const oldestAdded = [...nfts].sort((a, b) => {
          return a?.data
            ? a?.data?.mintTime -
            (b?.data && b?.data.mintTime ? b?.data.mintTime : 0)
            : 0;
        });
        // .slice(0, 5);
        setNftFilteredDetails(oldestAdded);
      }
    }
    setLoading(false);
  }, [nfts, activeCollection]);

  const createArtwork = () => {
    navigate("/create-art/");
  };

  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      const users = await getAllUsers();

      setUserData(users);
    };
    fetchUserData();
  }, []);
  const getNamebyAddress = () => {


    if (props?.address && !props?.id) {
      return "You haven't ";
    } else {
      const user = userData?.find((user) => user?.id === props?.id);
      const artistName = user ? user?.userName : null;


      if (!artistName) {
        return "@" + props?.id + " hasn't ";
      } else {
        // Truncate artist name to 18 characters and add ellipses if longer
        const truncatedName = artistName.length > 18
          ? artistName.substring(0, 18) + "..."
          : artistName;

        return "@" + truncatedName + " hasn't ";
      }
    }
  };

  return (
    <>
      {!loading && (nftFilteredDetails?.length == 0 || !nftFilteredDetails) && !filter ? (
        <div className="no-content mt-5 pt-5">
          <p className="body-large">
            {getNamebyAddress()}{" "}
            <span>created </span>
            any artwork yet.
          </p>
          {!props?.id && <Button
            text="Create artwork"
            width="166px"
            height="36px"
            className="btn-prime btn-primary"
            onClick={() => {
              navigate("/create");
            }}
          />}
        </div>
      ) : (
        <div>
          <div className="collected-arts ">
            <div className="collection-filter">
              <div className="left">
                <div
                  className="show-filter cursor-pointer "
                  onClick={showMobileSideFilter}
                >
                  <span>
                    <img src={Filter} alt="filter" id="filter" />
                  </span>
                  <label
                    htmlFor="filter"
                    className="medium text-black cursor-pointer ms-1 hide-on-mobile"
                  >
                    {hide ? "Hide Filters" : "Show Filters"}
                  </label>
                </div>
                <div className="t-items">
                  {hide && isChange ? (
                    <label
                      onClick={clearFilter}
                      className="medium ms-4 pointer"
                    >
                      Clear All
                    </label>
                  ) : (
                    <label
                      className={` medium ms-4 ${activeLayout === "row" && "d-none"
                        }`}
                    >
                      {nftFilteredDetails?.length} Items
                    </label>
                  )}
                </div>
              </div>

              <div className="right">
                {/* Sorting filter dropdown desktop*/}
                <div className="collection-grid hide-on-mobile">
                  <img
                    src={grid}
                    alt="grid"
                    className={
                      activeLayout === "grid" ? "active opacity-100 " : ""
                    }
                    onClick={() => handleActiveLayout("grid")}
                  />
                  <div className="divider"></div>
                  <img
                    src={row}
                    alt="grid"
                    className={
                      activeLayout === "row" ? "active opacity-100" : ""
                    }
                    onClick={() => handleActiveLayout("row")}
                  />
                  <div className="divider"></div>
                  <img
                    src={artwork}
                    alt="grid"
                    className={
                      activeLayout === "artwork" ? "active opacity-100" : ""
                    }
                    onClick={() => handleActiveLayout("artwork")}
                  />
                </div>

                {/* layout tabs for  mobile menu */}
                <div className="collection-grid-sm">
                  <span
                    className="hide-on-desktop d-flex align-items-center me-1"
                    onClick={handleShow}
                  >
                    <img
                      src={layout}
                      alt="layout"
                      className={activeLayout === "grid" ? "active " : ""}
                    />
                  </span>
                </div>
                <div className="recent-collection filter dropdown hide-on-mobile ">
                  <p
                    className="body-medium dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="hide-on-mobile">
                      {
                        collectionSortFilter.find(
                          (option) => option.value === activeCollection
                        )?.label
                      }
                    </span>
                    <span>
                      <img src={sortby} alt="sortby" />
                    </span>
                  </p>
                  <ul className="dropdown-menu">
                    {collectionSortFilter.map((option) => {
                      return (
                        <li
                          className="dropdown-item"
                          key={option.value}
                          onClick={() => handleIPriceTick(option.value)}
                        >
                          <img
                            src={tick}
                            alt="tick"
                            className={`${activeCollection === option.value
                              ? "active opacity-100"
                              : "opacity-0"
                              }`}
                          />
                          {option.label}
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="hide-on-desktop ">
                  <span onClick={() => setCollectionMobileFilter(true)}>
                    <img src={sortby} alt="sortby" />
                  </span>
                </div>
              </div>
            </div>

            {activeLayout == "grid" ? (
              <UserCollectionGrid
                id={props?.id}
                flag={Flag}
                listedNft={nftFilteredDetails}
                onSelectedFilterChange={handleSelectedFilterChange}
                owner={owner}
                address={address}
                loading={loading}
                setLoading={setLoading}
              />
            ) : activeLayout == "row" ? (
              <UserCollectedRow
                id={props?.id}
                flag={Flag}
                listedNft={nftFilteredDetails}
                onSelectedFilterChange={handleSelectedFilterChange}
                owner={owner}
                address={address}
                loading={loading}
              />
            ) : activeLayout == "artwork" ? (
              <UserCollectedArtwork
                id={props?.id}
                flag={Flag}
                listedNft={nftFilteredDetails}
                onSelectedFilterChange={handleSelectedFilterChange}
                owner={owner}
                address={address}
                loading={loading}
              />
            ) : (
              <></>
            )}
          </div>
          <Offcanvas
            show={showSideFilter}
            onHide={closeMobileSideFilter}
            placement="bottom"
            className="sub-menu-offcanvas collection-multi-filter aside-filter-offcanvas"
          >
            <div className="more-menu-sm price-more-menu ">
              <div className="menu-head">
                <label htmlFor="">
                  {nftFilteredDetails?.length
                    ? nftFilteredDetails?.length
                    : "0"}{" "}
                  Items
                </label>
                <label className="text-black multi-filter-head">Filters</label>
                <div className="close-btn cursor-pointer">
                  <img
                    src={close}
                    alt="close"
                    className="img-24"
                    onClick={closeMobileSideFilter}
                  />
                </div>
              </div>

              {/* <AsideFilterExplore /> */}
              <AsideFilterExplore
                onSelectedFilterChange={handleSelectedFilterChange}
              />
            </div>
          </Offcanvas>

          <Offcanvas
            show={collectionMobileFilter}
            onHide={hideMobileSortFilter}
            placement="bottom"
            className="sub-menu-offcanvas"
          >
            <div className="more-menu-sm price-more-menu">
              <div className="menu-head">
                <label className="text-black">sort by</label>
                <div className="close-btn cursor-pointer">
                  <img
                    src={close}
                    alt="close"
                    className="img-24"
                    onClick={hideMobileSortFilter}
                  />
                </div>
              </div>

              <div className="share">
                {collectionSortFilter.map((option) => (
                  <label
                    key={option.value}
                    className={`no-text-transform h-64 text-black ${activeCollectionFilter === option.value
                      ? "fw-bold"
                      : "fw-normal"
                      }`}
                    onClick={() => handleCollectionTick(option.value)}
                  >
                    {option.label}
                    <img
                      src={tick}
                      alt="tick"
                      className={`${activeCollectionFilter === option.value
                        ? "active opacity-100"
                        : "opacity-0"
                        }`}
                    />
                  </label>
                ))}
              </div>
            </div>
          </Offcanvas>

          <Offcanvas
            show={mobileLayout}
            onHide={handleClose}
            placement="bottom"
            className="sub-menu-offcanvas"
          >
            <div className="more-menu-sm price-more-menu ">
              <div className="menu-head">
                <label className="text-black">layout</label>
                <div className="close-btn cursor-pointer">
                  <img
                    src={close}
                    alt="close"
                    className="img-24"
                    onClick={handleClose}
                  />
                </div>
              </div>

              <div className="share">
                <label
                  className=" h-64 text-black"
                  onClick={() => handleActiveLayout("grid")}
                >
                  <div>
                    <img src={grid} alt="grid" className="me-3 " />
                    Grid Layout
                  </div>

                  <img
                    src={tick}
                    alt="tick"
                    className={
                      activeLayout === "grid"
                        ? "active opacity-100"
                        : "opacity-0"
                    }
                  />
                </label>
                <label
                  className=" h-64 text-black"
                  onClick={() => handleActiveLayout("row")}
                >
                  <div className="cursor-pointer">
                    <img src={row} alt="row" className="me-3 " />
                    Row Layout
                  </div>
                  <img
                    src={tick}
                    alt="tick"
                    className={
                      activeLayout === "row"
                        ? "active opacity-100"
                        : "opacity-0"
                    }
                  />
                </label>
                <label
                  className=" h-64 text-black"
                  onClick={() => handleActiveLayout("artwork")}
                >
                  <div className="cursor-pointer">
                    <img src={artwork} alt="artwork" className="me-3 " />
                    Artwork Layout
                  </div>

                  <img
                    src={tick}
                    alt="tick"
                    className={
                      activeLayout === "artwork"
                        ? "active opacity-100"
                        : "opacity-0"
                    }
                  />
                </label>
              </div>
            </div>
          </Offcanvas>
        </div>
      )}
    </>
  );
};

export default CreatedArtwork;
