import React, { useState, useEffect, useContext } from "react";
import Button from "../button";
import Input from "../inputs";
import ImageUploadCollection from "../imageUploadCollection";
import ImageUpload from "../imageUpload";
import unchecked from "../../assets/icon/checkbox.svg";
import checked from "../../assets/icon/checkbox-selected.svg";
import leftArrow from "../../assets/icon/chevron-left-small.svg";
import { useNavigate } from "react-router-dom";
import UseWindowResize from "../../customHooks/useWindowResize";
import Textarea from "../shared/textarea";
import useScrollToTop from "../../customHooks/scrollToTop";
import InputBox from "../shared/inputBox";
import { RefreshContext } from "../notificationContext";
import {
  SaveSpotlight,
  getSpotlightUser,
  getAllUsers,
} from "../../firebase/firebase";
import { set } from "firebase/database";
import { toast } from "react-toastify";

const SpotLight = () => {
  const [loading, setLoading] = useState(false);
  const [spotActive, setSpotActive] = useState(false);
  const [spotAddress, setSpotAddress] = useState("");
  const [spotUsername, setSpotUsername] = useState("");
  const [spotInstagram, setSpotInstagram] = useState(""); // New state for Instagram
  const [spotDiscord, setSpotDiscord] = useState(""); // New state for Discord
  const [spotTwitter, setSpotTwitter] = useState("");
  const [spotWebsite, setSpotWebsite] = useState("");
  const [spotFeaturedImage1, setSpotFeaturedImage1] = useState("");
  const [spotFeaturedName1, setSpotFeaturedName1] = useState("");
  const [spotFeaturedLink1, setSpotFeaturedLink1] = useState("");
  const [spotFeaturedLink2, setSpotFeaturedLink2] = useState("");
  const [spotFeaturedLink3, setSpotFeaturedLink3] = useState("");

  const [spotFeaturedImage2, setSpotFeaturedImage2] = useState("");
  const [spotFeaturedName2, setSpotFeaturedName2] = useState("");
  const [spotFeaturedImage3, setSpotFeaturedImage3] = useState("");
  const [spotFeaturedName3, setSpotFeaturedName3] = useState("");
  const [spotBio, setSpotBio] = useState("");
  const [spotHeader1, setSpotHeader1] = useState("");
  const [spotSection1image, setSpotSection1image] = useState("");
  const [spotSection1, setSpotSection1] = useState("");
  const [spotHeader2, setSpotHeader2] = useState("");
  const [spotSection2, setSpotSection2] = useState("");
  const [spotSection2image, setSpotSection2image] = useState("");
  const [spotHeader3, setSpotHeader3] = useState("");
  const [spotSection3, setSpotSection3] = useState("");
  const [spotSection3image1, setSpotSection3image1] = useState("");
  const [spotSection3image2, setSpotSection3image2] = useState("");
  const [userData, setUserData] = useState({});
  const [spotlight, setSpotlight] = useState({});
  const [changesMade, setChangesMade] = useState(false); // Initially set to false
  const [initialDataFetched, setInitialDataFetched] = useState(false); // New state to track data fetch
  const { isAdmin, setIsAdmin } = useContext(RefreshContext);
  const [mainTitle, setMainTitle] = useState("");
  const [initialData, setInitialData] = useState({
    spotActive: false,
    spotAddress: "",
    spotUsername: "",
    spotInstagram: "",
    spotDiscord: "",
    spotTwitter: "",
    spotWebsite: "",
    mainTitle: "",
    spotFeaturedImage1: "",
    spotFeaturedName1: "",
    spotFeaturedImage2: "",
    spotFeaturedName2: "",
    spotFeaturedImage3: "",
    spotFeaturedName3: "",
    spotFeaturedLink1: "",

    spotFeaturedLink2: "",
    spotFeaturedLink3: "",
    spotBio: "",
    spotHeader1: "",
    spotSection1image: "",
    spotSection1: "",
    spotHeader2: "",
    spotSection2: "",
    spotSection2image: "",
    spotHeader3: "",
    spotSection3: "",
    spotSection3image1: "",
    spotSection3image2: "",
  });
  const [currentData, setCurrentData] = useState({
    spotActive: false,
    spotAddress: "",
    spotUsername: "",
    spotInstagram: "",
    spotDiscord: "",
    spotTwitter: "",
    spotWebsite: "",
    mainTitle: "",
    spotFeaturedImage1: "",
    spotFeaturedName1: "",
    spotFeaturedImage2: "",
    spotFeaturedName2: "",
    spotFeaturedImage3: "",
    spotFeaturedLink1: "",
    spotFeaturedLink2: "",
    spotFeaturedLink3: "",
    spotFeaturedName3: "",
    spotBio: "",
    spotHeader1: "",
    spotSection1image: "",
    spotSection1: "",
    spotHeader2: "",
    spotSection2: "",
    spotSection2image: "",
    spotHeader3: "",
    spotSection3: "",
    spotSection3image1: "",
    spotSection3image2: "",
  });
  useEffect(() => {
    if (!isAdmin) {
      navigate("/");
    }
  }, [isAdmin]);
  // match artistAddress with username and if match found then set SelectedUser


  const getUsername = () => {
    if (userData.length > 0) {
      const user = userData.find((user) => user.id === spotAddress);
      if (user) {
        setSpotUsername(user?.userName);
      } else {
        setSpotUsername(" ");
      }
    }
  };
  useEffect(() => {

    getUsername();
  }, [spotAddress, userData]);

  useEffect(() => {
    const fetchData = async () => {
      const users = await getAllUsers();
      setUserData(users);
    };
    fetchData();
  }, []);

  const handleSaveChanges = async () => {
    setLoading(true);
    if (!spotAddress && !spotActive) {
      toast.error("Please add an artist address to activate the spotlight.");
    }


    if ((spotAddress && !spotUsername) || spotUsername === " ") {
      toast.error(
        "Username for this address is missing. Please ensure that the artist has a username associated with their address."
      );
      setLoading(false);
      return;
    }

    try {
      // Validation functions
      const validateTwitter = (twitter) => {
        const twitterRegex =
          /^(?:https?:\/\/)?(?:www\.)?(?:twitter\.com|x\.com)\/([a-zA-Z0-9_]+)(?:\/)?$/;
        return twitterRegex.test(twitter);
      };

      const validateInstagram = (instagram) => {
        const instagramRegex =
          /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/([a-zA-Z0-9_]+)(?:\/)?$/;
        return instagramRegex.test(instagram);
      };

      const validateURL = (url) => {
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlRegex.test(url);
      };

      // Validation checks
      if (spotTwitter && !validateTwitter(spotTwitter)) {
        toast.error("Invalid Twitter URL");
        setLoading(false);
        return;
      }

      if (spotInstagram && !validateInstagram(spotInstagram)) {
        toast.error("Invalid Instagram URL");
        setLoading(false);
        return;
      }

      if (spotWebsite && !validateURL(spotWebsite)) {
        toast.error("Invalid URL");
        setLoading(false);
        return;
      }

      // Set deleted images to null if they are empty
      const data = {
        spotActive,
        spotAddress,
        spotUsername,
        spotInstagram,
        spotDiscord,
        spotTwitter,
        spotWebsite,
        mainTitle,
        spotFeaturedImage1: spotFeaturedImage1 || null, // Set to null if empty
        spotFeaturedName1,
        spotFeaturedImage2: spotFeaturedImage2 || null, // Set to null if empty
        spotFeaturedName2,
        spotFeaturedImage3: spotFeaturedImage3 || null, // Set to null if empty
        spotFeaturedName3,
        spotFeaturedLink1: spotFeaturedLink1 || null,
        spotFeaturedLink2: spotFeaturedLink2 || null,
        spotFeaturedLink3: spotFeaturedLink3 || null,
        spotBio,
        spotHeader1,
        spotSection1image: spotSection1image || null, // Set to null if empty
        spotSection1,
        spotHeader2,
        spotSection2,
        spotSection2image: spotSection2image || null, // Set to null if empty
        spotHeader3,
        spotSection3,
        spotSection3image1: spotSection3image1 || null, // Set to null if empty
        spotSection3image2: spotSection3image2 || null, // Set to null if empty
      };


      const spotLight = await SaveSpotlight(data);

      if (spotLight) {
        setLoading(false);
        // setInitialDataFetched(true);
        fetchData();
        setChangesMade(false);
        toast.success("Spotlight Updated successfully", {
          toastId: "saveSpotlight",
        });
      } else {
        throw new Error("Failed to update spotlight");
      }
    } catch (error) {
      console.error("Error updating spotlight:", error);

      // else {
      //   toast.error("An error occurred while saving the spotlight.");

      // }
      // toast.error("An error occurred while saving the spotlight.");
      setLoading(false);
      setInitialDataFetched(false);
      setChangesMade(false);
    }
  };



  const fetchData = async () => {
    const response = await getSpotlightUser();

    // if (!response[0]) {
    //   setInitialDataFetched(false);
    //   return;
    // }

    if (response) {
      setInitialDataFetched(true);
      const user = response[0];
      setSpotlight(user);
      if (user) {
        setSpotActive(user.spotActive);
        setSpotAddress(user.spotAddress);
        setSpotUsername(user.spotUsername);
        setSpotInstagram(user.spotInstagram);
        setSpotDiscord(user.spotDiscord);
        setSpotTwitter(user.spotTwitter);
        setSpotWebsite(user.spotWebsite);
        setMainTitle(user?.mainTitle);
        setSpotFeaturedImage1(user.spotFeaturedImage1);
        setSpotFeaturedName1(user.spotFeaturedName1);
        setSpotFeaturedLink1(user.spotFeaturedLink1);
        setSpotFeaturedLink2(user.spotFeaturedLink2);
        setSpotFeaturedLink3(user.spotFeaturedLink3);
        setSpotFeaturedImage2(user.spotFeaturedImage2);
        setSpotFeaturedName2(user.spotFeaturedName2);
        setSpotFeaturedImage3(user.spotFeaturedImage3);
        setSpotFeaturedName3(user.spotFeaturedName3);
        setSpotBio(user.spotBio);
        setSpotHeader1(user.spotHeader1);
        setSpotSection1image(user.spotSection1image);
        setSpotSection1(user.spotSection1);
        setSpotHeader2(user.spotHeader2);
        setSpotSection2(user.spotSection2);
        setSpotSection2image(user.spotSection2image);
        setSpotHeader3(user.spotHeader3);
        setSpotSection3(user.spotSection3);
        setSpotSection3image1(user.spotSection3image1);
        setSpotSection3image2(user.spotSection3image2);
        const newData = {
          spotActive: user.spotActive,
          spotAddress: user.spotAddress,
          spotUsername: user.spotUsername,
          spotInstagram: user.spotInstagram,
          spotDiscord: user.spotDiscord,
          spotTwitter: user.spotTwitter,
          spotWebsite: user.spotWebsite,
          mainTitle: user.mainTitle,
          spotFeaturedImage1: user.spotFeaturedImage1,
          spotFeaturedName1: user.spotFeaturedName1,
          spotFeaturedImage2: user.spotFeaturedImage2,
          spotFeaturedName2: user.spotFeaturedName2,
          spotFeaturedImage3: user.spotFeaturedImage3,
          spotFeaturedLink1: user?.spotFeaturedLink1,
          spotFeaturedLink2: user?.spotFeaturedLink2,
          spotFeaturedLink3: user?.spotFeaturedLink3,
          spotFeaturedName3: user.spotFeaturedName3,
          spotBio: user.spotBio,
          spotHeader1: user.spotHeader1,
          spotSection1image: user.spotSection1image,
          spotSection1: user.spotSection1,
          spotHeader2: user.spotHeader2,
          spotSection2: user.spotSection2,
          spotSection2image: user.spotSection2image,
          spotHeader3: user.spotHeader3,
          spotSection3: user.spotSection3,
          spotSection3image1: user.spotSection3image1,
          spotSection3image2: user.spotSection3image2,
        };

        setInitialData(newData);
      }
      setInitialDataFetched(true); // Set to true after data fetch
    }
  };
  useEffect(() => {
    fetchData();
  }, []);


  useEffect(() => {
    if (initialDataFetched) {
      const currentData = {
        spotActive,
        spotAddress,
        spotUsername,
        spotInstagram,
        spotDiscord,
        spotTwitter,
        spotWebsite,
        mainTitle,
        spotFeaturedImage1,
        spotFeaturedName1,
        spotFeaturedImage2,
        spotFeaturedName2,
        spotFeaturedImage3,
        spotFeaturedLink1,
        spotFeaturedLink2,
        spotFeaturedLink3,

        spotFeaturedName3,
        spotBio,
        spotHeader1,
        spotSection1image,
        spotSection1,
        spotHeader2,
        spotSection2,
        spotSection2image,
        spotHeader3,
        spotSection3,
        spotSection3image1,
        spotSection3image2,
      };
      setCurrentData(currentData);
    }
  }, [
    spotActive,
    spotAddress,
    spotUsername,
    spotInstagram,
    spotDiscord,
    spotTwitter,
    spotWebsite,
    mainTitle,
    spotFeaturedImage1,
    spotFeaturedName1,
    spotFeaturedImage2,
    spotFeaturedName2,
    spotFeaturedImage3,
    spotFeaturedLink1,
    spotFeaturedLink2,
    spotFeaturedLink3,
    spotFeaturedName3,
    spotBio,
    spotHeader1,
    spotSection1image,
    spotSection1,
    spotHeader2,
    spotSection2,
    spotSection2image,
    spotHeader3,
    spotSection3,
    spotSection3image1,
    spotSection3image2,
    spotlight,
    initialDataFetched, // added this dependency
  ]);
  useEffect(() => {


    if (initialDataFetched) {
      const differences = Object.keys(initialData).reduce((acc, key) => {
        if (initialData[key] !== currentData[key]) {
          acc.push({
            key,
            initialValue: initialData[key],
            currentValue: currentData[key],
          });
        }
        return acc;
      }, []);

      if (differences.length > 0) {
        setChangesMade(true); // Set to true if there are differences
      } else {
        setChangesMade(false); // Set to false if there are no differences
      }
    } else {
      setChangesMade(false);
    }
  }, [currentData, initialData, initialDataFetched]);



  const navigate = useNavigate();
  UseWindowResize(1024, "/catalyst-phoenix-nexus");
  useScrollToTop();
  return (
    <div>
      <div className="spotlight-wrapper">
        <div className="admin-content-head v-center justify-content-between header-fixed ">
          {/* Header for desktop */}
          <h3 className="fw-bold text-capitalize for-desktop">Spotlight</h3>
          {/* Header for mobile */}
          <h6
            className="fw-bold text-capitalize for-mobile"
            onClick={() => navigate(-1)}
          >
            <img src={leftArrow} alt="back" className="me-3" />
            Spotlight
          </h6>

          {/* button is disabled until some state has changed on the page */}
          {/* when some data is changed or entered in form fields then save changes button btn-ternary class will replace with btn-primary  */}
          <Button
            width="199px"
            height="47px"
            className={`br-30 ${changesMade ? "btn-primary" : "btn-ternary"}`}
            text="Save changes"
            onClick={handleSaveChanges}
            disabled={!changesMade}
            loading={loading}
          />
        </div>

        <div className="spotlight-wrapper-content">
          {/* toggle spotlight */}
          <div className="toggle-spotlight">
            <p className="body-large fw-bold mt-3 pt-2">Toggle Spotlight</p>
            <p className="mt-3 pt-1">
              If this is on, the Spotlight Artist will be shown on the front
              page.
            </p>

            {/* If this checkbox is set to active, the Spotlight Artist will be
            shown on the LANDING page and on the SPOTLIGHT page. the details on
            this page will ‘fill’ those pages where applicable. */}

            <div className="spotlight-active-box mt-4 mb-5 ms-3">
              <label
                className="text-black no-text-transform v-center pointer"
                onClick={(e) => {
                  setSpotActive(!spotActive);
                }}
              >
                <img
                  src={spotActive ? checked : unchecked}
                  alt="checkbox"
                  className="me-3"
                />
                Spotlight Active
              </label>
            </div>
          </div>

          {/* artist details */}
          <div className="artist-details">
            <p className="body-large fw-bold head">Artist Details</p>

            {/* Artist address */}
            <div className="spot-input-box">
              {/* everywhere there is an SGB / FLR address field it should follow
              the same error rules */}

              <InputBox
                label="ARTIST ADDRESS"
                subLabel=" "
                id="inputId"
                type="url"
                value={spotAddress}
                onChange={(e) => {
                  getUsername();
                  setSpotAddress(e.target.value);
                }}
                placeholder="Add FLR address"
                disabled={false}
                optional={false}
                maxLength={50}
              />
            </div>

            {/* artist username */}
            <div className="spot-input-box mt-36">
              {/* the disabled filed will fill automatically with the artists
                username once address is filled and admin has clicked ‘save
                changes” */}

              <InputBox
                label="ARTIST Name"
                subLabel=" "
                id="inputId"
                type="text"
                boxClassName="disabled"
                value={spotUsername}
                onChange={(e) => setSpotUsername(e.target.value)}
                placeholder="@artistName"
                disabled={true}
                optional={false}
                maxLength={30}
              />
              <p className="body-medium ">
                The artist username will fill automatically from the artists’
                address and cannot be changed manually here.
              </p>
            </div>

            <div className="spot-input-box mt-36">
              <InputBox
                label="Artist Discord"
                subLabel=" "
                id="inputId"
                type="url"
                value={spotDiscord}
                onChange={(e) => setSpotDiscord(e.target.value)}
                // placeholder="https://twitter.com/handle1234"
                disabled={false}
                optional={false}
                maxLength={100}
              />
            </div>

            {/* Artist twitter handle */}
            <div className="spot-input-box mt-36">
              <InputBox
                label="ARTIST twitter"
                subLabel=" "
                id="inputId"
                type="url"
                value={spotTwitter}
                onChange={(e) => setSpotTwitter(e.target.value)}
                placeholder="https://twitter.com/handle1234"
                disabled={false}
                optional={false}
                maxLength={100}
              />
            </div>

            <div className="spot-input-box mt-36">
              <InputBox
                label="ARTIST instagram"
                subLabel=" "
                id="inputId"
                type="url"
                value={spotInstagram}
                onChange={(e) => setSpotInstagram(e.target.value)}
                placeholder="https://instagram.com/handle1234"
                disabled={false}
                optional={false}
                maxLength={100}
              />
            </div>

            {/* Artist twitter handle */}
            <div className="spot-input-box mt-36">
              <InputBox
                label="ARTIST website"
                subLabel=" "
                id="inputId"
                type="url"
                value={spotWebsite}
                onChange={(e) => setSpotWebsite(e.target.value)}
                placeholder="https://website.com/"
                disabled={false}
                optional={false}
                maxLength={100}
              />
            </div>

            {/* FEATURED IMAGES */}
            <div className="featured-images mt-60 ">
              <label className="medium text-black">FEATURED IMAGES</label>
              <p className="mt-2 pb-1">
                These are displayed on the homepage, and transition between each
                other.
              </p>
              <ImageUploadCollection
                value={spotFeaturedImage1}
                onChange={(e) => setSpotFeaturedImage1(e.target.files[0])}
                setImage={setSpotFeaturedImage1}
              />
              <div className="spot-input-box">
                <InputBox
                  // label="Artwork#1 Name"
                  // subLabel=" "
                  id="inputId"
                  type="url"
                  value={spotFeaturedName1}
                  onChange={(e) => setSpotFeaturedName1(e.target.value)}
                  placeholder="Artwork#1 Name"
                  disabled={false}
                  optional={false}
                  maxLength={42}
                />
              </div>
              <div className="spot-input-box">
                <InputBox
                  // label="Artwork#1 Name"
                  // subLabel=" "
                  id="inputId"
                  type="url"
                  value={spotFeaturedLink1}
                  onChange={(e) => setSpotFeaturedLink1(e.target.value)}
                  placeholder="Artwork#1 Link"
                  disabled={false}
                  optional={false}
                // maxLength={42}
                />
              </div>
              <ImageUploadCollection
                value={spotFeaturedImage2}
                onChange={(e) => setSpotFeaturedImage2(e.target.files[0])}
                setImage={setSpotFeaturedImage2}
              />
              <div className="spot-input-box">
                <InputBox
                  // label="Artwork#2 Name"
                  // subLabel=" "
                  id="inputId"
                  type="url"
                  value={spotFeaturedName2}
                  onChange={(e) => setSpotFeaturedName2(e.target.value)}
                  placeholder="Artwork#2 Name"
                  disabled={false}
                  optional={false}
                  maxLength={42}
                />
              </div>
              <div className="spot-input-box">
                <InputBox
                  // label="Artwork#1 Name"
                  // subLabel=" "
                  id="inputId"
                  type="url"
                  value={spotFeaturedLink2}
                  onChange={(e) => setSpotFeaturedLink2(e.target.value)}
                  placeholder="Artwork#2 Link"
                  disabled={false}
                  optional={false}
                // maxLength={42}
                />
              </div>
              <ImageUploadCollection
                value={spotFeaturedImage3}
                onChange={(e) => setSpotFeaturedImage3(e.target.files[0])}
                setImage={setSpotFeaturedImage3}
              />
              <div className="spot-input-box">
                <InputBox
                  // label="Artwork#3 Name"
                  // subLabel="Artwork#3 Name"
                  id="inputId"
                  type="url"
                  value={spotFeaturedName3}
                  onChange={(e) => setSpotFeaturedName3(e.target.value)}
                  placeholder="Artwork#3 Name"
                  disabled={false}
                  optional={false}
                  maxLength={42}
                />
              </div>
              <div className="spot-input-box">
                <InputBox
                  // label="Artwork#1 Name"
                  // subLabel=" "
                  id="inputId"
                  type="url"
                  value={spotFeaturedLink3}
                  onChange={(e) => setSpotFeaturedLink3(e.target.value)}
                  placeholder="Artwork#3 Link"
                  disabled={false}
                  optional={false}
                // maxLength={42}
                />
              </div>
            </div>
            {/* Short Bio */}
            <div className="short-bio mt-60 d-flex flex-column">
              <InputBox
                label="Main Title"
                id="inputId"
                type="url"
                value={mainTitle}
                onChange={(e) => setMainTitle(e.target.value)}
                maxLength={200}
              />
              <label className="medium text-black">short bio</label>
              {/* <textarea
                className="mt-3 br-20 pt-2 ps-3" 
                placeholder="@artistName"
              ></textarea>
              <label className="medium text-end pt-2 pe-2 ">0 / 40</label> */}

              <Textarea
                maxLength={200}
                placeholder="@artistName"
                value={spotBio}
                onChange={(e) => setSpotBio(e.target.value)}
              />
            </div>

            {/*   header 1 */}
            <div className="spot-input-box mt-36">
              <InputBox
                label="header 1"
                subLabel=" "
                id="inputId"
                type="text"
                placeholder=" "
                value={spotHeader1}
                onChange={(e) => setSpotHeader1(e.target.value)}
                disabled={false}
                optional={false}
                maxLength={200}
              />
            </div>

            {/* Section 1 discription */}

            <div className="bio-section mt-60 d-flex flex-column">
              <label className="medium text-black">section 1</label>
              {/* <textarea
                className="mt-3 br-20 pt-2 ps-3"
                placeholder="Add bio section 1..."
              ></textarea> */}
              <Textarea
                maxLength={1000}
                placeholder="Add bio section 1..."
                value={spotSection1}
                onChange={(e) => setSpotSection1(e.target.value)}
              />

              {/* <ImageUploadCollection
                value={spotSection1image}
                onChange={(e) => setSpotSection1image(e.target.files[0])}
                setImage={setSpotSection1image}
              /> */}
            </div>

            {/*   header 2 */}

            <div className="spot-input-box mt-36">
              <InputBox
                label="header 2"
                subLabel=" "
                id="inputId"
                type="text"
                placeholder=" "
                value={spotHeader2}
                onChange={(e) => setSpotHeader2(e.target.value)}
                disabled={false}
                optional={false}
                maxLength={200}
              />
            </div>

            {/* Section 2 discription */}

            <div className="bio-section mt-60  d-flex flex-column">
              <label className="medium text-black">section 2</label>
              {/* <textarea
                className="mt-3 br-20 pt-2 ps-3"
                placeholder="Add bio section 2..."
              ></textarea>
              <label className="medium text-end pt-2 pe-2 ">0 / 200</label> */}
              <Textarea
                maxLength={1000}
                placeholder="Add bio section 2..."
                value={spotSection2}
                onChange={(e) => setSpotSection2(e.target.value)}
              />

              {/* <ImageUploadCollection
                value={spotSection2image}
                onChange={(e) => setSpotSection2image(e.target.files[0])}
                setImage={setSpotSection1image}
              /> */}
            </div>

            {/*   header 3 */}

            <div className="spot-input-box mt-36">
              <InputBox
                label="header 3"
                subLabel=" "
                id="inputId"
                type="text"
                placeholder=" "
                value={spotHeader3}
                onChange={(e) => setSpotHeader3(e.target.value)}
                disabled={false}
                optional={false}
                maxLength={200}
              />
            </div>

            {/* Section 3 discription */}

            <div className="bio-section mt-60  d-flex flex-column">
              <label className="medium text-black">section 3</label>
              {/* <textarea
                className="mt-3 br-20 pt-2 ps-3"
                placeholder="Add bio section 3..."
              />
              <label className="medium text-end pt-2 pe-2">0 / 200</label> */}

              <Textarea
                maxLength={1000}
                placeholder="Add bio section 3..."
                value={spotSection3}
                onChange={(e) => setSpotSection3(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpotLight;
