import "../src/style/main.scss";
import Home from "./pages/Home.js";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Profile from "./pages/profile";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SingleArt from "./components/explore/singleArt";
import Navbar from "./components/navbar";
import Explore from "./components/explore/explore";
import Drop from "./components/explore/drop";
import Stats from "./pages/stats";
import Notifications from "./components/notifications";
import AccountSettings from "./components/settings/accountSettings";
import NotificationSettings from "./components/settings/notificationSettings";
import ProfileSetting from "./components/settings/profileSetting.js";
import Collections from "./components/explore/exploreCollections";
import Admin from "./components/admin/admin";
import Error from "./components/error";
import ReportIssue from "./components/reportIssue";
import CreateArt from "./components/createArt/createArt";
import MintArt from "./components/createArt/mintArt";
import Whitelist from "./components/admin/whitelist";
import Dashboard from "./components/admin/dashboard";
import SpotLight from "./components/admin/spotLight";
import FeaturedArt from "./components/admin/featured";
import ArtistApllied from "./components/admin/artistapplied.js";
import SiteSetting from "./components/admin/siteSetting";
import Market from "./components/admin/market";
import Blacklist from "./components/admin/blacklist";
import NoNetwork from "./components/noNetwork";
import CreateArtTabs from "./components/createArt/createArtTabs";
import SelectArtTab from "./components/createArt/selectArtTab";
import ListforSale from "./components/listforSale";
import ListforOffer from "./components/listforOffer";
import ExploreCollections from "./components/explore/exploreCollections";
import SingleArt2 from "./components/explore/singleArt2";
import Verify from "./components/verify";
import Footer from "./components/footer";
import FooterV2 from "./components/footerV2";
import CreateCollection from "./components/createArt/createCollection";
import EditCollection from "./components/createArt/editCollection";
import Banner from "./components/banner";
import Spotlight from "./pages/spotlight";
import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { RefreshProvider } from "./components/notificationContext.js";
import { ClearAllProvider } from "./components/explore/ClearAllContext.js";
import { TagProvider } from "./components/explore/TagContext.js";
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
  zora,
  flare,
  songbird,
  flareTestnet,
  songbirdTestnet,
} from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import Applyform from "./components/applyform/Applyform";
import AllCollections from "./components/allcollections";
import { getTopBannerText, getUserData } from "./firebase/firebase";
import { useEffect, useState } from "react";
import CollectionApllied from "./components/admin/collectionApplied.js";
import NoCollectonArtwork from "./components/createArt/noCollectonArtwork.js";
import useLocalStorage from "use-local-storage";
import { Navigate } from "react-router-dom";
import { useAccount, useConnect, useDisconnect, useBalance } from "wagmi";
import Web3 from "web3";
import { getAccount } from "@wagmi/core";
import HelpCernter from "./pages/helpCernter.js";
import Privacy from "./pages/privacy.js";
import Terms from "./pages/terms.js";
function App() {
  const navigate = useNavigate();
  const [bannerEnable, setBannerEnable] = useState(false);
  const [bannerText, setBannerText] = useState("");
  const [bannerOpen, setBannerOpen] = useLocalStorage(true);
  const location = useLocation();
  const [loggedIn, setLoggedIn] = useState(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [userAddress, setUserAddress] = useState(null);
  const [isWhiteListed, setIsWhiteListed] = useState(true);

  const currentEnv = process.env.REACT_APP_VERCEL_ENV;

  const getAccounts = async () => {
    const web3_1 = new Web3(window.ethereum);

    const accounts = await web3_1.eth.getAccounts();
    setUserAddress(accounts[0]);
  };

  useEffect(() => {
    getAccounts();
  }, []);

  useEffect(() => {
    if (userAddress) {
      const fetchUserData = async () => {
        const res = await getUserData(userAddress);
        setIsWhiteListed(res?.isWhiteListed);
      };

      fetchUserData();
    }
  }, [userAddress]);

  const handleSignIn = () => {
    setLoggedIn(true);
    setIsSignInModalOpen(false);
  };

  const navigateToHome = () => {
    navigate("/");
  };
  const navigateToDiscord = () => {
    navigate("/sign-in");
  };

  const getbanner = () => {
    getTopBannerText().then((banner) => {
      setBannerEnable(banner?.enable);
      setBannerText(banner?.text);
      // setBannerOpen(true);
    });
  };
  const closeBanner = () => {
    setBannerOpen(false);
  };

  useEffect(() => {
    getbanner();
  }, []);

  // Get the current route path
  const currentPath = window.location.pathname;

  const { chains, publicClient } = configureChains(
    [flare, songbird],
    [publicProvider()]
  );
  const { connectors } = getDefaultWallets({
    appName: "fatcat",
    projectId: "af6a837ee2e1f5cce8a95b447794ec55",
    chains,
  });

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
  });
  const pathsToCustomSpace = [
    "/create-art",
    "/create",
    "/list-forSale",
    "/edit-collections",
    "/no-collection-exists",
    "/select",
  ];
  const customSpace = pathsToCustomSpace.includes(location.pathname);

  useEffect(() => {
    // Step 1: Check if the email is present in localStorage
    const userEmail = localStorage.getItem("catalystSigner");

    if (userEmail) {
      // Step 2: Perform login action
      setLoggedIn(true);
      // Perform your login action here, such as setting authentication state or redirecting to a logged-in page
    } else {
      // Step 3: Handle the case where the email is not present
      setLoggedIn(false);
      // Handle this case according to your application logic, such as redirecting to a login page or showing a login form
    }
  }, [localStorage.getItem("catalystSigner")]);

  return (
    <div className="App">
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider coolMode chains={chains}>
          <RefreshProvider>
            <ToastContainer />
            {/* {bannerEnable ? (
              bannerOpen == undefined ? (
                <Banner
                  message={bannerText}
                  modalSize="compact"
                  onClose={closeBanner}
                />
              ) : (
                <></>
              )
            ) : null} */}

            <Navbar
              isSignInModalOpen={isSignInModalOpen}
              setIsSignInModalOpen={setIsSignInModalOpen}
            />

            <div
              style={{ marginTop: customSpace ? "0px" : "90px" }}
              className="mt-mb-0"
            >
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/verify/:id" element={<Verify />} />

                {/* <Route path="/profile/:id" element={<Profile />} /> */}
                <Route path="/profile/:address" element={<Profile />} />
                <Route path="/profile/:id" element={<Profile />} />
                <Route path="/prof/:address" element={<Stats />} />

                {/* <Route path="/single-artwork2" element={<SingleArt />} /> */}
                <Route
                  path="/explore"
                  element={
                    <TagProvider>
                      <Explore />
                      {/* <Footer /> */}
                    </TagProvider>
                  }
                />
                {/* <Route path="/drops" element={<Drop />} /> */}

                <Route path="/stats" element={<Stats />} />

                <Route
                  path="/notification"
                  element={<Notifications hideHead="opacity-0" />}
                />

                <Route
                  path="/notification-settings"
                  element={<NotificationSettings />}
                />
                <Route path="/profile-settings" element={<ProfileSetting />} />
                <Route path="/account-settings" element={<AccountSettings />} />
                <Route path="/catalyst-phoenix-nexus" element={<Admin />} />
                <Route
                  path="/report-issue"
                  element={<ReportIssue hide="hide-class" />}
                />

                {/* ----------Errors */}
                <Route
                  path="*"
                  element={
                    <Error
                      heading="Error 404"
                      errorMessage="Whoops! Looks like the page you're looking for doesn’t exist. Please
                   double check the URL."
                      buttonText="Go home"
                      onClick={navigateToHome}
                      navPath="/"
                    />
                  }
                />

                {/* ------------Discord----------- */}
                <Route
                  path="/something-went-wrong"
                  element={
                    <Error
                      heading="Oops.."
                      errorMessage="Something went wrong on our end. Please reach out to our team on Discord if the issue persists."
                      buttonText="Go to Discord"
                      onClick={navigateToDiscord}
                      navPath=""
                    />
                  }
                />
                {/* ----------------down for maintenance-------------- */}
                <Route
                  path="/down-for-maintenance"
                  element={
                    <Error
                      heading="Sorry."
                      errorMessage="We’re currently down for maintenance. Check out our Discord channel for updates."
                      buttonText="Go to Discord"
                      onClick={navigateToDiscord}
                    />
                  }
                />
                {/* --------------- admin panel --------------- */}
                <Route path="/whitelist" element={<Whitelist />} />
                <Route path="/admin-dashboard" element={<Dashboard />} />
                <Route path="/admin-spotlight" element={<SpotLight />} />
                <Route path="/site-settings" element={<SiteSetting />} />
                <Route path="/featured-art" element={<FeaturedArt />} />
                <Route path="/artist-applied" element={<ArtistApllied />} />
                <Route
                  path="/collection-applied"
                  element={<CollectionApllied />}
                />
                <Route path="/marketing-promotions" element={<Market />} />
                <Route path="/black-list" element={<Blacklist />} />
                <Route path="/no-network" element={<NoNetwork />} />
                <Route
                  path="/create"
                  element={
                    isWhiteListed ? <CreateArtTabs /> : <Navigate to="/" />
                  }
                />
                <Route path="/create-art/:id" element={<CreateArt />} />
                <Route path="/create-art" element={<CreateArt />} />
                <Route
                  path="/mint-art"
                  element={loggedIn ? <MintArt /> : <Navigate to="/" />}
                />

                <Route
                  path="/select"
                  element={loggedIn ? <SelectArtTab /> : <Navigate to="/" />}
                />
                <Route
                  path="/list-forSale"
                  element={loggedIn ? <ListforSale /> : <Navigate to="/" />}
                />

                <Route
                  path="/list-forOffer"
                  element={loggedIn ? <ListforOffer /> : <Navigate to="/" />}
                />
                <Route
                  path="/spotlight"
                  element={
                    <Spotlight
                      isLoggedIn={loggedIn}
                      setIsSignInModalOpen={setIsSignInModalOpen}
                    />
                  }
                />
                {/* sa */}
                <Route path="/apply" element={<Applyform />} />
                <Route
                  path="/create-collections"
                  element={
                    loggedIn ? <CreateCollection /> : <Navigate to="/" />
                  }
                />

                <Route
                  path="/edit-collections/:id"
                  element={loggedIn ? <EditCollection /> : <Navigate to="/" />}
                />
                {/* <Route path="/allcollections" element={<AllCollections />} /> */}
                <Route
                  path="/no-collection-exists"
                  element={<NoCollectonArtwork />}
                />
                <Route
                  path="/explore-collections/:id"
                  element={<ExploreCollections />}
                />
                <Route path="/help-center" element={<HelpCernter />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />

                <Route
                  path="/artwork/:network/:collectionId/:tokenId"
                  element={
                    <TagProvider>
                      <SingleArt2 />
                    </TagProvider>
                  }
                />
              </Routes>
            </div>
          </RefreshProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </div>
  );
}

export default App;
