import React, { useEffect, useState } from "react";
import Header from "../header";
import del from "../../assets/icon/bin.svg";
import Button from "../button";
import Modal from "react-bootstrap/Modal";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import ImageUpload from "../imageUpload";
import ImageUploadCollection from "../imageUploadCollection";
import CustomCheckBox from "../shared/customTags";
import Textarea from "../shared/textarea";
import InputBox from "../shared/inputBox";
import { toast } from "react-toastify";
import {
  getCollections,
} from "../../firebase/firebase";

import { useAccount, useWalletClient } from "wagmi";
import Web3 from "web3";
import mintContractABI from "../../abis/SafeMint/v2/abi.json";
import { useLocation } from "react-router-dom";
import { getAllArts } from "../../firebase/firebase";
import CollectionCard from "../collectionCard";
import Backend_url from "../../herokuUrlConfig";
import { uploadImage, uploadVideo } from "../../Services/nft";

const CreateArt = () => {

  const { address } = useAccount();
  const navigate = useNavigate();
  const location = useLocation();

  // useStates
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createArtWork, setCreateArtWork] = useState(false);
  const [filetType, setFileType] = useState();

  let username = localStorage?.getItem("userName");

  const [previewImage, setPreviewImage] = useState("");
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [artistFee, setArtistFee] = useState(0);

  const [selectedCollection, setSelectedCollection] = useState(null);
  const [selectedCollectionId, setSelectedCollectionId] = useState(null);
  const [selectedContract, setSelectedContract] = useState(null);
  const [NFTDataUrl, setNFTDataUrl] = useState(null);
  const [collectedArts, setCollectedArts] = useState([]);
  const [Collectionname, setCollectionName] = useState(null);
  const [collectionSymbol, setCollectionSymbol] = useState(null);

  const { data } = location.state;
  useEffect(() => {
    let { CollectionName } = location.state;
    setCollectionName(CollectionName);
  }, [location]);

  useEffect(() => {
    console.log("useEffect");
    getAllArts().then((res) => {
      console.log(res);
      setCollectedArts(res);
    });
  }, []);

  const web3 = new Web3(window.ethereum);

  let mintContract;
  useEffect(() => {
    if (address == undefined || address == null || address == "") {
      toast.error("Please connect wallet");
    } else {
      console.log("address: ", address);
      mintContract = new web3.eth.Contract(mintContractABI, selectedContract);
    }
  }, [selectedContract]);

  const handleItemSelected = (item, documentId, Address) => {
    setSelectedCollection(item);
    setSelectedCollectionId(documentId);
    setSelectedContract(Address);
  };

  const handleCaptchaModal = () => setShowCaptcha(!showCaptcha);

  const verifyHuman = async () => {
    setTimeout(() => {
      setShowCaptcha(false);
    }, 5000);
    createArt();

    return true;
  };

  const handleCreateArtwork = () => {
    setCreateArtWork(true);
  };

  useEffect(() => {
    if (createArtWork) {
      window.scrollTo(0, 0);
    }
  }, [createArtWork]);
  const [collections, setCollections] = useState([]);
  const getUserCollections = async () => {
    const usercollections = await getCollections();
    console.log(usercollections);
    setCollections(usercollections);
  };
  useEffect(() => {
    getUserCollections();
  }, []);

  // Check if location.pathname.split("/")[2] is available
  const shouldShowDropdownFirst = !location.pathname.split("/")[2];
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    console.log(
      "location.pathname.split(/)[2]",
      location.pathname.split("/")[2]
    );
    if (location.pathname.split("/")[2]) {
      setShowDropdown(false);
    }
  }, [location.pathname.split("/")[2]]);

  useEffect(() => {
    console.log("collectionsDocumentId ", location.pathname.split("/")[2]);
    console.log("collection", collections);

    // Filter collections based on the condition
    const filteredCollections = collections.find(
      (collection) => collection.documentId === location.pathname.split("/")[2]
    );

    console.log("filteredCollections", filteredCollections);
    handleItemSelected(
      filteredCollections?.data?.name,
      filteredCollections?.documentId,
      filteredCollections?.data?.contractAddress
    );
  }, [collections]);

  const [traits, setTraits] = useState([]);

  const handleAddTrait = () => {
    setTraits([...traits, { type: "", name: "" }]);
  };

  const handleDeleteTrait = (index) => {
    const updatedTraits = [...traits];
    updatedTraits.splice(index, 1);
    setTraits(updatedTraits);
  };

  const handleTraitChange = (index, key, value) => {
    // Remove any special characters from the input value
    const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, "");

    const updatedTraits = [...traits];
    updatedTraits[index][key] = sanitizedValue;
    setTraits(updatedTraits);
  };

  const initialTags = [
    "3D",
    "Animation",
    "Generative",
    "Phygital",
    "Geometric",
    "Fantasy",
    "Surreal",
    "Illustration",
    "AI",
    "Photography",
    "Portrait",
    "Landscape",
    "Audio",
    "Nature",
    "Drawing",
    "Painting",
  ];
  const [selectedTags, setSelectedTags] = useState([]);

  const toggleTag = (e) => {
    const tag = e.target.value;
    if (e.target.checked) {
      setSelectedTags([...selectedTags, tag]);
    } else {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const [selectedBlockchain, setSelectedBlockchain] =
    useState("Select Blockchain"); // Rename the state variable

  const blockchains = ["Songbird Network", "Flare Network"]; // Your dynamic blockchains list

  const handleSelect = (blockchain) => {
    setSelectedBlockchain(blockchain);
  };

  const [globalErrors, setGlobalErrors] = useState({
    selectedTags: false,
    traits: false,
    name: false,
    description: false,
    previewImage: false,
    image: false,
    artistFee: false,
  });

  useEffect(() => {
    const updatedErrors = { ...globalErrors };

    // Update the error status of the specified field to false
    updatedErrors[image] = false;
    console.log(updatedErrors, "updated Error");

    // Set the updatedErrors state
    setGlobalErrors(updatedErrors);
  }, [image]);

  const handleImage = async (e) => {
    const file = e.target.files[0];
    console.log(file);

    if (file) {
      // Check if the file type starts with 'image/'
      if (file.type.startsWith("image/")) {
        setFileType("image");
      }
      // Check if the file type starts with 'video/'
      else if (file.type.startsWith("video/")) {
        setFileType("video");
      }
      // File type is neither image nor video
      else {
        setFileType("unknown");
        console.log("Unsupported file type");
        return; // Stop execution
      }

      // Set the uploaded file
      setImage(file);

      const updatedErrors = { ...globalErrors };
      updatedErrors["image"] = false; // Use string 'image' instead of variable image
      console.log(updatedErrors, "updated Error");

      // Set the updatedErrors state
      setGlobalErrors(updatedErrors);
    } else {
      console.log("No file selected");
    }
  };
  let resetError = {
    selectedTags: false,
    traits: false,
    name: false,
    description: false,
    previewImage: false,
    image: false,
    artistFee: false,
  };

  const create = () => {
    console.log("image in create art", image);
    let errors = {
      selectedTags: false,
      traits: false,
      name: false,
      description: false,
      previewImage: false,
      image: false,
      artistFee: false,
    };
    if (!name) {
      errors.name = true;
      // toast.error("name missing");
    }
    if (!image) {
      errors.image = true;
      // toast.error("image missing");
    }

    if (filetType === "video") {
      if (!previewImage) {
        errors.previewImage = true;
      }
    }
    setGlobalErrors(errors);
    if (filetType === "video") {
      if (name && previewImage) {
        setShowCaptcha(true);
      }
    } else if (name && image) {
      setShowCaptcha(true);
    }
  };

  const createArt = async () => {
    let errors = {
      selectedTags: false,
      traits: false,
      name: false,
      description: false,
      previewImage: false,
      image: false,
      artistFee: false,
    };

    if (image == null) {
      errors.image = true;
    }

    if (!name) {
      errors.name = true;
    }

    if (!image) {
      errors.image = true;
    }

    if (filetType === "video") {
      if (!previewImage) {
        errors.previewImage = true;
      }
    }
    setGlobalErrors(errors);

    if (Object.values(errors).every((error) => !error)) {
      setLoading(true);

      let artistAddress = await web3.eth.getAccounts();
      artistAddress = artistAddress[0];

      let ImageUrl;
      let PreviewImageUrl;
      let videoUrl;

      // Upload images and videos to the backend
      if (filetType !== "video") {
        const formData = new FormData();
        formData.append("image", image);

        try {
          const response = await uploadImage(image);

          if (response.success) {
            ImageUrl = response.imageUrl;
            console.log("ImageUrl", ImageUrl);
            console.log("Image uploaded successfully");
          } else {
            console.error("Failed to upload image:", response.error);
          }
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      }

      if (filetType === "video") {
        const formData = new FormData();
        formData.append("video", image);

        try {
          const response = await uploadVideo(image);

          if (response.success) {
            videoUrl = response.videoUrl;
            console.log("videoUrl", videoUrl);
            console.log("Video uploaded successfully");
          } else {
            console.error("Failed to upload video:", response.error);
          }
        } catch (error) {
          console.error("Error uploading video:", error);
        }
        const previewFormData = new FormData();
        previewFormData.append("image", previewImage);

        try {
          const response = await fetch(`${Backend_url}/uploadImg`, {
            method: "POST",
            body: previewFormData,
          });

          if (response.ok) {
            const responseData = await response.json();
            PreviewImageUrl = responseData.imageUrl;
            console.log("Preview image uploaded successfully");
          } else {
            console.error(
              "Failed to upload preview image:",
              response.statusText
            );
          }
        } catch (error) {
          console.error("Error uploading preview image:", error);
        }
      }

      setLoading(false);

      // Navigate to the mint-art page with collected data
      navigate(`/mint-art`, {
        state: {
          art: {
            username,
            name,
            description,
            traits,
            selectedTags,
            artistFee,
            artistAddress,
            imageUrl: ImageUrl,
            previewImageUrl: PreviewImageUrl,
            videoUrl: videoUrl,
          },
          name: Collectionname,
        },
      });
    }
  };

  useEffect(() => {
    console.log(image, "image");
  }, [image]);

  return (
    <div>
      <section className="create-artwork">
        <Header head="Create" />

        <div className="create-artwork-content  ">
          <h4 className="medium-head">Create New Artwork</h4>
          <p className="body-medium mt-3 fw-bold">
            Once your artwork has been created, you will not be able to edit or
            update any of its information.
          </p>
          <>
            <div className="upload-file mt-40">
              <p className="body-large fw-bold">
                Upload your file*
                <span className="body-medium ms-2 text-medium-grey fw-500">
                  Required
                </span>
              </p>
              <p className="body-small mt-3 text-medium-grey">
                JPG, PNG, GIF, SVG, MP4 Max size: 6mb
              </p>

              <ImageUpload
                onChange={(e) => {
                  handleImage(e);
                }}
                name="logo"
                value={image}
                setImage={setImage}
                type={filetType}
                setFileType={setFileType}
                setGlobalErrors={setGlobalErrors}
                image={image}
              />

              {globalErrors.image && (
                <p
                  className="warning"
                  style={{
                    color: "red",
                  }}
                >
                  Image is missing
                </p>
              )}
            </div>

            {/* preview of uploaded file */}
            {filetType === "video" && (
              <div className="preview-file upload-file mt-40">
                <p className="body-large fw-bold">
                  Preview Image*
                  <span className="body-medium ms-2 text-medium-grey fw-500">
                    Required
                  </span>
                </p>
                <p className="body-small mt-3 text-medium-grey">
                  Since you've added multimedia, please provide an image (PNG,
                  JPG, or GIF) for your item's card display. Max size: 6mb
                </p>
                <ImageUploadCollection
                  onChange={(e) => {
                    console.log(e.target.files[0].size / 1024 / 1024);
                    setPreviewImage(e.target.files[0]);
                    setGlobalErrors(resetError);
                  }}
                  setImage={setPreviewImage}
                  name="featured"
                  value={previewImage}
                />
                {globalErrors.previewImage && (
                  <p
                    className="warning"
                    style={{
                      color: "red",
                    }}
                  >
                    Preview Image is missing
                  </p>
                )}
              </div>
            )}

            {/* art name */}
            <div className="mt-30">
              <InputBox
                label="Name*"
                subLabel="required"
                id="inputId"
                type="text"
                className="custom-class"
                placeholder="Artwork Name"
                disabled={false}
                optional={false}
                maxLength={50}
                onChange={(e) => {
                  console.log(e.target.value);
                  setName(e.target.value);
                  setGlobalErrors(resetError);
                }}
                value={name}
              />
              {globalErrors.name && (
                <p
                  className="warning"
                  style={{
                    zIndex: 1, // Set the z-index to bring it to the front
                    marginTop: "-1rem", // Adjust the position to align it properly
                    color: "red",
                  }}
                >
                  Name is missing
                </p>
              )}
            </div>

            {/* Description */}

            <div className="art-name mt-30 d-flex flex-column ">
              <div className="input-label v-center justify-content-between">
                <label className="text-black medium">
                  Description
                  {/* <span className="body-medium text-capitalize fw-500 text-medium-grey ms-2">
                    Required *
                  </span> */}
                </label>
              </div>
              <Textarea
                maxLength={1000}
                placeholder="About Artwork"
                onChange={(e) => {
                  console.log(e.target.value);
                  setDescription(e.target.value);
                }}
                value={description}
              />
              {globalErrors.description && (
                <p
                  className="warning"
                  style={{
                    zIndex: 1, // Set the z-index to bring it to the front
                    marginTop: "-1.5rem", // Adjust the position to align it properly
                    color: "red",
                  }}
                >
                  Description is missing
                </p>
              )}
            </div>

            <div className="mt-30">
              <label className="medium text-black">COLLECTION NAME</label>
              <CollectionCard data={data} maxWidth="100%" cursor="hover-none" />
            </div>
            {/* Artist fee */}

            {/* <InputBox
              label="Artist fee"
              type="Number"
              placeholder="Amount"
              className="inputtype1"
              value={artistFee}
              onChange={(e) => setArtistFee(e.target.value)}
            />
            {globalErrors.artistFee && (
              <p
                className="warning"
                style={{
                  color: "red",
                }}
              >
                Artist fee is missing
              </p>
            )} */}

            {/* Collection */}
            {/* <div className="art-collections">
              <p className="body-large fw-bold mt-40 d-flex justify-content-between">
                <span>Collection</span>
                <span className="fw-normal text-underline pointer">
                  Manage collections
                </span>
              </p>
              <p className="body-small text-medium-grey mt-3 ">
                This is the collection where your artwork will appear.
              </p>
              <Dropdown className="select-collection">
                <Dropdown.Toggle id="dropdown-basic">
                  {selectedCollection ? selectedCollection : "Collections"}
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                  {collections.map((item, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() =>
                        {handleItemSelected(item.data.name, item.documentId)
                        console.log("contractAddess",item.data.contractAddress)
                        setSelectedContract(item.data.contractAddress)}
                      }
                    >
                      {item.data.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              {globalErrors.selectedCollection && <p className="warning">Selected collection is missing</p>}
              
            </div> */}

            {/* Traits */}
            <div className="artwork-traits mt-30">
              <p className="body-large fw-bold  d-flex justify-content-between">
                <span>Traits</span>
                {/* <span className="fw-normal text-underline pointer">
                Manage collections
              </span> */}
              </p>
              <p className="body-small text-medium-grey mt-3 ">
                A distinguishing quality or characteristic.
              </p>

              <div className="add-traits mt-3 d-flex flex-column">
                {/* <div className="d-flex  add-traits-head">
                  <div className="left">
                    <label className="text-black ">Type</label>
                  </div>
                  <div className="left">
                    <label className="text-black ">name</label>
                  </div>
                </div> */}

                {/* static */}

                {/* <div className="d-flex mt-3 add-traits-content">
                  <div className="type left">
                    <InputBox
                      label="Type"
                      subLabel=" "
                      id="inputId"
                      type="text"
                      className="custom-class"
                      placeholder="Artwork Type"
                      disabled={false}
                      optional={false}
                      maxLength={30}
                    />
                  </div>
                  <div className="name ">
                    <InputBox
                      label="name"
                      subLabel=" "
                      id="inputId"
                      type="text"
                      className="custom-class"
                      placeholder="Artwork name"
                      disabled={false}
                      optional={false}
                      maxLength={30}
                    />
                  </div>
                  <div className="delete v-end">
                    <Button
                      width="41px"
                      height="41px"
                      className="rounded-circle btn-prime bg-white v-center h-center"
                      imageSrc={del}
                    />
                  </div>
                </div>

                <Button
                  text="Add a trait +"
                  className="btn-prime btn-secondary add-btn"
                  height="36px"
                  width="100%"
                /> */}

                <div>
                  {traits.map((trait, index) => (
                    <div
                      key={index}
                      className="d-flex mt-3 add-traits-content mb-4"
                    >
                      <div className="type left">
                        <InputBox
                          label="Type"
                          subLabel=" "
                          id={`inputId-${index}`}
                          type="text"
                          className="custom-class"
                          placeholder="Trait Type"
                          disabled={false}
                          optional={false}
                          maxLength={30}
                          value={trait.type}
                          onChange={(e) =>
                            handleTraitChange(index, "type", e.target.value)
                          }
                        />
                      </div>
                      <div className="name">
                        <InputBox
                          label="Name"
                          subLabel=" "
                          id={`inputId-${index}`}
                          type="text"
                          className="custom-class"
                          placeholder="Trait Name"
                          disabled={false}
                          optional={false}
                          maxLength={30}
                          value={trait.name}
                          onChange={(e) =>
                            handleTraitChange(index, "name", e.target.value)
                          }
                        />
                      </div>
                      <div className="delete v-center">
                        <Button
                          width="41px"
                          height="41px"
                          className="rounded-circle btn-prime bg-white v-center h-center"
                          imageSrc={del}
                          onClick={() => handleDeleteTrait(index)}
                        />
                      </div>
                    </div>
                  ))}

                  <Button
                    text="Add a trait +"
                    className="btn-prime btn-secondary add-btn mt-0"
                    height="36px"
                    width="100%"
                    onClick={handleAddTrait}
                  />
                </div>
              </div>
              {globalErrors.traits && (
                <p
                  className="warning"
                  style={{
                    color: "red",
                  }}
                >
                  {" "}
                  Traits are missing
                </p>
              )}
            </div>

            {/* tags */}
            {/* <div className="artwork-tags">
              <p className="body-large fw-bold mt-40 d-flex justify-content-between">
                <span>Tags</span>
              </p>
              <p className="body-small text-medium-grey mt-3 ">
                Select any number of tags that best describe your work.
              </p>
              <div className="mt-22">
                <CustomCheckBox
                  values={[
                    "3d",
                    "Animation",
                    "Phygital",
                    "3d",
                    "Animation",
                    "Phygital",
                    "3d",
                    "Animation",
                    "Phygital",
                  ]}
                  disabled={false}
                  onChange={(e) => {
                    console.log(e.target.value);
                    console.log(e.target.checked);
                  }}
                />
              </div>
            </div> */}

            <div className="artwork-tags">
              <p className="body-large fw-bold mt-40 d-flex justify-content-between">
                <span>Tags</span>
              </p>
              <p className="body-small text-medium-grey mt-3">
                Select any number of tags that best describe your work.
              </p>
              <div className="mt-22">
                <CustomCheckBox
                  values={initialTags}
                  selectedTags={selectedTags}
                  onChange={toggleTag}
                />
              </div>

              {selectedTags.length > 0 && (
                <p className="mt-4">
                  Selected Tags:{" "}
                  {selectedTags.map((tag) => `#${tag}`).join(", ")}
                </p>
              )}
              {globalErrors.selectedTags && (
                <p
                  className="warning"
                  style={{
                    color: "red",
                  }}
                >
                  Selected Tags are missing
                </p>
              )}
            </div>

            <div className="divider"></div>

            <Button
              text="Continue"
              className="btn-prime btn-primary br-30 font-18"
              height="50px"
              width="100%"
              loading={loading}
              onClick={create}
            />
          </>
        </div>

        <Modal
          show={showCaptcha}
          onHide={handleCaptchaModal}
          className="sign-modal "
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <label htmlFor="" className="medium">
                ALMOST DONE
              </label>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="captcha-content v-center flex-column pb-5">
              <p className="body-medium text-center mb-5">
                Before we create this item, please confirm that you are indeed a
                human by ticking the box below.
              </p>
              <ReCAPTCHA
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={verifyHuman}
              />
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </div>
  );
};

export default CreateArt;
