import React, { useState, useEffect } from "react";
import Header from "../header";
import spike from "../../assets/icon/spiked-circle/black/24px.svg";
import Collection from "../../assets/icon/collection-white.svg";
import logo from "../../assets/images/logo.png";
import Button from "../button";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";

import { getCollections, getUserData } from "../../firebase/firebase";
const CreateArtTabs = () => {
  const navigate = useNavigate();
  const { address } = useAccount();
  const [collection, setCollection] = useState([]);
  const [user, setUser] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Artwork");


  // get collection data
  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await getCollections();

        if (address && Array.isArray(res)) {
          const filteredCollection = res.filter(
            (item) => item.data.address === address
          );
          setCollection(filteredCollection);
        }
      } catch (error) {
        console.error("Error fetching collection details:", error);
      }
    };

    fetchData();
  }, [address]);



  useEffect(() => {
    const fetchData = async () => {
      let user = await getUserData(address);
      setUser(user);
    };

    fetchData();
  }, [address]);


  const createNavigation = () => {
    // check if the user is whitelisted or not

    if (user?.isWhiteListed === false || !user?.isWhiteListed) {
      toast.error("Artist is not Approved");
      return;
    }


    if (selectedTab === "Artwork") {
      if (collection && collection.length > 0) {

        navigate(`/select`, {
          state: { collection: collection },
        });
      } else {
        navigate("/no-collection-exists");
      }
    } else if (selectedTab === "Collection") {
      navigate("/create-collections");
    }
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  return (
    <div>
      <div className="">
        <div className="create-art-tabs">
          <Header head="Create" className="position-relative" />
          <div className="content-wrapper">
            <div className="left">
              <label className="small">CREATE</label>
              <div className="wrapper-tabs">
                <label
                  className={`text-black pointer no-text-transform mb-3 ${selectedTab === "Artwork" ? "active" : ""
                    }`}
                  onClick={() => handleTabClick("Artwork")}
                >
                  <img src={spike} alt="spike" />
                  Artwork
                </label>
                <label
                  className={`text-black pointer no-text-transform mb-3 ${selectedTab === "Collection" ? "active" : ""
                    }`}
                  onClick={() => handleTabClick("Collection")}
                >
                  <img src={Collection} alt="spike" />
                  Collection
                </label>
              </div>
            </div>

            <div
              className={`right ${selectedTab == "Artwork" ? "artwork-bg" : "collection-bg"
                }`}
            >
              <div className="site-logo">
                <img src={logo} alt="" className="invert1" />
              </div>
              <h4 className="medium-head text-white">
                <img src={spike} alt="" className="img-48 invert1 me-3" />
                Create New {selectedTab}
              </h4>
              <p className="w-50 text-white body-medium fw-light mt-3 mb-5">
                Create a new artwork on one of your ERC-721 smart contracts.{" "}
                {/* <a href="#" className="text-white text-decoration-underline">
                  Learn more
                </a> */}
              </p>
              <Button
                text={`Create ${selectedTab}`}
                className="border-0 bg-white text-black btn-prime btn-primary fs-6 br-30"
                width="195px"
                height="47px"
                onClick={createNavigation}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateArtTabs;
