import React, { useState, useEffect, useRef } from "react";
import "../../style/main.scss";
import verified from "../../assets/icon/verified-artist-small.svg";
import option from "../../assets/icon/more-vertical.svg";
import sale from "../../assets/icon/list-for-sale.svg";
import transfer from "../../assets/icon/transfer.svg";
import copyLink from "../../assets/icon/link.svg";
import view from "../../assets/icon/eye.svg";
import eidt from "../../assets/icon/edit.svg";
import dropdown from "../../assets/icon/chevron-down-extra-small.svg";
import { Offcanvas } from "react-bootstrap";
import close from "../../assets/icon/close.svg";
import tick from "../../assets/icon/tick-large-black.svg";
import Dropdown from "../shared/dropdown";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import marketplaceContractABI from "../../abis/Marketplace/abi.json";
import Web3 from "web3";
import {
  getAllUsers,
  getArtLastPriceFromFirebase,
  getContractAddressByCollectionId
} from "../../firebase/firebase";
import mintContractABI from "../../abis/SafeMint/v2/abi.json";
import AsideFilterExplore from "../explore/asideFilterExplore";
import config from "../../config";
import { useAccount } from "wagmi";
import { Table } from "antd";

const LayoutRow = (props) => {
  const [show, setShow] = useState({});
  const [activePrice, setActivePrice] = useState("listPrice");
  const [priceShow, setPriceShow] = useState(false);
  const dropdownRef = useRef(null);

  const { address } = useAccount();
  const [collectionDetails, setCollectionDetails] = useState(props?.ApvNFT);
  const [nftDetails, setNftDetails] = useState([]);
  const [listedNfts, setListedNfts] = useState([]);
  const navigate = useNavigate();
  const [collectionData, setCollectionData] = useState([]);
  const [nftFilteredDetails, setNftFilteredDetails] = useState(collectionData);
  const [tokenId, setTokenId] = useState();
  const location = useLocation();
  const [userData, setUserData] = useState([]);

  const [web3, setWeb3] = useState(new Web3(window.ethereum));
  const [isNftApproved, setIsNftApproved] = useState(false);
  const { marketplace, erc20 } = config;
  const Marketplace_coston_contractAddress =
    marketplace.coston;
  const Marketplace_coston2_contractAddress =
    marketplace.coston2;
  const contractABI = marketplaceContractABI;

  const coston_Token = erc20.coston;
  const coston2_Token = erc20.coston2;

  // setting rpc url
  useEffect(() => {

    try {
      if (collectionDetails?.selectedNetwork === config.networks.songbird) {
        setWeb3(new Web3(config.rpc.songbird));
      } else {
        setWeb3(new Web3(config.rpc.flr));
      }
    }
    catch (error) {
      console.log(error, 'error');
    }

  }, [collectionDetails]);

  // price Filter handle
  const priceHandleShow = () => setPriceShow(true);
  const priceHandleHide = () => setPriceShow(false);

  const showOption = (index) => {
    setShow({ [index]: true });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  // ----------- Handle option menu end

  const handleIPriceTick = (tick) => {
    setActivePrice(tick);
    priceHandleHide();
  };

  const priceOptions = [
    { value: "listPrice", label: "List price" },
    { value: "lastPrice", label: "Last sale price" },
    { value: "bestOffer", label: "Best offer" },
  ];

  // get all listed data from blockchain


  const getAllListNFTData = async () => {
    try {
      if (web3 !== null) {
        let MarketplaceAddress;
        if (collectionDetails?.selectedNetwork === config.networks.songbird) {
          MarketplaceAddress = Marketplace_coston_contractAddress;
        } else if (collectionDetails?.selectedNetwork === config.networks.flare) {
          MarketplaceAddress = Marketplace_coston2_contractAddress;
        }

        const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);
        const data = await contract.methods.getAllListedNfts().call();

        setListedNfts(data);
      }
    }
    catch (error) {
      console.log(error);
    }

  };

  useEffect(() => {
    setNftDetails(props?.nftDetails);
    getAllListNFTData();
  }, [props?.nftDetails, web3]);

  // get connected users nfts from blockchain

  const getUserNftsFromContract = async (uri) => {




    try {
      let web3_1;
      if (collectionDetails?.selectedNetwork === config.networks.songbird) {
        web3_1 = new Web3(config.rpc.songbird);
      } else if (collectionDetails?.selectedNetwork === config.networks.flare) {
        web3_1 = new Web3(config.rpc.flr);
      }
      if (web3_1 !== null) {
        const contract = new web3_1.eth.Contract(
          mintContractABI,
          collectionDetails?.contractAddress
        );
        const contractOwner = await contract.methods.owner().call();


        return new Promise((resolve, reject) => {
          contract.methods
            .getTokenId(collectionDetails?.address)
            .call({ from: contractOwner }, async (error, result) => {
              if (error) {
                console.error(error);
                reject(error);
              } else {
                if (result?.length > 0) {
                  for (let a of result) {
                    if (a.uri === uri) {
                      resolve(a.tokenId);
                    }
                  }
                } else {
                  console.log("error");
                  reject("Error: No result found");
                }
              }
            });
        });
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };



  // mapping listed data to nfts



  useEffect(() => {
    if (props?.nfts !== undefined) {

      let nftListDetails = [];

      props?.nfts.forEach((item) => {
        let isSaleListed = false;
        let isOfferListed = false;
        let saleCountIndex = 0;  // Initialize saleCountIndex
        let offerCountIndex = 0; // Initialize offerCountIndex


        // Check for sale listings
        listedNfts[0]?.forEach((nft) => {
          if (nft?.uriData === item?.uri) {
            isSaleListed = true;
            saleCountIndex = nft?.listCount; // Get listCount from sale listing
            nftListDetails.push({
              ...nft,
              ...item,
              isListed: true,
              isSaleListed: true,
              saleCountIndex, // Add saleCountIndex to the object
            });
          }
        });

        // Check for offer listings
        listedNfts[1]?.forEach((nft) => {
          if (nft?.uriData === item?.uri) {
            isOfferListed = true;
            offerCountIndex = nft?.listCount; // Get listCount from offer listing
            const existingIndex = nftListDetails.findIndex(
              (nftDetail) => nftDetail.uri === item.uri
            );

            if (existingIndex !== -1) {
              nftListDetails[existingIndex] = {
                ...nftListDetails[existingIndex],
                isOfferListed: true,
                offerCountIndex, // Add offerCountIndex to the object
              };
            } else {
              nftListDetails.push({
                ...nft,
                ...item,
                isListed: true,
                isOfferListed: true,
                offerCountIndex, // Add offerCountIndex to the object
              });
            }
          }
        });

        // Ensure both flags are set correctly for NFTs listed in both categories
        if (isSaleListed || isOfferListed) {
          const existingIndex = nftListDetails.findIndex(
            (nftDetail) => nftDetail.uri === item.uri
          );
          if (existingIndex !== -1) {
            nftListDetails[existingIndex] = {
              ...nftListDetails[existingIndex],
              isListed: true,
              isSaleListed: isSaleListed,
              isOfferListed: isOfferListed,
            };
          }
        }
      });


      // Fetch and set last price for each NFT
      nftListDetails.forEach(async (e) => {
        let Price = await getArtLastPriceFromFirebase(e?.uri);
        if (Price) {
          e.data.lastPrice = Price;
        }
      });

      bid(nftListDetails);

      setCollectionData(nftListDetails);
    } else {
      setCollectionData([]);
      setNftFilteredDetails([]);
    }
  }, [nftDetails, listedNfts, props?.nfts]);

  // calculating maximum offer price




  const bid = async (nfts) => {

    let data = nfts;
    let offerListed = nfts.filter((item) => item.isOfferListed);
    let MarketplaceAddress;

    try {
      if (collectionDetails?.selectedNetwork === config.networks.songbird) {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (collectionDetails?.selectedNetwork === config.networks.flare) {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }

      // getting bidding history from blockchain
      const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);

      const updatedDetails = await Promise.all(
        offerListed.map(async (item) => {
          try {
            const biddingHistoryCoston = await contract.methods
              .getBiddingHistory(item?.offerCountIndex)
              .call();

            // Calculate max price from bidding history
            const maxPrice = calculateMaxPrice(biddingHistoryCoston);

            return { ...item, maxPrice: maxPrice / 1000000000000000000 }; // Assuming maxPrice is in wei, converting to ether
          } catch (innerError) {
            console.error(`Error getting bidding history for item ${item.offerCountIndex}:`, innerError);
            return null; // Returning null if an error occurs for an individual item
          }
        })
      );

      setNftFilteredDetails((prevDetails) => {
        const unchangedItems = data.filter((prevItem) => !prevItem.isOfferListed);
        const mergedDetails = [
          ...unchangedItems,
          ...updatedDetails.filter(Boolean),
        ];
        return mergedDetails;
      });

    } catch (error) {
      console.error("Error in bid function:", error);
    }
  };


  // side filters (buy now, offer now,price, category, network)
  const handleSelectedFilterChange = (newFilter) => {

    let filteredData = [];

    if (collectionData?.length > 0) {

      // Define conversion rates for USD based on selected blockchain
      const conversionRates = {
        coston: props.sgb,
        coston2: props.flr,
      };

      // Function to apply price filter
      const applyPriceFilter = (item) => {
        let priceFilter = true;

        if (newFilter.selectPrice.min !== "" || newFilter.selectPrice.max !== "") {
          let priceInEth = parseFloat(item?.listedData?.price) / 1e18;


          // Adjust price based on selected priceCurrency
          if (newFilter.priceCurrency === "USD") {
            const blockchain = item?.metadata?.selectedBlockchain?.toLowerCase();
            const conversionRate = conversionRates[blockchain] || 1; // Default rate if not found
            priceInEth *= conversionRate;
          } else if (
            newFilter.priceCurrency === "CFLR" &&
            item?.data?.selectedBlockchain?.toLowerCase() !== "coston"
          ) {
            priceFilter = false;
          } else if (
            newFilter.priceCurrency === "C2FLR" &&
            item?.data?.selectedBlockchain?.toLowerCase() !== "coston2"
          ) {
            priceFilter = false;
          } else if (
            newFilter.priceCurrency === "SGB" &&
            item.data.selectedBlockchain?.toLowerCase() !== "sgb"
          ) {
            priceFilter = false;
          } else if (
            newFilter.priceCurrency === "FLR" &&
            item.data.selectedBlockchain?.toLowerCase() !== "flr"
          ) {
            priceFilter = false;
          }

          // Apply price range filtering
          if (priceFilter) {
            priceFilter =
              (newFilter.selectPrice.min === "" || priceInEth >= parseFloat(newFilter.selectPrice.min)) &&
              (newFilter.selectPrice.max === "" || priceInEth <= parseFloat(newFilter.selectPrice.max));
          }
        }

        return priceFilter;
      };

      // Get current time for early access calculation
      const now = Date.now();

      collectionData.forEach((item) => {
        const isCategorySelected = Object.values(newFilter.selectCategories).some((category) => category);
        const selectedCurrency = Object.values(newFilter.selectCurrency).some((currency) => currency);

        let categoriesFilter = true;
        let currencyFilter = true;
        let statusFilter = true;
        let earlyAccessFilter = true;

        // Calculate early access based on listTime (Unix timestamp in seconds)
        const listTime = item?.listedData.listTime;

        const mintedAt = listTime ? new Date(listTime * 1000) : null;

        if (mintedAt) {
          const timeElapsed = now - mintedAt; // Calculate the time difference in milliseconds
          const hoursElapsed = Math.floor(timeElapsed / (1000 * 60 * 60)); // Convert milliseconds to hours
          earlyAccessFilter = hoursElapsed < 24 && item.isSaleListed; // Check if minted within the last 24 hours
        }


        // Apply categories filter
        if (isCategorySelected) {
          categoriesFilter = Object.keys(newFilter.selectCategories).every(
            (category) =>
              newFilter.selectCategories[category]
                ? item?.data?.selectedTags?.includes(category)
                : true
          );
        }

        // Apply currency filter
        if (selectedCurrency) {
          currencyFilter =
            newFilter.selectCurrency.allChains ||
            (newFilter.selectCurrency.flr && item?.data?.selectedBlockchain?.toLowerCase() === "flare network") ||
            (newFilter.selectCurrency.sgb && item?.data?.selectedBlockchain?.toLowerCase() === "songbird network") ||
            (newFilter.selectCurrency.coston && item?.data?.selectedBlockchain?.toLowerCase() === "coston") ||
            (newFilter.selectCurrency.coston2 && item?.data?.selectedBlockchain?.toLowerCase() === "coston2");
        }

        // Apply status filter
        if (
          newFilter.selectStatus.buyNow ||
          newFilter.selectStatus.onOffer ||
          newFilter.selectStatus.new ||
          newFilter.selectStatus.earlyAccess // Check for early access filter
        ) {
          statusFilter =
            (newFilter.selectStatus.buyNow && item?.isSaleListed) ||
            (newFilter.selectStatus.onOffer && item?.isOfferListed) ||
            (newFilter.selectStatus.new && !item?.isListed) ||
            (newFilter.selectStatus.earlyAccess && earlyAccessFilter);
        }

        // Apply all filters
        if (categoriesFilter && currencyFilter && applyPriceFilter(item) && statusFilter) {
          filteredData.push(item);
        }
      });


      // Set the filtered data
      setNftFilteredDetails(filteredData);
    }
  };

  // Filter list price



  // const priceOptions = [
  //   { value: "listPrice", label: "List price" },
  //   { value: "lastPrice", label: "Last sale price" },
  //   { value: "bestOffer", label: "Best offer" },
  // ];





  // setting number of total nfts of filter change.

  useEffect(() => {
    props.setItemNumber(nftFilteredDetails.length);
  }, [nftFilteredDetails]);

  useEffect(() => {
    const getToken = async () => {
      let res = await getTokenIdforNoList();
      setTokenId(res);
    };
    getToken();
  }, [collectionDetails]);

  // get user data from firebase

  useEffect(() => {
    const fetchUserData = async () => {
      const users = await getAllUsers();
      setUserData(users);
    };
    fetchUserData();
  }, []);

  // get artist name by address

  const getArtistNamebyAdress = (accountAddress) => {
    let artistName = "";

    if (address === accountAddress) {
      return "You";
    } else {
      userData?.forEach((user) => {
        if (user?.id === accountAddress) {
          artistName = user?.userName;
        }
      });
    }

    return "@" + artistName;
  };

  // check if nfts are apporoved

  const checkIfNftsApproved = async () => {

    let web3;

    try {
      let MarketplaceAddress;
      if (collectionDetails?.selectedNetwork === config.networks.songbird) {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (collectionDetails?.selectedNetwork === config.networks.flare) {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }

      if (web3 !== null) {
        const contract = new web3.eth.Contract(
          mintContractABI,
          collectionDetails?.contractAddress
        );

        const data = await contract.methods
          .isApprovedForAll(address, MarketplaceAddress)
          .call();

        return data;
      }
    }
    catch (error) {
      console.log(error, "error");
    }

  };

  // function to check if nfts are approved or not

  const getstatus = async () => {
    let res = await checkIfNftsApproved();
    setIsNftApproved(res);
  };

  useEffect(() => {
    getstatus();
  }, [address]);


  // Function to calculate the maximum price from bidding history
  const calculateMaxPrice = (biddingHistoryArray) => {
    let maxPrice = 0;
    for (const biddingHistory of biddingHistoryArray) {
      for (const key in biddingHistory) {
        if (Object.prototype.hasOwnProperty.call(biddingHistory, key)) {
          const currentPrice = parseFloat(biddingHistory[key]);
          if (currentPrice && !isNaN(currentPrice) && currentPrice > maxPrice) {
            maxPrice = currentPrice;
          }
        }
      }
    }
    return maxPrice;
  };

  // get nfts token for nfts that are not listed

  const getTokenIdforNoList = async (uri) => {
    try {
      let TokenId = await getUserNftsFromContract(uri);
      let tokenId = TokenId;
      return tokenId;
    } catch (error) {
      console.error(error);
    }
  };



  const getContractAddressById = async (item) => {
    let res = await getContractAddressByCollectionId(item?.data?.selectedCollectionId);
    if (res) {
      return res;
    }

  }



  const navigateToArt = async (item) => {

    let token = await getTokenIdforNoList(item?.uri);

    let collectionId = await getContractAddressById(item);
    navigate(
      `/artwork/eth/${collectionId}/${token}`
    );
  };








  const [collectionDataDisplayed, setCollectionDataDisplayed] = useState([]);



  // Filters (Recently Minted, Recently Listed, Lowest Price, Highest Price)
  useEffect(() => {

    if (props?.sortFilter !== undefined) {
      let sortedData = [];
      if (props?.sortFilter === "Recently Minted") {
        sortedData = nftFilteredDetails.sort((a, b) => {
          const dateA = new Date(a?.data?.mintedAt);
          const dateB = new Date(b?.data?.mintedAt);
          return dateB - dateA;
        });
      } else if (props?.sortFilter === "Recently Listed") {
        sortedData = nftFilteredDetails
          .filter((item) => {
            return item.isListed;
          })
          .sort((a, b) => {
            const dateA = new Date(parseInt(a?.listedData?.listTime) * 1000);
            const dateB = new Date(parseInt(b?.listedData?.listTime) * 1000);
            return dateB - dateA;
          });
      } else if (props?.sortFilter === "Lowest price") {

        if (nftFilteredDetails?.length > 0) {

          const nftsWithPrice = [...nftFilteredDetails].filter(
            (a) => a?.listedData?.price !== undefined
          );
          const nftsWithoutPrice = [...nftFilteredDetails].filter(
            (a) => a?.listedData?.price === undefined
          );

          const sortByLowestPrice = nftsWithPrice.sort((a, b) => {
            const aPrice = a?.listedData?.price;
            const bPrice = b?.listedData?.price;

            return aPrice - bPrice;
          });

          // Concatenate sorted NFTs with price and NFTs without price
          sortedData = [...sortByLowestPrice, ...nftsWithoutPrice];
        }
      } else if (props?.sortFilter === "Highest price") {

        const nftWithPrice = [...nftFilteredDetails].filter((a) =>
          a.listedData.price !== undefined && a.listedData.price !== null);


        const nftsWithoutPrice = [...nftFilteredDetails].filter((a) =>
          a.listedData.price === undefined || a.listedData.price === null);

        const sortByHighestPrice = [...nftWithPrice].sort((a, b) => {

          const aPrice = a?.listedData?.price;
          const bPrice = b?.listedData?.price;

          return bPrice - aPrice;

        })
        sortedData = [...sortByHighestPrice, ...nftsWithoutPrice];
      }
      setCollectionDataDisplayed(sortedData);

    }
  }, [props?.sortFilter, nftFilteredDetails]);







  const columns = [
    {
      title: `${nftFilteredDetails?.length} ITEMS`,
      key: "items",
      width: "400px",
      render: (_, record) => (
        <div className="d-flex align-items-center">
          <div className="art-img">
            {record.data.image ? (
              <img
                src={record.data.image}
                alt="artwork image"
                className="img-100"
              />
            ) : (
              <img
                src={record.data.previewImg}
                alt="artwork image"
                className="img-100"
              />
            )}
          </div>
          <div className="user-name d-flex flex-column">
            <label
              onClick={() => navigateToArt(record)}
              className="text-black text-capitalize hover-underline pointer"
            >
              {record?.data?.artName.length > 20 ? (
                <>
                  {record?.data?.artName.substring(0, 20) || "Art Name"}
                  ...
                </>
              ) : (
                <>{record?.data?.artName || "Art Name"}</>
              )}
            </label>
            <p className="body-medium fw-bold v-center text-medium-grey hover-underline pointer show-artist-popup">
              {record?.data.isSaleListed && (
                <span
                  onClick={() => {
                    navigate(`/profile/${record?.listedData.seller}`);
                  }}
                >
                  {getArtistNamebyAdress(record?.listedData.seller).length > 18
                    ? getArtistNamebyAdress(
                      record?.listedData.seller
                    ).substring(0, 18) + "..."
                    : getArtistNamebyAdress(record?.listedData.seller)}
                </span>
              )}

              {record?.data.isOfferListed && (
                <span
                  onClick={() => {
                    navigate(`/profile/${record?.newOwner}`);
                  }}
                >
                  {getArtistNamebyAdress(record?.newOwner).length > 18
                    ? getArtistNamebyAdress(record?.newOwner).substring(
                      0,
                      18
                    ) + "..."
                    : getArtistNamebyAdress(record?.newOwner)}
                </span>
              )}
              {!record?.data.isListed && (
                <span
                  onClick={() => {
                    navigate(`/profile/${record?.data.artistAddress}`);
                  }}
                >
                  {getArtistNamebyAdress(record?.data.artistAddress).length > 18
                    ? getArtistNamebyAdress(
                      record?.data.artistAddress
                    ).substring(0, 18) + "..."
                    : getArtistNamebyAdress(record?.data.artistAddress)}
                </span>
              )}
              <img src={verified} alt="verified" className="ms-2 img-18" />
            </p>
          </div>
        </div>
      ),
    },
    {
      title: "List Price",
      key: "List Price",
      render: (_, record) => (
        <div>
          <label className="text-black">
            {record?.isListed
              ? record?.isSaleListed
                ? record?.listedData?.price / 1000000000000000000
                : "--"
              : "--"}
          </label>
        </div>
      ),
    },
    {
      title: "Last Sale Price",
      key: "Last Sale Price",
      render: (_, record) => (
        <>
          <label className="medium text-black">
            {record?.data?.lastPrice / 1000000000000000000 || "--"}
          </label>
        </>
      ),
    },
    {
      title: "Best Offer",
      key: "Best Offer",
      render: (_, record) => (
        <div>
          <label className="text-black">{record?.maxPrice || "--"}</label>
        </div>
      ),
    },
    {
      title: "",
      key: "options",
      render: (_, record, index) => (
        <div className="c5 pricing d-flex align-items-center cursor-pointer position-relative item-hide justify-content-end ">
          {collectionDetails?.address == address ? (
            <div className="c5 pricing d-flex align-items-center cursor-pointer position-relative item-hide justify-content-end dropdown">
              <img
                src={option}
                alt=""
                className="dropdown-toggle no-after"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              />
              <ul className="dropdown-menu no-border bg-transparent">
                <Dropdown
                  listed={record?.isSaleListed ? "listed" : "noListed"}
                  item={record}
                  tokenId={record.listedData?.tokenId}
                  row="row1"
                  transfer={collectionDetails?.Approved}
                />
              </ul>
            </div>

          ) : (

            <p>
              --
            </p>
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="d-flex ">
        <div className={props.flag ? "my-filter w-25" : "d-none"}>
          <AsideFilterExplore
            onSelectedFilterChange={handleSelectedFilterChange}
            clearAll={props?.clearAll}
          />
        </div>

        {/* custom old table */}
        <div
          className={`row-layout hide-on-desktop flex-column ${props.flag ? "w-75 active" : "w-100"
            }`}
        >

          <div className="row-layout-head justify-content-between">
            <label className="small head-1 c1">
              {nftFilteredDetails?.length} Items
            </label>
            <label className="small head-1 c2 hide-on-mobile">List Price</label>
            <label
              className="small head-1 c4 hide-on-mobile"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Last Sale Price
            </label>
            <label
              className="small head-1 c3 hide-on-mobile"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Best Offer
            </label>
            {props?.isOwner && (
              <label className="small head-1 c5 hide-on-mobile pe-4"></label>
            )}
            <label
              className="small text-black d-flex align-items-center justify-content-end hide-on-desktop w-50 "
              onClick={priceHandleShow}
            >
              {
                priceOptions.find((option) => option.value === activePrice)
                  ?.label
              }
              <img src={dropdown} alt="dropdown" />
            </label>
          </div>

          {/* row layout content */}
          <div className="row-layout-body">
            {props?.nfts == undefined ? (
              <></>
            ) : (
              nftFilteredDetails.map((item, index) => {
                return (
                  <div
                    className="row-content d-flex align-items-center justify-content-between "
                    key={index}
                  >
                    {/* Artwork Items */}
                    <div className="c1 d-flex align-items-center artwork-item">
                      <div className="art-img">
                        {item.data.image ? (
                          <img
                            src={item.data.image}
                            alt="artwork image"
                            className="img-100"
                          />
                        ) : (
                          <img
                            src={item.data.previewImg}
                            alt="artwork image"
                            className="img-100"
                          />
                        )}
                      </div>
                      <div className="user-name d-flex flex-column">
                        <label
                          onClick={() => navigateToArt(item)}
                          className="text-black text-capitalize hover-underline pointer"
                        >
                          {item?.data?.artName.length > 20 ? (
                            <>
                              {item?.data?.artName.substring(0, 20) ||
                                "Art Name"}
                              ...
                            </>
                          ) : (
                            <>{item?.data?.artName || "Art Name"}</>
                          )}
                        </label>
                        <p className="body-medium fw-bold v-center text-medium-grey hover-underline pointer show-artist-popup">
                          {item?.data.isSaleListed && (
                            <span
                              onClick={() => {
                                navigate(`/profile/${item?.listedData.seller}`);
                              }}
                            >
                              {getArtistNamebyAdress(item?.listedData.seller)
                                .length > 18
                                ? getArtistNamebyAdress(
                                  item?.listedData.seller
                                ).substring(0, 18) + "..."
                                : getArtistNamebyAdress(
                                  item?.listedData.seller
                                )}
                            </span>
                          )}
                          {item?.data.isOfferListed && (
                            <span
                              onClick={() => {
                                navigate(`/profile/${item?.newOwner}`);
                              }}
                            >
                              {getArtistNamebyAdress(item?.newOwner)
                                .length > 18
                                ? getArtistNamebyAdress(
                                  item?.newOwner
                                ).substring(0, 18) + "..."
                                : getArtistNamebyAdress(item?.newOwner)}
                            </span>
                          )}
                          {!item?.data.isListed && (
                            <span
                              onClick={() => {
                                navigate(
                                  `/profile/${item?.data.artistAddress}`
                                );
                              }}
                            >
                              {getArtistNamebyAdress(item?.data.artistAddress)
                                .length > 18
                                ? getArtistNamebyAdress(
                                  item?.data.artistAddress
                                ).substring(0, 18) + "..."
                                : getArtistNamebyAdress(
                                  item?.data.artistAddress
                                )}
                            </span>
                          )}
                          <img
                            src={verified}
                            alt="verified"
                            className="ms-2 img-18"
                          />
                        </p>



                      </div>
                    </div>


                    {
                      priceOptions.find((option) => option.value === activePrice)
                        ?.label === "List price" &&


                      <div
                        className={` c3 pricing d-flex align-items-center `}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <label className="text-black">
                          {item?.isListed
                            ? item?.isSaleListed
                              ? item?.listedData?.price / 1000000000000000000
                              : "--"
                            : "--"}
                        </label>
                      </div>

                    }


                    {priceOptions.find((option) => option.value === activePrice)
                      ?.label === "Last sale price" &&
                      <div
                        className={` c3 pricing d-flex align-items-center `}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <label className="medium text-black">
                          {item?.data?.lastPrice / 1000000000000000000 || "--"}
                        </label>
                      </div>





                    }

                    {
                      priceOptions.find((option) => option.value === activePrice)
                        ?.label === "Best offer" &&
                      <div
                        className={` c3 pricing d-flex align-items-center `}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <label className="text-black">{item?.maxPrice || "--"}</label>
                        </div>
                      </div>

                    }




                    {/* Best Offer */}
                    {/* <div
                      className={` c3 pricing d-flex align-items-center `}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <label className="text-black">
                        {item?.isListed
                          ? item?.isSaleListed
                            ? item?.listedData?.price / 1000000000000000000
                            : item?.isOfferListed
                              ? item?.listedData?.minimumBid
                              : "--"
                          : "--"}
                      </label>
                    </div> */}

                    <div className="c5 pricing d-flex align-items-center cursor-pointer position-relative item-hide justify-content-end d-none">
                      <img
                        // className="pe-4"
                        src={option}
                        alt="option"
                        onClick={() => showOption(index)}
                      />

                      {/* Collected Artwork more option popup */}
                      {show[index] ? (
                        <div className="collection-option" ref={dropdownRef}>
                          <div className="collection-item">
                            <img src={sale} alt="sale" />
                            <label className="text-black no-text-transform">
                              List for sale
                            </label>
                          </div>
                          <div className="collection-item">
                            <img src={transfer} alt="sale" />
                            <label className="text-black no-text-transform">
                              {" "}
                              Transfer{" "}
                            </label>
                          </div>
                          <div className="collection-item">
                            <img src={copyLink} alt="sale" />
                            <label className="text-black no-text-transform">
                              {" "}
                              Copy link{" "}
                            </label>
                          </div>
                          <div className="collection-item">
                            <img src={view} alt="sale" />
                            <label className="text-black no-text-transform">
                              View item{" "}
                            </label>
                          </div>
                          <div className="collection-item">
                            <img src={eidt} alt="sale" />
                            <label className="text-black no-text-transform">
                              {" "}
                              Edit item
                            </label>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    {props?.isOwner && isNftApproved && (
                      <div className="c5 pricing d-flex align-items-center cursor-pointer position-relative item-hide justify-content-end dropdown ">
                        <img
                          src={option}
                          alt=""
                          className="dropdown-toggle no-after"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        />
                        <ul class="dropdown-menu no-border bg-transparent">
                          <Dropdown
                            listed={item?.isListed ? "listed" : "noListed"}
                            item={item}
                            tokenId={tokenId}
                            row="row"
                            transfer={collectionDetails?.Approved}
                          />
                        </ul>
                      </div>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>

        {/* ant des table */}
        <div
          className={`nft-table hide-on-mobile  ${props.flag ? "w-75 active" : "w-100"
            }`}
        >
          <Table columns={columns} dataSource={collectionDataDisplayed} />
        </div>
      </div>

      {/* List Price menu */}
      <Offcanvas
        show={priceShow}
        onHide={priceHandleHide}
        placement="bottom"
        className="sub-menu-offcanvas"
      >
        <div className="more-menu-sm price-more-menu">
          <div className="menu-head">
            <label className="text-black">change</label>
            <div className="close-btn cursor-pointer">
              <img
                src={close}
                alt="close"
                className="img-24"
                onClick={priceHandleHide}
              />
            </div>
          </div>

          <div className="share">
            {priceOptions.map((option) => (
              <label
                key={option.value}
                className={`no-text-transform h-64 text-black ${activePrice === option.value ? "fw-bold" : "fw-normal"
                  }`}
                onClick={() => handleIPriceTick(option.value)}
              >
                {option.label}
                <img
                  src={tick}
                  alt="tick"
                  className={`${activePrice === option.value
                    ? "active opacity-100"
                    : "opacity-0"
                    }`}
                />
              </label>
            ))}
          </div>
        </div>
      </Offcanvas>
    </div>
  );
};

export default LayoutRow;
