import React, { useState, useEffect, useContext } from "react";
import "../style/main.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import placeholder from "../assets/images/profile-1.svg";
import { useNavigate } from "react-router-dom";
import ArtistPopUp from "./shared/artistpopup";
import verified from "../assets/icon/verified-artist-small.svg";
import lock from "../assets/icon/lock.svg";
import { Popover, Skeleton } from "antd";
import config from "../config";
import {
  getCollectionHistory,
  getAllUsers,
  getCollections,
} from "../firebase/firebase";
import Carousel from "react-bootstrap/Carousel";
import marketplaceContractABI from "../abis/marketplace.json";
import { useAccount } from "wagmi";
import Web3 from "web3";
import { NFTContext } from "./context/appContext";
import { fetchNFTMetadata } from "../Services/nft";
import mintABI from "../abis/SafeMint/abi.json";
import { getContractAddressByCollectionId } from "../firebase/firebase";
import { update } from "lodash";
const LatestNews = () => {

  const { marketplace } = config;
  const { userMembership } = useContext(NFTContext);
  const navigate = useNavigate();
  const { address } = useAccount();
  const Marketplace_coston_contractAddress = marketplace.coston;
  const Marketplace_coston2_contractAddress = marketplace.coston2;

  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [listedNfts, setListedNfts] = useState();
  const [collections, setCollections] = useState([]);
  const [latestNews, setLatestNews] = useState([]);
  const [updatedLatestNews, setUpdatedLatestNews] = useState(null);


  const getUserCollections = async () => {
    const usercollections = await getCollections();
    const filterCollection = usercollections?.filter(
      (item) => item?.data?.isWhiteList === true
    );
    setCollections(filterCollection);
  };

  const getAllListNFTData = async (selectedNetwork, web3) => {
    if (collections && web3 != null) {
      let MarketplaceAddress;
      if (selectedNetwork === config.networks.songbird) {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (selectedNetwork === config.networks.flare) {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }
      const contract = new web3.eth.Contract(
        marketplaceContractABI,
        MarketplaceAddress
      );
      const data = await contract.methods.getAllListedNfts().call();
      return data;
    } else {
    }
  };


  const getNftMetadataList = async (tokens_uri, network) => {
    const nftMetadata = [];
    await Promise.all(
      tokens_uri.map(async (token) => {
        const uri = token.uri;
        try {
          const json = await fetchNFTMetadata(uri);
          const existingIndex = nftMetadata.findIndex(
            (item) => item.uri === uri
          );
          if (existingIndex === -1 && json) {
            nftMetadata.push({
              data: token,
              metadata: {
                uri: uri,
                data: json.data,
              },
            });
          }
        } catch (error) {
          console.error(`Failed to fetch metadata from ${uri}:`, error);
        }
      })
    );
    return nftMetadata;
  };

  // step no 2
  //get nfts of user based on address and map and set listed data to nfts

  const fetchDataForContract = async (contractAddress, selectedNetwork, id) => {
    try {
      // setLoading(true);

      let web;
      if (selectedNetwork === config.networks.flare) {
        web = new Web3(config.rpc.flr);
      } else {
        web = new Web3(config.rpc.songbird);
      }

      const contract = new web.eth.Contract(mintABI, contractAddress);

      // get nfts by collection id
      const nftData = await contract.methods.getTokenIdsByCollection(id).call();

      const listedNfts = await getAllListNFTData(selectedNetwork, web);

      // Merge listed NFTs for sale and offer
      let mergedListedNfts = Array.from(
        new Set([
          ...listedNfts[0].map((nft) => ({
            ...nft,
            isSaleListed: true,
            isOfferListed: listedNfts[1].some((b) => b.uriData === nft.uriData),
            listTime: listedNfts[1].some((b) => b.uriData === nft.uriData) ? listedNfts[1].find((b) => b.uriData === nft.uriData).listTime : nft.listTime
          })),
          ...listedNfts[1]
            .filter((b) => !listedNfts[0].some((a) => a.uriData === b.uriData))
            .map((nft) => ({
              uriData: nft.uriData,
              isSaleListed: false,
              isOfferListed: true,
              listTime: nft.listTime
            })),
        ])

      )
      // Build final NFT list details
      if (nftData || listedNfts) {
        let nftListDetails = [];

        nftData?.forEach((nftItem) => {
          // Find if the NFT exists in the merged listed NFTs
          const matchedListedNft = mergedListedNfts.find(
            (listedNft) => listedNft.uriData === nftItem.uri
          );

          if (matchedListedNft) {
            // If matched, merge data and flags
            nftListDetails.push({
              ...nftItem,
              ...matchedListedNft,
              isListed: true,
              listedType: matchedListedNft.isSaleListed
                ? "sale"
                : matchedListedNft.isOfferListed
                  ? "offer"
                  : "none",
            });
          } else {
            // If not listed, add the base NFT data
            nftListDetails.push({
              ...nftItem,
              isListed: false,
              isSaleListed: false,
              isOfferListed: false,
            });
          }
        });
        // setLoading(false);
        return nftListDetails;
      } else {
        // setLoading(false);
        console.log("Error: NFT data or listed NFTs missing.");
      }
    } catch (error) {
      console.log("Error in fetchDataForContract:", error);
    }
  };


  // fet data for nfts collected by user (connected address)
  const fetchDataForAllContracts = async () => {
    try {
      // setLoading(true);
      let contractDataArray = [];

      for (const item of collections) {

        let data = await fetchDataForContract(
          item?.data.contractAddress,
          item?.data.selectedNetwork,
          item?.documentId
        );
        if (data?.length > 0) {
          contractDataArray.push(data);
        }
      }
      const flattenedTokens = contractDataArray.flat();
      let res = await getNftMetadataList(flattenedTokens);

      if (res) {
        setListedNfts(res); // Setting the state here
      }
    }
    catch (error) {
      console.log(error);
      // setLoading(false);
    }

  };

  useEffect(() => {
    if (collections?.length > 0) {
      fetchDataForAllContracts();
    }
  }, [collections]);

  useEffect(() => {
    getUserCollections();
  }, [address]);

  useEffect(() => {
    const fetchUserData = async () => {
      const users = await getAllUsers();

      setUserData(users);
    };
    fetchUserData();
  }, []);

  const getNamebyAddress = (accountAddress) => {
    if (address === accountAddress) {
      return "You";
    }
    const user = userData?.find((user) => user?.id === accountAddress);
    const artistName = user ? user?.userName : "Not Found";
    return "@" + artistName;
  };

  // get artist image by address

  const getImage = (accountAddress) => {
    const user = userData?.find((user) => user?.id === accountAddress);
    const artistImage = user && user?.image ? user?.image : placeholder;

    return artistImage;
  };



  // Define the convertToUnixTimestamp function
  const convertToUnixTimestamp = (timestamp) => {
    if (timestamp instanceof Date) {
      return Math.floor(timestamp.getTime() / 1000);
    }
    if (timestamp.seconds) {
      return timestamp.seconds;
    }
    return Math.floor(new Date(timestamp).getTime() / 1000);
  };

  useEffect(() => {
    const fetchCollectionHistory = async () => {
      try {
        setLoading(true);
        const collectionHistory = await getCollectionHistory();
        const sortedCollectionHistory = collectionHistory
          .map((item) => ({
            ...item,
            unixTimestamp: convertToUnixTimestamp(item.timestamp),
          }))
          .sort((a, b) => b.unixTimestamp - a.unixTimestamp);

        const firstTenItems = sortedCollectionHistory.slice(0, 10);

        // Merge listed NFT data with collection history
        const data = firstTenItems.map((item) => {
          if (listedNfts?.length > 0) {
            const listedData = listedNfts?.find(
              (e) =>
                e?.data?.listedData?.tokenId === item.tokenId && item.artworkUri?.selectedCollectionId == e?.metadata?.data?.selectedCollectionId
                ||
                (item.uri === e.data.uriData)
            );
            return listedData ? { ...item, ...listedData } : { ...item };
          }
        });

        const filteredNfts = data?.filter((nft) => {
          const owner = userData.find((user) => user.id === nft?.data?.newOwner);
          return owner && !owner.isBlackListed;
        });
        if (filteredNfts?.length > 0) {
          let newData = await Promise.all(filteredNfts.map(async (e) => {

            let mintContractAddress = await getContractAddressByCollectionId(e?.artworkUri?.selectedCollectionId);
            return { ...e, mintContractAddress };
          }));
          setLatestNews(newData);
        }
        if (listedNfts) {
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchCollectionHistory();
  }, [listedNfts]);



  function timeAgo(timestamp) {
    const currentDate = new Date();
    const providedDate = new Date(timestamp * 1000);
    const timeDifference = currentDate - providedDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (minutes <= 0) {
      return "0 minutes ago";
    } else if (minutes < 60) {
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (hours < 24) {
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else if (days < 30) {
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    } else if (months < 12) {
      return `${months} ${months === 1 ? "month" : "months"} ago`;
    } else {
      return `${years} ${years === 1 ? "year" : "years"} ago`;
    }
  }


  const navigateToArt = async (item) => {
    const web3_1 = new Web3(window.ethereum);
    if (item.artworkUri && web3_1 != null) {
      try {
        listedNfts.forEach((nft) => {
          if (nft?.data.uriData === item?.uri) {

            navigate(
              `/artwork/eth/${(item?.mintContractAddress)}/${nft?.data?.listedData?.tokenId}`
            );

          }
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      console.log("Error: Missing artworkUri or Web3 instance.");
    }
  };

  const navigateToProfile = (account) => {
    if (account === address) {
      navigate(`/profile/${address}`, { state: { loc: "hero" } });
    } else {
      navigate(`/profile/${account}`, { state: { loc: "hero" } });
    }
  };



  const checkAccessForAllNFTs = () => {
    const now = new Date().getTime();

    // Get the current time in milliseconds
    const updatedNews = latestNews.map((nft) => {

      // Convert the listTime (in seconds) to a JavaScript Date object
      const createdTime = new Date(nft?.data?.listedData.listTime * 1000);
      const timeElapsed = now - createdTime.getTime();
      const hoursElapsed = Math.floor(timeElapsed / (1000 * 60 * 60));

      let currentMembership = null;
      let purchaseWindow = false;
      let totalRemainingTime = null;
      let eligibleBuyer = false;
      let timeRemaining = null;

      let earlyAccess =
        hoursElapsed < 24 &&
        nft.data.isSaleListed &&
        !nft?.data?.listedData?.firstClaim;

      // Determine which membership has access based on elapsed time
      if (hoursElapsed < 6) {
        currentMembership = "Gold"; // First 6 hours for GOLD members
        purchaseWindow = userMembership === "Gold";
        totalRemainingTime = 6 * 60 * 60 * 1000 - timeElapsed; // Time left in milliseconds for Gold members
      } else if (hoursElapsed >= 6 && hoursElapsed < 12) {
        currentMembership = "Silver"; // Next 6 hours for Silver and Gold
        purchaseWindow = ["Gold", "Silver"].includes(userMembership);
        totalRemainingTime = 12 * 60 * 60 * 1000 - timeElapsed; // Time left in milliseconds for Silver members
      } else if (hoursElapsed >= 12 && hoursElapsed < 18) {
        currentMembership = "Bronze"; // Next 6 hours for Bronze, Silver, and Gold
        purchaseWindow = ["Gold", "Silver", "Bronze"].includes(userMembership);
        totalRemainingTime = 18 * 60 * 60 * 1000 - timeElapsed; // Time left in milliseconds for Bronze members
      } else if (hoursElapsed >= 18 && hoursElapsed < 24) {
        currentMembership = "Standard"; // Last 6 hours for Standard and others
        purchaseWindow = ["Gold", "Silver", "Bronze", "Standard"].includes(
          userMembership
        );
        totalRemainingTime = 24 * 60 * 60 * 1000 - timeElapsed; // Time left in milliseconds for Standard members
      } else {
        currentMembership = "PUBLIC"; // After 24 hours, public access
        purchaseWindow = true; // Everyone can buy
      }

      // Calculate time remaining if the window is still open
      if (totalRemainingTime !== null && totalRemainingTime > 0) {
        const hours = Math.floor(totalRemainingTime / (1000 * 60 * 60));
        const minutes = Math.floor(
          (totalRemainingTime % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((totalRemainingTime % (1000 * 60)) / 1000);
        timeRemaining = `${hours}h ${minutes}m ${seconds}s`;
      }

      // Determine if the user is an eligible buyer
      if (currentMembership === "PUBLIC" || purchaseWindow) {
        eligibleBuyer = true;
      }

      // Return updated NFT information
      return {
        ...nft, // Spread the existing NFT data
        currentMembership,
        eligibleBuyer,
        timeRemaining,
        earlyAccess,
        canPurchase: purchaseWindow,
      };
    });

    // Update the latest news with the new calculated values for each NFT
    setUpdatedLatestNews(updatedNews);
  };

  // Call the function inside useEffect to check access for each NFT when dependencies change
  useEffect(() => {

    if (latestNews) {
      checkAccessForAllNFTs();
      const timer = setInterval(checkAccessForAllNFTs, 1000);

      return () => clearInterval(timer);
    }
  }, [latestNews, userMembership]);





  return (
    <>

      {
        loading ?
          (
            <div
              className="d-flex flex-column align-items-center justify-content-center mx-auto"
              style={{ maxWidth: "600px" }}
            >
              <Skeleton.Avatar active size={557} shape="square" />

              <Skeleton.Input
                block={false}
                active
                round
                size="large"
                shape="button"
                className=" mt-2"
                style={{
                  width: "200px",
                  height: "25px",
                  margin: "auto",
                  borderRadius: "4px",
                }}
              />
            </div>
          ) : (
            <>
              {latestNews?.length > 0 && (
                <section className="news  ">

                  <h5 className="section-head">
                    <span>latest</span>
                    news
                  </h5>
                  <div className="news-slider">

                    <Carousel indicators={false} controls={true} touch={true} fade>
                      {updatedLatestNews.map((item, index) => (
                        <Carousel.Item>
                          <div key={index}>
                            <div className="news-card">
                              <div className="news-header pointer position-relative">
                                {item.action === "listedOffer" ? (
                                  <img
                                    onClick={() => {
                                      if (item.action === "Mint") {
                                        navigateToArt(item); // Call your function with item data
                                      } else {
                                        navigate(
                                          `/artwork/eth/${item?.mintContractAddress}/${item?.tokenId}`
                                        );
                                      }
                                    }}
                                    src={
                                      item?.artworkUri?.metadata?.data.image ||
                                      item?.artworkUri?.metadata?.data.previewImg
                                    }
                                    alt="card image"
                                    className="img-100"
                                  />
                                ) : item.action === "Mint" ? (
                                  <img
                                    onClick={() => {
                                      if (item.action === "Mint") {
                                        navigateToArt(item); // Call your function with item data
                                      } else {
                                        navigate(
                                          `/artwork/eth/${(item?.mintContractAddress)}/${item?.tokenId}`
                                        );
                                      }
                                    }}
                                    src={
                                      item?.artworkUri?.image ||
                                      item?.artworkUri?.previewImg
                                    }
                                    alt="card image"
                                    className="img-100"
                                  />
                                ) : (
                                  <img
                                    onClick={() => {
                                      if (item.action === "Mint") {
                                        navigateToArt(item); // Call your function with item data
                                      } else {

                                        navigate(
                                          `/artwork/eth/${(item?.mintContractAddress)}/${item?.tokenId}`
                                        );
                                      }
                                    }}
                                    src={
                                      item?.artworkUri?.image ||
                                      item?.artworkUri?.previewImg
                                    }
                                    alt="card image"
                                    className="img-100"
                                  />
                                )}

                                {item?.earlyAccess && (
                                  <div className="news-status justify-content-between">
                                    <div className="d-flex align-items-center w-100 ">
                                      <span className="circle me-3"></span>{" "}
                                      <p className="body-medium text-white">
                                        Early access
                                      </p>
                                      <div
                                        className={`member-ship fw-semibold body-medium mx-2  ${item.currentMembership == "Standard"
                                          ? "standard"
                                          : item.currentMembership == "Gold"
                                            ? "gold"
                                            : item.currentMembership == "Silver"
                                              ? "silver"
                                              : item.currentMembership == "Bronze"
                                                ? "bronze"
                                                : ""
                                          }`}
                                      >
                                        {item.currentMembership}
                                      </div>
                                      <p className="body-medium text-white d-flex gap-1">
                                        {item.timeRemaining}
                                      </p>
                                    </div>
                                    <div>

                                      <img src={lock} alt="" className="w-75" />
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div className="news-body">

                                <p className="mb-0">
                                  {/* sold */}
                                  {item?.action === "sold" && (
                                    <>
                                      <Popover
                                        placement="top"
                                        trigger="hover"
                                        content={
                                          <ArtistPopUp
                                            userProfile={getImage(item?.to)}
                                            artistName={getNamebyAddress(item?.to)}
                                            verified={verified}
                                          />
                                        }
                                      >
                                        <span
                                          onClick={() => {
                                            navigateToProfile(item?.to);
                                          }}
                                          className="hover-underline pointer show-artist-popup"
                                        >
                                          {getNamebyAddress(item?.to)}
                                        </span>
                                      </Popover>{" "}
                                      purchased{" "}
                                      <span className="hover-underline pointer">
                                        <span
                                          className="hover-underline pointer"
                                          onClick={() =>
                                            navigate(
                                              `/artwork/eth/${item?.mintContractAddress}/${item?.tokenId}`
                                            )
                                          }
                                        >
                                          {item?.artworkUri?.artName}
                                        </span>
                                      </span>{" "}
                                      for {item?.price / 1000000000000000000}{" "}
                                      {item?.artworkUri?.selectedBlockchain ==
                                        config.networks.flare
                                        ? "FLR"
                                        : "SGB"}{" "}
                                    </>
                                  )}

                                  {/* mint */}
                                  {item?.action === "Mint" && (
                                    <>
                                      <Popover
                                        placement="top"
                                        trigger="hover"
                                        content={
                                          <ArtistPopUp
                                            userProfile={getImage(item?.from)}
                                            artistName={getNamebyAddress(item?.from)}
                                            verified={verified}
                                          />
                                        }
                                      >
                                        <span
                                          onClick={() => {
                                            navigateToProfile(item?.from);
                                          }}
                                          className="hover-underline pointer show-artist-popup"
                                        >
                                          {getNamebyAddress(item?.from)}
                                        </span>
                                      </Popover>{" "}
                                      minted{" "}
                                      <span
                                        className="hover-underline pointer show-artist-popup"
                                        onClick={() => {
                                          navigateToArt(item);
                                        }}
                                      >
                                        {item?.artworkUri?.artName}
                                      </span>{" "}
                                    </>
                                  )}

                                  {/* listed sale */}
                                  {item?.action === "listedSale" && (
                                    <>
                                      <Popover
                                        placement="top"
                                        trigger="hover"
                                        content={
                                          <ArtistPopUp
                                            userProfile={getImage(item?.from)}
                                            artistName={getNamebyAddress(item?.from)}
                                            verified={verified}
                                          />
                                        }
                                      >
                                        <span
                                          onClick={() => {
                                            navigateToProfile(item?.from);
                                          }}
                                          className="hover-underline pointer show-artist-popup"
                                        >
                                          {getNamebyAddress(item?.from)}
                                        </span>
                                      </Popover>{" "}
                                      listed{" "}
                                      <span
                                        className="hover-underline pointer show-artist-popup"
                                        onClick={() =>
                                          navigate(
                                            `/artwork/eth/${item?.mintContractAddress}/${item?.tokenId}`
                                          )
                                        }
                                      >
                                        {item?.artworkUri?.artName}
                                      </span>{" "}
                                      for sale
                                    </>
                                  )}

                                  {/* listed offer */}
                                  {item?.action === "listedOffer" && (
                                    <>

                                      <Popover
                                        placement="top"
                                        trigger="hover"
                                        content={
                                          <ArtistPopUp
                                            userProfile={getImage(item?.from)}
                                            artistName={getNamebyAddress(item?.from)}
                                            verified={verified}
                                          />
                                        }
                                      >
                                        <span
                                          className="hover-underline pointer show-artist-popup"
                                          onClick={() => {
                                            navigateToProfile(item?.from);
                                          }}
                                        >
                                          {getNamebyAddress(item?.from)}
                                        </span>
                                      </Popover>{" "}
                                      listed{" "}
                                      <span
                                        className="hover-underline pointer"
                                        onClick={() =>
                                          navigate(
                                            `/artwork/eth/${item?.mintContractAddress}/${item?.tokenId}`
                                          )
                                        }
                                      >
                                        {item?.artworkUri?.artName}
                                      </span>
                                      for offer
                                    </>
                                  )}

                                  {/* offers */}
                                  {item?.action === "offer" && (
                                    <>
                                      <Popover
                                        placement="top"
                                        trigger="hover"
                                        content={
                                          <ArtistPopUp
                                            userProfile={getImage(item?.from)}
                                            artistName={getNamebyAddress(item?.from)}
                                            verified={verified}
                                          />
                                        }
                                      >
                                        <span
                                          onClick={() => {
                                            navigateToProfile(item?.from);
                                          }}
                                          className="hover-underline pointer show-artist-popup"
                                        >
                                          {getNamebyAddress(item?.from)}
                                        </span>
                                      </Popover>{" "}
                                      made a bid of{" "}
                                      {item?.price / 1000000000000000000}{" "}
                                      {item?.artworkUri?.selectedBlockchain ==
                                        "Coston"
                                        ? "SGB"
                                        : "FLR"}{" "}
                                      on{" "}
                                      <span
                                        className="hover-underline pointer"
                                        onClick={() =>
                                          navigate(
                                            `/artwork/eth/${item?.mintContractAddress}/${item?.tokenId}`
                                          )
                                        }
                                      >
                                        {item?.artworkUri?.artName}
                                      </span>
                                    </>
                                  )}

                                  {item?.action === "transfer" && (
                                    <>
                                      <Popover
                                        placement="top"
                                        trigger="hover"
                                        content={
                                          <ArtistPopUp
                                            userProfile={getImage(item?.from)}
                                            artistName={getNamebyAddress(item?.from)}
                                            verified={verified}
                                          />
                                        }
                                      >
                                        <span
                                          onClick={() => {
                                            navigateToProfile(item?.from);
                                          }}
                                          className="hover-underline pointer show-artist-popup"
                                        >
                                          {getNamebyAddress(item?.from)}
                                        </span>
                                      </Popover>{" "}
                                      transferred{" "}
                                      <span
                                        className="hover-underline pointer"
                                        onClick={() =>
                                          navigate(
                                            `/artwork/eth/${item?.mintContractAddress}/${item?.tokenId}`
                                          )
                                        }
                                      >
                                        {item?.artworkUri?.artName}
                                      </span>

                                      to{" "}
                                      <span
                                        onClick={() => {
                                          navigateToProfile(item?.to);
                                        }}
                                        className="hover-underline pointer show-artist-popup"
                                      >
                                        {getNamebyAddress(item?.to)}
                                      </span>
                                    </>
                                  )}
                                  {/* transfer */}
                                  {item?.action === "claimNft" && (
                                    <>
                                      <Popover
                                        placement="top"
                                        trigger="hover"
                                        content={
                                          <ArtistPopUp
                                            userProfile={getImage(item?.to)}
                                            artistName={getNamebyAddress(item?.to)}
                                            verified={verified}
                                          />
                                        }
                                      >
                                        <span
                                          onClick={() => {
                                            navigateToProfile(item?.to);
                                          }}
                                          className="hover-underline pointer show-artist-popup"
                                        >
                                          {getNamebyAddress(item?.to)}
                                        </span>
                                      </Popover>{" "}
                                      claimed{" "}
                                      <span
                                        className="hover-underline pointer"
                                        onClick={() =>
                                          navigate(
                                            `/artwork/eth/${item?.mintContractAddress}/${item?.tokenId}`
                                          )
                                        }
                                      >
                                        {item?.artworkUri?.artName}
                                      </span>
                                    </>
                                  )}
                                </p>
                                <label className="small">
                                  {timeAgo(item?.timestamp?.seconds)}
                                </label>
                              </div>
                            </div>
                          </div>
                        </Carousel.Item>
                      ))}
                    </Carousel>

                  </div>
                </section>
              )}

            </>
          )
      }


    </>
  );
};

export default LatestNews;
