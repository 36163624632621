import { Skeleton } from "antd";
import React from "react";

const SkeltonLoader = ({ circle = false, text = false }) => {
  return (
    <>
      {circle && (
        <Skeleton.Avatar
          active
          size="large"
          shape="circle"
          style={{ width: "130px", height: "130px" }}
        />
      )}
      <br />

      <Skeleton.Avatar
        active
        round
        size="large"
        shape="button"
        style={{ width: "130px", height: "10px", borderRadius: "4px" }}
      />
    </>
  );
};

export default SkeltonLoader;
